import { BaseService } from './baseService'
import apis from './apis'

export class OrderService extends BaseService {
  static async create(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.create_orders,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async createMultiPackages(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.create_multi_packages,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async calculateShipfee(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.calculate_ship_fee,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async multicalculateShipfee(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.multi_calculate_ship_fee,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async searchOrders(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.search_orders,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getListStatus() {
    try {
      const response = await this.request({ auth: true }).get(
        apis.orders_status
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getDetail(pars) {
    try {
      const response = await this.request({ auth: true }).get(
        `${apis.orders_detail}/${pars}`
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async cancel(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.orders_cancel,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async createMultiple(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.create_multiple_orders,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async viewFromExcel(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.orders_view_from_excel,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async downloadTemplate() {
    try {
      const response = await this.request({ auth: true }).get(
        apis.orders_download_template, {
          responseType: 'arraybuffer'
        }
      )
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getLastTransportType() {
    try {
      const response = await this.request({ auth: true }).get(
        apis.get_last_transport_type
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getReceiverInfoByPhone(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.get_receiver_info_by_phone,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getCircleStatistic(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.get_circle_statistic,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getLineStatistic(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.get_line_statistic,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getDashBoard(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.dashboard_shop,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async findForUserId(userId) {
    try {
      const response = await this.request({ auth: true }).get(
        `${apis.get_find_for_user_Id}/${userId}`
      );
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getEstablishServiceByPartner(partnerId) {
    try {
      const response = await this.request({ auth: true }).get(
        `${apis.get_establish_service_by_partner}/${partnerId}`
      );
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getAllPickWorkShip() {
    try {
      const response = await this.request({ auth: true }).get(
        apis.get_all_pick_work_ship
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async calculateServicefee(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.calculate_service_fee,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
}
