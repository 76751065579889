<template>
  <v-layout>
    <div style="align-self: center">
      <v-icon
        v-if="isShowIconDel"
        slot="prepend"
        @click="onDeleteItem(idx)"
      >
        {{ 'mdi-delete' }}
      </v-icon>
      <v-icon v-else slot="prepend" class="ml-6" />
    </div>
    <v-layout column class="item-order">
      <v-layout>
        <v-layout column class="mt-4 mx-4">
          <div>
            {{ $t("index_package") }} {{ idx + 1 }}
            <span class="red--text">
              {{ $t("icon_required") }}
            </span>
          </div>
          <validation-provider
            v-slot="{ errors }"
            name="require"
            vid="productDescription"
            :rules="requireRules"
          >
            <v-text-field
              v-model="item.productDescription"
              outlined
              dense
              :maxlength="maximumSmallText"
              :error-messages="errors"
              :placeholder="$t('place_holders.product_name')"
            />
          </validation-provider>
        </v-layout>
      </v-layout>
      <!-- Quy đổi kích thước và khối lượng -->
      <v-layout class="mx-4">
        {{ $t("calculate_size") }}
        <span class="red--text">
          {{ $t("icon_required") }}
        </span>
        <v-icon>mdi-compare-horizontal</v-icon>
        {{ $t("tranfer_weight") }}: {{ computedEstimateWeight(item.length, item.width, item.height)
        }}{{ $t("kg") }}
      </v-layout>
      <v-row class="justify-center mx-1">
        <!-- Dài -->
        <validation-provider
          v-slot="{ errors }"
          name="require"
          vid="length"
          class="container col-sm-4 col-12"
          :rules="requireRules"
        >
          <v-text-field
            v-model="item.length"
            outlined
            dense
            suffix="cm"
            class="pr-sm-2"
            :maxlength="maximumSizeLwH"
            :error-messages="errors"
            :placeholder="$t('place_holders.length')"
            @keypress="isNumber($event)"
            @input="onInputLength"
            @paste.prevent="onPasteLength($event)"
            @blur="onBlurValue($event, idx, item)"
          />
        </validation-provider>
        <!-- Rộng -->
        <validation-provider
          v-slot="{ errors }"
          name="require"
          vid="width"
          class="container col-sm-4 col-12 px-sm-0 py-0 py-sm-3"
          :rules="requireRules"
        >
          <v-text-field
            v-model="item.width"
            outlined
            dense
            suffix="cm"
            prepend-icon="mdi-plus"
            :maxlength="maximumSizeLwH"
            :error-messages="errors"
            :placeholder="$t('place_holders.width')"
            @keypress="isNumber($event)"
            @input="onInputWidth"
            @paste.prevent="onPasteWidth($event)"
            @blur="onBlurValue($event, idx, item)"
          />
        </validation-provider>
        <!-- Cao -->
        <validation-provider
          v-slot="{ errors }"
          name="require"
          vid="height"
          class="container col-sm-4 col-12"
          :rules="requireRules"
        >
          <v-text-field
            v-model="item.height"
            outlined
            dense
            suffix="cm"
            prepend-icon="mdi-plus"
            class="ml-sm-2"
            :maxlength="maximumSizeLwH"
            :error-messages="errors"
            :placeholder="$t('place_holders.height')"
            @keypress="isNumber($event)"
            @input="onInputHeight"
            @paste.prevent="onPasteHeight($event)"
            @blur="onBlurValue($event, idx, item)"
          />
        </validation-provider>
      </v-row>
      <!-- Khối lượng đơn hàng -->
      <v-layout class="mx-4">
        <v-row>
          <v-col cols="sm-6" class="col-12 pb-0 mb-2">
            <div>
              {{ $t("order_weight")
              }}<span class="red--text">{{
                $t("icon_required")
              }}</span>
            </div>
            <validation-provider
              v-slot="{ errors }"
              name="orderWeight"
              vid="orderWeight"
              :rules="orderWeightRules"
            >
              <v-text-field
                v-model="item.orderWeight"
                dense
                suffix="kg"
                outlined
                :class="item.orderWeightError ? 'error--text v-input--has-state': ''"
                :hide-details="item.orderWeightHideError"
                :maxlength="6"
                :error-messages="errors"
                :placeholder="$t('place_holders.type_weight')"
                @keypress="isDecimal($event)"
                @input="onInputOrderWeight"
                @paste.prevent="onPasteOrderWeight($event)"
                @blur="item.orderWeightHideError === false ? onBlurValue($event, idx, item) : ''"
              />
              <div
                v-if="item.orderWeightError"
                class="v-messages theme--light error--text mt-1 ml-3"
                role="alert"
              >
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ item.orderWeightError }}
                  </div>
                </div>
              </div>
            </validation-provider>
          </v-col>
          <!--gia tri don hang -->
          <v-col cols="sm-6" class="col-12 pt-0 pt-sm-3">
            <div>
              {{ $t("order_value") }}
              <span class="red--text">
                {{ $t("icon_required") }}
              </span>
            </div>
            <validation-provider
              v-slot="{ errors }"
              name="orderValue"
              vid="orderValue"
              :rules="orderValueRules"
            >
              <v-text-field
                v-model="item.orderValue"
                dense
                outlined
                :class="item.orderValueError ? 'error--text v-input--has-state': ''"
                :maxlength="maximumPriceFm"
                :error-messages="errors"
                :hide-details="item.orderValueHideError"
                :placeholder="$t('place_holders.type_value_order')"
                @keypress="isNumberValue($event)"
                @input="onInputOrderValue"
                @paste.prevent="onPasteOrderValue($event)"
                @blur="onBlurValue($event, idx, item)"
              />
              <div
                v-if="item.orderValueError"
                class="v-messages theme--light error--text mt-1 ml-3"
                role="alert"
              >
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ item.orderValueError }}
                  </div>
                </div>
              </div>
            </validation-provider>
          </v-col>
        </v-row>
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import constants from '@/constants'
import { FormUtils } from "@/helpers/formUtils";
import { StringUtils } from '@/helpers/stringUtils'
export default {
  name: 'InputInfoOrder',
  props: {
    item: {
      type: Object,
      default: null
    },
    idx: {
      type: Number,
      default: null
    },
    onDeleteItem: {
      type: Function,
      default: null
    },
    disableItem: {
      type: Boolean,
      default: false
    },
    onBlurValue: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      constants,
      requireRules: {
        required: true,
      },
      orderWeightRules: {
        required: true,
        regex: constants.regExp.not_zero,
      },
      orderValueRules: {
        required: true,
        regex: constants.regExp.not_zero,
      },
      maximumMediumText: constants.maximumMediumText,
      maximumSizeLwH: constants.maximumSizeLwHFm,
      maximumSizeLWH: constants.maximumSizeLwH,
      maximumOrderWeight: constants.maximumOrderWeight,
      maximumSmallText: constants.maximumSmallText,
      maximumPriceFm: constants.maximumPriceValueFm,
      rules: {
        checkEmpty: (title) => (v) => {
          if (!v || !v.length) {
            return `${title} ${this.$t('users.required')}`
          }
          return true
        },
      },
    }
  },
  computed: {
    isShowIconDel() {
      return this.idx > 1
    }
  },
  methods: {
    isNumber(event) {
      if (event.key === ".") {
        return event.preventDefault();
      } else {
        return FormUtils.isNumber(event);
      }
    },
    isNumberValue(event) {
      return FormUtils.isNumber(event);
    },
    isDecimal(evt) {
      return FormUtils.isDecimal(evt);
    },
    onInputLength(val) {
      this.item.length = StringUtils.formatNumber(val);
    },
    onPasteLength(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        this.maximumSizeLwH
      );
      if (val !== null) {
        this.item.length = StringUtils.formatNumber(val);
      }
    },
    onInputWidth(val) {
      this.item.width = StringUtils.formatNumber(val);
    },
    onPasteWidth(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        this.maximumSizeLwH
      );
      if (val !== null) {
        this.item.width = StringUtils.formatNumber(val);
      }
    },
    onInputHeight(val) {
      this.item.height = StringUtils.formatNumber(val);
    },
    onPasteHeight(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        this.maximumSizeLwH
      );
      if (val !== null) {
        this.item.height = StringUtils.formatNumber(val);
      }
    },
    onInputOrderWeight(val) {
      if (StringUtils.isExistSeparation(val)) {
        this.maximumOrderWeight = constants.maximumOrderWeightDecimalFm;
      } else {
        this.maximumOrderWeight = constants.maximumOrderWeightFm;
      }
      this.checkValidateOrderWeight()
      this.item.orderWeight = StringUtils.formatDecimal(val);
    },
    onPasteOrderWeight(evt) {
      const val = FormUtils.isOnPasteOnlyDecimalAndMaximum(
        evt,
        constants.maximumOrderWeight
      );
      if (val !== null) {
        this.item.orderWeight = StringUtils.formatDecimal(val);
      }
    },
    checkValidateOrderWeight() {
      var msg = null;
      if (this.item.orderWeight < 10) {
        msg = `${this.$t("ten_kg")}`;
        this.item.orderWeightError = msg;
        this.item.orderWeightHideError = true;
      } else {
        this.item.orderWeightError = "";
        this.item.orderWeightHideError = false;
      }
    },
    onPasteOrderValue(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPriceValue
      );
      if (val !== null) {
        this.item.orderValue = StringUtils.formatNumber(val);
        this.checkValidOrderValue();
      }
    },
    onInputOrderValue(val) {
      this.item.orderValue = StringUtils.formatNumber(val);
      if (
        parseFloat(StringUtils.replaceComma(val)) >
        constants.maximumOrderValue
      ) {
        const msg = `${this.$t("order_value")} ${this.$t(
          "maximum"
        )} ${StringUtils.formatNumber(constants.maximumOrderValue)} ${this.$t(
          "vnd_unit"
        )}`;
        this.item.orderValueError = msg;
        this.item.orderValueHideError = true;
      } else {
        this.item.orderValueError = "";
        this.item.orderValueHideError = false;
      }
    },
    computedEstimateWeight(length, width, height) {
      if (length && width && height) {
        if (this.partnerSelect === constants.partnerGroup.ninjaVan) {
          const weightTranfer =
          (StringUtils.numStrToInt(length) *
            StringUtils.numStrToInt(width) *
            StringUtils.numStrToInt(height)) /
          6000;
          return StringUtils.formatDecimal(weightTranfer);
        } else {
          if (this.serviceSelect === constants.servicesShipping.over) {
            const weightTranfer =
            (StringUtils.numStrToInt(length) *
              StringUtils.numStrToInt(width) *
              StringUtils.numStrToInt(height)) /
            4000;
            return StringUtils.formatDecimal(weightTranfer);
          } else {
            const weightTranfer =
            (StringUtils.numStrToInt(length) *
              StringUtils.numStrToInt(width) *
              StringUtils.numStrToInt(height)) /
            6000;
            return StringUtils.formatDecimal(weightTranfer);
          }
        }
      }
      return 0;
    },
  }
}
</script>

<style scoped>
.item-order {
  border: 1px dotted gray;
  border-radius: 8px;
}
</style>
