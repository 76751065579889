import apis from './apis'
import { BaseService } from './baseService'

export class BankService extends BaseService {
  static async getAll() {
    try {
      const response = await this.request({ auth: true }).get(
        apis.bank_get_all
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getAccount() {
    try {
      const response = await this.request({ auth: true }).get(
        apis.bank_get_account
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async createOrUpdate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.bank_update_account,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
}
