<template>
  <v-dialog v-model="internalValue" :max-width="widthDialog" persistent>
    <v-card class="pa-4">
      <div class="d-flex justify-space-between text--body-5-22 pb-2">
        <span>{{ $t("edit-new-order") }}</span>
        <v-icon class="cursor-pointer" @click="closeDialog">mdi-close</v-icon>
      </div>
      <v-layout column>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-layout class="border-primary rounded pa-2 px-lg-6 py-sm-3">
            <v-row>
              <v-col cols="sm-6" class="col-12">
                <v-layout column>
                  <v-layout justify-start>
                    <h4>{{ $t("receiver") }}</h4>
                  </v-layout>
                  <v-layout class="mt-4">
                    <v-row>
                      <v-col>
                        <div>
                          {{ $t("receiver_phone") }}
                          <span class="red--text">
                            {{ $t("icon_required") }}
                          </span>
                        </div>
                        <validation-provider
                          v-slot="{ errors }"
                          name="phone"
                          :rules="phoneRules"
                        >
                          <v-text-field
                            v-model="detailData.receiverPhone"
                            type="text"
                            pattern="\d*"
                            outlined
                            dense
                            :error-messages="errors"
                            :maxlength="maximumPhone"
                            :placeholder="$t('place_holders.type_phone')"
                            @keypress="isNumber($event)"
                            @paste.prevent="onPastePhone($event)"
                            @input="inputPhone($event)"
                          />
                          <div class="pos-rel">
                            <v-menu
                              v-model="menuPhone"
                              class="list-receiver"
                              :nudge-width="200"
                              offset-x
                              attach
                            >
                              <v-card>
                                <v-list
                                  v-for="(item, index) in listReceiver"
                                  :key="index"
                                >
                                  <v-list-item @click="choosePhone(item.phone)">
                                    <v-layout column>
                                      <span class="text--body-5-14">
                                        {{ item.phone }} - {{ item.name }}
                                      </span>
                                      <span class="text--body-5-12">
                                        {{
                                          `${item.address}, ${item.communeName}, ${item.districtName}, ${item.provinceName}`
                                        }}
                                      </span>
                                    </v-layout>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-menu>
                          </div>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-layout>
                  <v-layout>
                    <v-row>
                      <v-col>
                        <div>
                          {{ $t("receiver_full_name") }}
                          <span class="red--text">
                            {{ $t("icon_required") }}
                          </span>
                        </div>
                        <validation-provider
                          v-slot="{ errors }"
                          name="require"
                          vid="fullName"
                          :rules="requireRules"
                        >
                          <v-text-field
                            v-model="detailData.receiverName"
                            outlined
                            dense
                            :error-messages="errors"
                            :maxlength="maximumSmallText"
                            :placeholder="$t('place_holders.type_full_name')"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-layout>
                  <!-- Địa chỉ nhận hàng -->
                  <v-layout column>
                    <label>
                      {{ $t("province") }}/{{ $t("district") }}/{{
                        $t("commune")
                      }}
                      <span class="error--text">*</span>
                    </label>
                    <v-row>
                      <validation-provider
                        v-slot="{ errors }"
                        name="selectRequire"
                        vid="provinceOrder"
                        class="container col-sm-4 col-12"
                        :rules="requireRules"
                      >
                        <v-autocomplete
                          ref="inputProvinceOrder"
                          v-model="detailData.editProvinceOrderSelected"
                          outlined
                          dense
                          class="rounded"
                          item-text="name"
                          item-value="code"
                          hide-no-data
                          :items="lstProvince"
                          :filter="filterItems"
                          :placeholder="$t('place_holders.province')"
                          :error-messages="errors"
                          @change="onChangeProvinceOrder"
                          @blur="calculateShipFee"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        ref="districtOrder"
                        name="selectRequire"
                        vid="districtOrder"
                        class="container col-sm-4 col-12 col-12 py-0 py-sm-3"
                        :rules="requireRules"
                      >
                        <v-autocomplete
                          ref="inputDistrictOrder"
                          v-model="detailData.editDistrictOrderSelected"
                          outlined
                          dense
                          class="rounded"
                          item-text="name"
                          item-value="code"
                          hide-no-data
                          :items="lstDistrict"
                          :filter="filterItems"
                          :placeholder="$t('place_holders.district')"
                          :error-messages="errors"
                          :disabled="districtDisabled"
                          @change="onChangeDistrictOrder"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        ref="communeOrder"
                        name="selectRequire"
                        vid="communeOrder"
                        class="container col-sm-4"
                        :rules="requireRules"
                      >
                        <v-autocomplete
                          ref="inputCommuneOrder"
                          v-model="detailData.editCommuneOrderSelected"
                          outlined
                          dense
                          class="rounded"
                          item-text="name"
                          item-value="code"
                          hide-no-data
                          :items="lstCommune"
                          :filter="filterItems"
                          :placeholder="$t('place_holders.commune')"
                          :error-messages="errors"
                          :disabled="communeDisabled"
                          @change="onChangeCommuneOrder"
                        />
                      </validation-provider>
                    </v-row>
                  </v-layout>
                  <!-- Địa chỉ cụ thể -->
                  <v-layout>
                    <v-row>
                      <v-col>
                        <div>
                          {{ $t("custom_address") }}
                          <span class="red--text">
                            {{ $t("icon_required") }}
                          </span>
                        </div>
                        <validation-provider
                          v-slot="{ errors }"
                          name="require"
                          vid="customAddress"
                          :rules="requireRules"
                        >
                          <v-text-field
                            v-model="detailData.receiverAddress"
                            outlined
                            dense
                            :maxlength="maximumSmallText"
                            :error-messages="errors"
                            :placeholder="
                              $t('place_holders.type_custom_address')
                            "
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-layout>
              </v-col>
              <!-- Thông tin đơn hàng -->
              <v-col>
                <v-layout column>
                  <v-layout justify-start>
                    <h4>{{ $t("order_info") }}</h4>
                  </v-layout>
                  <!-- Mô tả sản phẩm -->
                  <v-layout class="mt-4">
                    <v-row>
                      <v-col>
                        <div>
                          {{ $t("product_name") }}
                          <span class="red--text">
                            {{ $t("icon_required") }}
                          </span>
                        </div>
                        <validation-provider
                          v-slot="{ errors }"
                          name="require"
                          vid="productDescription"
                          :rules="requireRules"
                        >
                          <v-textarea
                            v-model="detailData.description"
                            rows="3"
                            outlined
                            :maxlength="maximumSmallText"
                            :error-messages="errors"
                            :placeholder="$t('place_holders.product_name')"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-layout>
                  <!-- Quy đổi kích thước và khối lượng -->
                  <div>
                    {{ $t("calculate_size") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                    <v-icon>mdi-compare-horizontal</v-icon>
                    {{ $t("tranfer_weight") }}: {{ computedEstimateWeightExcel
                    }}{{ $t("kg") }}
                  </div>
                  <v-row class="justify-center">
                    <!-- Dài -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="require"
                      vid="length"
                      class="container col-sm-4 col-12"
                      :rules="requireRules"
                    >
                      <v-text-field
                        v-model="detailData.length"
                        outlined
                        dense
                        suffix="cm"
                        class="pr-sm-2"
                        maxlength="5"
                        :error-messages="errors"
                        :placeholder="$t('place_holders.length')"
                        @keypress="isNumberFloat($event)"
                        @input="onInputLength"
                        @paste.prevent="onPasteLength($event)"
                        @blur="calculateShipFee"
                      />
                    </validation-provider>
                    <!-- Rộng -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="require"
                      vid="width"
                      class="container col-sm-4 col-12 px-sm-0 py-0 py-sm-3"
                      :rules="requireRules"
                    >
                      <v-text-field
                        v-model="detailData.width"
                        outlined
                        dense
                        suffix="cm"
                        prepend-icon="mdi-plus"
                        maxlength="5"
                        :error-messages="errors"
                        :placeholder="$t('place_holders.width')"
                        @keypress="isNumberFloat($event)"
                        @input="onInputWidth"
                        @paste.prevent="onPasteWidth($event)"
                        @blur="calculateShipFee"
                      />
                    </validation-provider>
                    <!-- Cao -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="require"
                      vid="height"
                      class="container col-sm-4 col-12"
                      :rules="requireRules"
                    >
                      <v-text-field
                        v-model="detailData.height"
                        outlined
                        dense
                        suffix="cm"
                        prepend-icon="mdi-plus"
                        class="ml-sm-2"
                        maxlength="5"
                        :error-messages="errors"
                        :placeholder="$t('place_holders.height')"
                        @keypress="isNumberFloat($event)"
                        @input="onInputHeight"
                        @paste.prevent="onPasteHeight($event)"
                        @blur="calculateShipFee"
                      />
                    </validation-provider>
                  </v-row>
                  <!-- Khối lượng đơn hàng -->
                  <v-layout>
                    <v-row>
                      <v-col cols="sm-6" class="col-12 pb-0">
                        <div>
                          {{ $t("order_weight")
                          }}<span class="red--text">{{
                            $t("icon_required")
                          }}</span>
                        </div>
                        <validation-provider
                          v-slot="{ errors }"
                          name="orderWeight"
                          vid="orderWeight"
                          :rules="orderWeightRules"
                        >
                          <v-text-field
                            v-model="detailData.weight"
                            dense
                            suffix="kg"
                            outlined
                            :maxlength="maximumOrderWeight"
                            :error-messages="errors"
                            :placeholder="$t('place_holders.type_weight')"
                            @keypress="isDecimal($event)"
                            @input="onInputOrderWeight"
                            @paste.prevent="onPasteOrderWeight($event)"
                            @blur="calculateShipFee"
                          />
                        </validation-provider>
                      </v-col>
                      <!-- COD thu hộ -->
                      <v-col cols="sm-6" class="col-12 pt-0 pt-sm-3">
                        <div>{{ $t("cod") }}
                          <span class="red--text">{{
                            $t("icon_required")
                          }}</span>
                        </div>
                        <validation-provider
                          v-slot="{ errors }"
                          name="require"
                          vid="cod"
                          :rules="requireRules"
                        >
                          <v-text-field
                            v-model="detailData.codValue"
                            dense
                            outlined
                            :class="
                              codValueError
                                ? 'error--text v-input--has-state'
                                : ''
                            "
                            :hide-details="codValueHideError"
                            :maxlength="maximumPriceFm"
                            :error-messages="errors"
                            :placeholder="$t('place_holders.type_cod')"
                            @keypress="isNumberFloat($event)"
                            @input="onInputCOD"
                            @paste.prevent="onPasteCOD($event)"
                          />
                          <div
                            v-if="codValueError"
                            class="v-messages theme--light error--text mt-1 ml-3"
                            role="alert"
                          >
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">
                                {{ codValueError }}
                              </div>
                            </div>
                          </div>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-layout>
                  <v-layout>
                    <v-row>
                      <!-- Giá trị đơn hàng -->
                      <v-col
                        cols="sm-6"
                        class="col-12 pt-0 pt-sm-3"
                      >
                        <div>
                          {{ $t("order_value") }}
                          <span class="red--text">
                            {{ $t("icon_required") }}
                          </span>
                        </div>
                        <validation-provider
                          v-slot="{ errors }"
                          name="require"
                          vid="orderValue"
                          :rules="requireRules"
                        >
                          <v-text-field
                            v-model="detailData.orderValue"
                            outlined
                            dense
                            :class="
                              orderValueError
                                ? 'error--text v-input--has-state'
                                : ''
                            "
                            :hide-details="orderValueHideError"
                            :error-messages="errors"
                            :maxlength="maximumPriceFm"
                            :placeholder="$t('place_holders.type_value_order')"
                            @keypress="isNumberFloat($event)"
                            @input="onInputOrderValue"
                            @paste.prevent="onPasteOrderValue($event)"
                          />
                          <div
                            v-if="orderValueError"
                            class="v-messages theme--light error--text mt-1 ml-3"
                            role="alert"
                          >
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">
                                {{ orderValueError }}
                              </div>
                            </div>
                          </div>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-layout>

                  <!-- Đối tượng trả phí dịch vụ -->
                  <v-layout>
                    <v-row>
                      <v-col>
                        <div>
                          {{ $t("payer") }}
                          <span class="red--text">
                            {{ $t("icon_required") }}
                          </span>
                        </div>
                        <v-radio-group
                          v-model="detailData.paymentByValue"
                          row
                          class="mt-0"
                          @change="onChangeServiceFeePayer"
                        >
                          <v-radio
                            :label="$t('sender')"
                            :value="serviceFeePayersConst.sender"
                          />
                          <v-radio
                            :label="$t('receiver')"
                            :value="serviceFeePayersConst.receiver"
                          />
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-layout>
              </v-col>
            </v-row>
          </v-layout>
          <!-- Button submit -->
          <v-layout class="mt-4 d-flex justify-end">
            <v-btn outlined @click="handleReType">{{ $t("re_type") }}</v-btn>
            <v-btn
              :disabled="invalid || detailData.paymentByValue === '-' || isInvalidAction"
              color="primary"
              class="ml-3"
              @click="onSaveData"
            >
              {{ $t("save") }}
            </v-btn>
          </v-layout>
        </validation-observer>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinHandleOrderCreate from "@/views/order/mixins/mixinHandleOrderCreate";
import mixinHandleAddress from "@/views/order/mixins/mixiHandleAddress";
import { StorageService } from "@/services/storageService";
import { FormUtils } from "@/helpers/formUtils";
import { StringUtils } from "@/helpers/stringUtils";
import filterItems from "@/helpers/filterItems"
import constants from "@/constants";

export default {
  props: {
    widthDialog: {
      type: Number,
      default: 1000,
    },
    value: Boolean,
  },
  mixins: [mixinHandleOrderCreate, mixinHandleAddress, filterItems],
  data() {
    return {
      internalValue: this.value,
      requireRules: {
        required: true,
      },
      lstProvince: [],
      listDataUpdated: {},
    };
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return;

      this.$emit("input", val);
    },
    value(val, oldVal) {
      if (val === oldVal) return;

      this.internalValue = val;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getProvinces();
      await this.getListDeliveryNote();
    },
    async getProvinces() {
      this.lstProvince = await StorageService.getProvinces();
    },
    isNumber(event) {
      return FormUtils.isNumber(event);
    },
    isNumberFloat(event) {
      if (event.key === ".") {
        return event.preventDefault();
      } else {
        return FormUtils.isNumber(event);
      }
    },
    isDecimal(evt) {
      return FormUtils.isDecimal(evt);
    },
    handleReType() {
      this.$emit("re-type");
    },
    onInputHeight(val) {
      this.detailData.height = StringUtils.formatNumber(val);
    },
    onPasteHeight(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        this.maximumSizeLwH
      );
      if (val !== null) {
        this.detailData.height = StringUtils.formatNumber(val);
      }
    },
    onInputWidth(val) {
      this.detailData.width = StringUtils.formatNumber(val);
    },
    onPasteWidth(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        this.maximumSizeLwH
      );
      if (val !== null) {
        this.detailData.width = StringUtils.formatNumber(val);
      }
    },
    onInputLength(val) {
      this.detailData.length = StringUtils.formatNumber(val);
    },
    onPasteLength(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        this.maximumSizeLwH
      );
      if (val !== null) {
        this.detailData.length = StringUtils.formatNumber(val);
      }
    },
    onInputOrderWeight(val) {
      this.detailData.weight = StringUtils.formatDecimal(val);
    },
    onPasteOrderWeight(evt) {
      const val = FormUtils.isOnPasteOnlyDecimalAndMaximum(
        evt,
        constants.maximumOrderWeight
      );
      if (val !== null) {
        this.detailData.weight = StringUtils.formatDecimal(val);
      }
    },
    onSaveData() {
      this.$emit(
        "on-save-data",
        this.detailData,
        this.lstDistrict,
        this.lstCommune
      );
    },
    closeDialog() {
      this.internalValue = false;
    },
  },
};
</script>

<style></style>
