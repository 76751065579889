<template>
  <v-app>
    <v-layout white>
      <v-layout box-noauth bg-main />
      <v-layout column justify-center>
        <v-container fluid zindex-1>
          <v-row>
            <v-col cols="md-6" class="col-12">
              <PostOffice />
            </v-col>
            <v-layout justify-center align-center pa-2 pa-sm-6>
              <div class="col-12 col-md-10 col-lg-8 pa-0">
                <v-layout column white rounded-xl box-shadow pa-6 pa-xl-16>
                  <label
                    class="text-uppercase font-weight-medium text-size-title mt-4"
                  >
                    {{ $t('login') }}
                  </label>
                  <p class="mt-2 mb-10 text-body-2">
                    {{ $t('welcome_back_to') }}
                    <span
                      class="text-uppercase primary--text font-weight-large"
                    >
                      {{ $t('brand') }}
                    </span>
                  </p>
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form @submit.prevent="submit">
                      <v-layout column>
                        <label class="text-subtitle-1">
                          {{ $t('phone') }}
                        </label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="phone"
                          :rules="phoneRules"
                        >
                          <v-text-field
                            v-model="phone"
                            type="text"
                            pattern="\d*"
                            outlined
                            dense
                            class="rounded-lg"
                            :maxlength="maximumPhone"
                            :error-messages="errors"
                            :placeholder="$t('place_holders.phone')"
                            @keypress="isNumber($event)"
                          />
                        </validation-provider>
                      </v-layout>
                      <v-layout column>
                        <label class="text-subtitle-1">
                          {{ $t('password') }}
                        </label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="password"
                          :rules="passwordRules"
                        >
                          <v-text-field
                            v-model="password"
                            outlined
                            dense
                            class="rounded-lg"
                            :maxlength="maximumPassword"
                            :append-icon="
                              isPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="isPassword ? 'password' : 'text'"
                            :error-messages="errors"
                            :placeholder="$t('place_holders.hint_password')"
                            @click:append="isPassword = !isPassword"
                          />
                        </validation-provider>
                      </v-layout>
                      <v-layout justify-end class="mb-8">
                        <router-link
                          class="ml-auto primary--text text-decoration-none text-body-2"
                          :to="forgotPasswordPath"
                        >
                          {{ $t('forgot_password') }}?
                        </router-link>
                      </v-layout>
                      <div>
                        <v-btn
                          block
                          large
                          color="primary"
                          type="submit"
                          class="rounded-lg"
                          :disabled="invalid"
                        >
                          <span class="font-weight-medium white--text">
                            {{ $t('login') }}
                          </span>
                        </v-btn>
                      </div>
                    </v-form>
                  </validation-observer>
                  <v-layout justify-center class="mt-5">
                    <div class="blur--text text-h6-1">
                      {{ $t('donot_account') }}?
                      <router-link
                        class="primary--text text-decoration-none"
                        :to="registerNowPath"
                      >
                        {{ $t('register_now') }}
                      </router-link>
                    </div>
                  </v-layout>
                </v-layout>
              </div>
            </v-layout>
          </v-row>
        </v-container>
      </v-layout>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :title="titleNoti"
      :message="message"
    />
  </v-app>
</template>

<script>
import PostOffice from './components/PostOffice.vue'
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import constants from '@/constants'
import { mapActions } from 'vuex'
import routePaths from '@/router/routePaths'
import { FormUtils } from '@/helpers/formUtils'
import { StringUtils } from '@/helpers/stringUtils'

export default {
  components: { PostOffice, DialogLoading, DialogNotification },
  data: () => ({
    phone: '',
    maximumPhone: constants.maximumPhone,
    phoneRules: {
      required: true,
      min: constants.minimumPhone,
      max: constants.maximumPhone,
      regex: constants.regExp.phone
    },

    password: '',
    isPassword: true,
    maximumPassword: constants.maximumPassword,
    passwordRules: {
      required: true,
      min: constants.minimumPassword,
      max: constants.maximumPassword,
      regex: constants.regExp.password
    },

    forgotPasswordPath: routePaths.FORGOT_PASSWORD,
    registerNowPath: routePaths.REGISTER,

    // loading
    showLoading: false,

    // notifications
    showNoti: false,
    typeNoti: constants.typeAlert.warning,
    titleNoti: '',
    message: ''
  }),
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    }
  },
  methods: {
    ...mapActions('user', ['loginUser']),
    async submit() {
      var valid = this.$refs.observer.validate()
      if (valid) {
        this.showLoading = true
        const params = {
          username: this.phone,
          password: this.password
        }
        const { status, data } = await this.loginUser(params)
        this.showLoading = false
        if (status === constants.statusCode.ok && data) {
          this.$router.push(routePaths.DASHBOARD_MAIN)
        } else {
          if (this.isShopInactive(data.message)) {
            this.toggleDialogNoti({
              state: true,
              title: this.$t('account_inactive'),
              msg: data.message
            })
          } else {
            let msgNoti = ''
            if (data.message === constants.errorValidation) {
              var jsonObj = data.details
              var keys = Object.keys(jsonObj)
              for (var i = 0; i < keys.length; i++) {
                if (i < keys.length - 1) {
                  msgNoti += jsonObj[keys[i]] + ','
                } else {
                  msgNoti += jsonObj[keys[i]]
                }
              }
            } else {
              msgNoti = data.message
            }
            this.toggleDialogNoti({ state: true, msg: msgNoti })
          }
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    isShopInactive(msg) {
      return msg.toLowerCase().startsWith(constants.startsWithShopInactive)
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt)
    },
    toggleDialogNoti(
      pars = {
        state: false,
        title: '',
        type: constants.typeAlert.warning,
        msg: ''
      }
    ) {
      this.showNoti = pars.state
      this.typeNoti = pars.type
      this.titleNoti = pars.title
      this.message = pars.msg
    }
  }
}
</script>
<style src="@/styles/form.scss" lang="scss" />
