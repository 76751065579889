import constants from '@/constants'
import { StorageService } from '@/services/storageService'
import { UserService } from '@/services/userService'

const initItemAddress = {
  name: '',
  phone: '',
  email: '',
  provinceCode: null,
  districtCode: null,
  communeCode: null,
  addressDetail: '',
  note: '',
  defaultAddress: false
}

var mixinHandleAddress = {
  data() {
    return {
      lstDistrict: [],
      lstCommune: [],
      lstAddressByPage: [],
      itemAddressSelectedByPage: null,
      showAddressList: false,
      showCreateAndEditAddress: false,
      showEditOrder: false,
      itemAddress: {},
      itemAddressDelete: null,
      defaultAddressOrigin: false,
      showAddressConfirmDel: false,
      textAcceptAddressConfirmDel: '',
      totalPage: 0,
      totalRecord: 0,
      page: 1
    }
  },
  created() {
    this.textAcceptAddressConfirmDel = this.$t('delete')
    this.textAccept = this.$t('delete_order')
  },
  methods: {
    async getAddressByPage() {
      this.showLoading = false
      const pars = {
        page: this.page,
        limit: constants.pageSize,
      }
      const { status, data } = await UserService.addressGetAll(pars)
      this.showLoading = false
      if (
        status === constants.statusCode.ok &&
        data !== null &&
        data.content.length > 0
      ) {
        this.lstAddressByPage = this.bindCheckedValue(data.content)
        this.totalPage = data.totalPages
        this.totalRecord = data.totalElements
      }
    },
    bindCheckedValue(data) {
      if (
        typeof this.itemAddressSelectedByPage !== constants.undefined &&
        this.itemAddressSelectedByPage
      ) {
        data.forEach((i) => {
          if (i.id === this.itemAddressSelectedByPage.id) {
            i['checked'] = true
          } else {
            i['checked'] = false
          }
        })
      } else {
        data.forEach((i) => {
          if (i.id === this.itemAddressSelected.id) {
            i['checked'] = true
          } else {
            i['checked'] = false
          }
        })
      }

      return data
    },
    onShowAddressCreate() {
      this.itemAddress = { ...initItemAddress }
      this.defaultAddressOrigin = this.itemAddress.defaultAddress
      this.showCreateAndEditAddress = true
    },
    onAddressCreateOrUpdate(item) {
      if (typeof item.id !== constants.undefined && item.id > 0) {
        this.addressUpdate(item)
      } else {
        this.addressCreate(item)
      }
    },
    async addressUpdate(item) {
      this.showLoading = true
      const pars = { ...item }
      const { status, data } = await UserService.addressUpdate(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t('success_saved_info')
        })
        if (item.id === this.itemAddressSelected.id) {
          const itemChange = this.lstAddressByPage.find((o) => o.id === item.id)
          this.itemAddressSelected = itemChange
        }

        if (item.defaultAddress) {
          await this.getAddressDefault(false)
        }

        await this.getAddressByPage()

        setTimeout(() => {
          this.toggleDialogNoti()
          this.onClickCancelDialogCreateAddress()
        }, constants.timeOut)
      } else {
        this.toggleDialogNoti({
          state: true,
          msg: data.message
        })
      }
    },
    async addressCreate(item) {
      this.showLoading = true
      const pars = { ...item }
      pars['custId'] = StorageService.getUserId()
      const { status, data } = await UserService.addressCreate(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t('create_address_success')
        })

        if (!this.itemAddressDefault) {
          await this.getAddressDefault(true)
        }

        if (item.defaultAddress) {
          await this.getAddressDefault(false)
        }

        this.getAddressByPage()
        setTimeout(() => {
          this.toggleDialogNoti()
          this.onClickCancelDialogCreateAddress()
        }, constants.timeOut)
      } else {
        this.toggleDialogNoti({
          state: true,
          msg: data.message
        })
      }
    },
    onShowAddressList() {
      this.bindCheckedValue(this.lstAddressByPage)
      this.showAddressList = true
    },
    onGoAddAddressFromList() {
      this.showAddressList = false
      this.onShowAddressCreate()
    },
    async onClickCancelDialogCreateAddress() {
      this.showCreateAndEditAddress = false
      if (
        this.lstAddressByPage.length !== 1 &&
        this.itemAddressSelected !== null
      ) {
        this.onShowAddressList()
      }
    },
    onDetailAddress(item) {
      if (item !== null && item.id > 0) {
        this.itemAddress = { ...item }
        this.defaultAddressOrigin = item.defaultAddress
        this.showCreateAndEditAddress = true
      }
    },
    async onShowConfirmDeleteAddress(item) {
      this.message = this.$t('are_you_delete_address')
      this.typeNoti = constants.typeAlert.warning
      this.showAddressConfirmDel = true
      this.itemAddressDelete = item
    },
    onCancelAddressConfirmDel() {
      this.showAddressConfirmDel = false
      this.message = ''
    },
    async onAcceptAddressConfirmDel(item) {
      this.showAddressConfirmDel = false
      if (item !== null && !item.defaultAddress) {
        this.showLoading = true
        const pars = { id: item.id }
        const { status, data } = await UserService.addressDelete(pars)
        this.showLoading = false
        if (status === constants.statusCode.ok) {
          if (data.id === this.itemAddressSelected.id) {
            this.itemAddressSelected = this.itemAddressDefault
          }
          await this.getAddressByPage()
        } else {
          this.toggleDialogNoti({
            state: true,
            msg: data.message
          })
        }
      }
    },
    onAcceptSelectedAddress() {
      if (
        typeof this.itemAddressSelectedByPage !== constants.undefined &&
        this.itemAddressSelectedByPage
      ) {
        this.showAddressList = false
        this.itemAddressSelected = this.itemAddressSelectedByPage
        this.inputPhone(this.phone);
        this.calculateShipFee()
        this.itemAddressSelectedByPage = null
        return
      }

      const itemSelected = this.getAddressSelected()
      if (typeof itemSelected !== constants.undefined && itemSelected.id > 0) {
        this.showAddressList = false
        this.itemAddressSelected = itemSelected
        this.inputPhone(this.phone);
        this.calculateShipFee()
      } else {
        this.toggleDialogNoti({
          state: true,
          msg: this.$t('select_sender_address')
        })
      }
    },
    getAddressSelected() {
      return this.lstAddressByPage.find((o) => o.checked.toString() === 'true')
    },
    onSelectedAddress(item) {
      this.itemAddressSelectedByPage = item
    },
    onChangePage(val) {
      this.page = val
      this.getAddressByPage()
    }
  }
}

export default mixinHandleAddress
