var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"col-12",attrs:{"cols":"sm-12"}},[_c('v-col',{staticClass:"border-primary rounded-lg px-3 px-sm-6 py-6"},[_c('validation-observer',{ref:"observer_bank",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitBank.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{staticClass:"col-12",attrs:{"cols":"sm-6"}},[_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("bank_name"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"vid":"bankName","name":"selectRequire","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"clearable":"","outlined":"","dense":"","hide-no-data":"","item-value":"id","placeholder":_vm.$t('place_holders.bank_name'),"item-text":_vm.getItemBankText,"items":_vm.lstBank,"error-messages":errors},model:{value:(_vm.bankSelected),callback:function ($$v) {_vm.bankSelected=$$v},expression:"bankSelected"}})]}}],null,true)})],1)],1),_c('v-col',{staticClass:"col-12",attrs:{"cols":"sm-6"}},[_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("account_name"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"vid":"accountName","name":"require","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","placeholder":_vm.$t('place_holders.account_name'),"maxlength":_vm.maximumSmallText,"error-messages":errors},on:{"keypress":function($event){return _vm.isLetter($event)},"change":function($event){_vm.accountName = _vm.trimSpace(_vm.accountName)}},model:{value:(_vm.accountName),callback:function ($$v) {_vm.accountName=$$v},expression:"accountName"}})]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-12",attrs:{"cols":"sm-6"}},[_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("branch_name"))+" ")]),_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","placeholder":_vm.$t('place_holders.branch_name'),"maxlength":_vm.maximumSmallText},on:{"change":function($event){_vm.branchName = _vm.trimSpace(_vm.branchName)}},model:{value:(_vm.branchName),callback:function ($$v) {_vm.branchName=$$v},expression:"branchName"}})],1)],1),_c('v-col',{staticClass:"col-12",attrs:{"cols":"sm-6"}},[_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("account_number"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"vid":"accountNumber","name":"require","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"type":"text","pattern":"\\d*","outlined":"","dense":"","placeholder":_vm.$t('place_holders.account_number'),"maxlength":_vm.maximumSmallText,"error-messages":errors},on:{"keypress":function($event){return _vm.isNumber($event)},"paste":function($event){$event.preventDefault();return _vm.onPastePhone($event)}},model:{value:(_vm.accountNumber),callback:function ($$v) {_vm.accountNumber=$$v},expression:"accountNumber"}})]}}],null,true)})],1)],1)],1),_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)]}}])})],1)],1)],1)],1),_c('dialog-notification',{attrs:{"icon-src":_vm.getIcon,"message":_vm.message},model:{value:(_vm.showNoti),callback:function ($$v) {_vm.showNoti=$$v},expression:"showNoti"}}),_c('dialog-confirm-password',{attrs:{"width-dialog":600},on:{"on-submit":_vm.onSubmitConfirmPassword},model:{value:(_vm.showConfirmPassword),callback:function ($$v) {_vm.showConfirmPassword=$$v},expression:"showConfirmPassword"}}),_c('DialogConfirmTokenExpried',{model:{value:(_vm.showConfirmTokenExpried),callback:function ($$v) {_vm.showConfirmTokenExpried=$$v},expression:"showConfirmTokenExpried"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }