var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('div',{staticStyle:{"align-self":"center"}},[(_vm.isShowIconDel)?_c('v-icon',{attrs:{"slot":"prepend"},on:{"click":function($event){return _vm.onDeleteItem(_vm.idx)}},slot:"prepend"},[_vm._v(" "+_vm._s('mdi-delete')+" ")]):_c('v-icon',{staticClass:"ml-6",attrs:{"slot":"prepend"},slot:"prepend"})],1),_c('v-layout',{staticClass:"item-order",attrs:{"column":""}},[_c('v-layout',[_c('v-layout',{staticClass:"mt-4 mx-4",attrs:{"column":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("index_package"))+" "+_vm._s(_vm.idx + 1)+" "),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("icon_required"))+" ")])]),_c('validation-provider',{attrs:{"name":"require","vid":"productDescription","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","maxlength":_vm.maximumSmallText,"error-messages":errors,"placeholder":_vm.$t('place_holders.product_name')},model:{value:(_vm.item.productDescription),callback:function ($$v) {_vm.$set(_vm.item, "productDescription", $$v)},expression:"item.productDescription"}})]}}])})],1)],1),_c('v-layout',{staticClass:"mx-4"},[_vm._v(" "+_vm._s(_vm.$t("calculate_size"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("icon_required"))+" ")]),_c('v-icon',[_vm._v("mdi-compare-horizontal")]),_vm._v(" "+_vm._s(_vm.$t("tranfer_weight"))+": "+_vm._s(_vm.computedEstimateWeight(_vm.item.length, _vm.item.width, _vm.item.height))+_vm._s(_vm.$t("kg"))+" ")],1),_c('v-row',{staticClass:"justify-center mx-1"},[_c('validation-provider',{staticClass:"container col-sm-4 col-12",attrs:{"name":"require","vid":"length","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pr-sm-2",attrs:{"outlined":"","dense":"","suffix":"cm","maxlength":_vm.maximumSizeLwH,"error-messages":errors,"placeholder":_vm.$t('place_holders.length')},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.onInputLength,"paste":function($event){$event.preventDefault();return _vm.onPasteLength($event)},"blur":function($event){return _vm.onBlurValue($event, _vm.idx, _vm.item)}},model:{value:(_vm.item.length),callback:function ($$v) {_vm.$set(_vm.item, "length", $$v)},expression:"item.length"}})]}}])}),_c('validation-provider',{staticClass:"container col-sm-4 col-12 px-sm-0 py-0 py-sm-3",attrs:{"name":"require","vid":"width","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","suffix":"cm","prepend-icon":"mdi-plus","maxlength":_vm.maximumSizeLwH,"error-messages":errors,"placeholder":_vm.$t('place_holders.width')},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.onInputWidth,"paste":function($event){$event.preventDefault();return _vm.onPasteWidth($event)},"blur":function($event){return _vm.onBlurValue($event, _vm.idx, _vm.item)}},model:{value:(_vm.item.width),callback:function ($$v) {_vm.$set(_vm.item, "width", $$v)},expression:"item.width"}})]}}])}),_c('validation-provider',{staticClass:"container col-sm-4 col-12",attrs:{"name":"require","vid":"height","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-sm-2",attrs:{"outlined":"","dense":"","suffix":"cm","prepend-icon":"mdi-plus","maxlength":_vm.maximumSizeLwH,"error-messages":errors,"placeholder":_vm.$t('place_holders.height')},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.onInputHeight,"paste":function($event){$event.preventDefault();return _vm.onPasteHeight($event)},"blur":function($event){return _vm.onBlurValue($event, _vm.idx, _vm.item)}},model:{value:(_vm.item.height),callback:function ($$v) {_vm.$set(_vm.item, "height", $$v)},expression:"item.height"}})]}}])})],1),_c('v-layout',{staticClass:"mx-4"},[_c('v-row',[_c('v-col',{staticClass:"col-12 pb-0 mb-2",attrs:{"cols":"sm-6"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("order_weight"))),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("icon_required")))])]),_c('validation-provider',{attrs:{"name":"orderWeight","vid":"orderWeight","rules":_vm.orderWeightRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:_vm.item.orderWeightError ? 'error--text v-input--has-state': '',attrs:{"dense":"","suffix":"kg","outlined":"","hide-details":_vm.item.orderWeightHideError,"maxlength":6,"error-messages":errors,"placeholder":_vm.$t('place_holders.type_weight')},on:{"keypress":function($event){return _vm.isDecimal($event)},"input":_vm.onInputOrderWeight,"paste":function($event){$event.preventDefault();return _vm.onPasteOrderWeight($event)},"blur":function($event){_vm.item.orderWeightHideError === false ? _vm.onBlurValue($event, _vm.idx, _vm.item) : ''}},model:{value:(_vm.item.orderWeight),callback:function ($$v) {_vm.$set(_vm.item, "orderWeight", $$v)},expression:"item.orderWeight"}}),(_vm.item.orderWeightError)?_c('div',{staticClass:"v-messages theme--light error--text mt-1 ml-3",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.item.orderWeightError)+" ")])])]):_vm._e()]}}])})],1),_c('v-col',{staticClass:"col-12 pt-0 pt-sm-3",attrs:{"cols":"sm-6"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("order_value"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("icon_required"))+" ")])]),_c('validation-provider',{attrs:{"name":"orderValue","vid":"orderValue","rules":_vm.orderValueRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:_vm.item.orderValueError ? 'error--text v-input--has-state': '',attrs:{"dense":"","outlined":"","maxlength":_vm.maximumPriceFm,"error-messages":errors,"hide-details":_vm.item.orderValueHideError,"placeholder":_vm.$t('place_holders.type_value_order')},on:{"keypress":function($event){return _vm.isNumberValue($event)},"input":_vm.onInputOrderValue,"paste":function($event){$event.preventDefault();return _vm.onPasteOrderValue($event)},"blur":function($event){return _vm.onBlurValue($event, _vm.idx, _vm.item)}},model:{value:(_vm.item.orderValue),callback:function ($$v) {_vm.$set(_vm.item, "orderValue", $$v)},expression:"item.orderValue"}}),(_vm.item.orderValueError)?_c('div',{staticClass:"v-messages theme--light error--text mt-1 ml-3",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.item.orderValueError)+" ")])])]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }