import constants from '@/constants'
import moment from 'moment'
import { BaseService } from './baseService'

export class StorageService {
  /**
   * Save current user, token and token expried to storage
   */
  static setCurrentUser({ currentUser }) {
    localStorage.setItem(constants.CURRENT_USER, JSON.stringify(currentUser))
    localStorage.setItem(constants.TOKEN, currentUser.accessToken)
    // const expDate = moment().add(timeExpried, 'seconds').format()
    localStorage.setItem(constants.TOKEN_EXP, currentUser.tokenExpiredAt)
  }

  static getCurrentUser() {
    var userStr = localStorage.getItem(constants.CURRENT_USER)
    if (userStr !== null) {
      return JSON.parse(userStr)
    }

    return null
  }

  static checkTokenTimeout() {
    // Validate exp token
    const expDate = localStorage.getItem(constants.TOKEN_EXP)
    if (!expDate) {
      return false
    }

    const currentDate = moment().format()
    const checkTime = moment(currentDate).diff(moment(expDate), 'seconds')
    // Kiem tra thoi gian hien tai > thoi gian timeout return false
    if (checkTime >= 0) {
      this.clearLocalStorage()
      return true
    }
    return false
  }

  static getPhone() {
    const user = this.getCurrentUser()
    if (user !== null) {
      return user.username
    }

    return null
  }

  static getUserId() {
    const user = this.getCurrentUser()
    if (user !== null) {
      return user.id
    }

    return null
  }

  static clearLocalStorage() {
    localStorage.removeItem(constants.TOKEN)
    localStorage.removeItem(constants.TOKEN_EXP)
    localStorage.removeItem(constants.CURRENT_USER)
    localStorage.clear()
  }

  static setUserName(name) {
    localStorage.setItem(constants.USER_NAME, name)
  }

  static getUserName() {
    return localStorage.getItem(constants.USER_NAME)
  }

  static removeUserName() {
    localStorage.removeItem(constants.USER_NAME)
  }

  static setTimeGetOtp(datetime) {
    localStorage.setItem(constants.TIME_GET_OTP, datetime)
  }

  static getTimeGetOtp() {
    return localStorage.getItem(constants.TIME_GET_OTP)
  }

  static removeTimeGetOtp() {
    localStorage.removeItem(constants.TIME_GET_OTP)
  }

  static setProvinces(data) {
    localStorage.setItem(constants.PROVINCES, data)
  }

  static async getProvinces() {
    var json = localStorage.getItem(constants.PROVINCES)
    if (json !== null) {
      return JSON.parse(json)
    }

    const { data } = await BaseService.getProvinceAll()
    if (data !== null && data.length > 0) {
      this.setProvinces(JSON.stringify(data))
      return data
    }

    return null
  }

  static setDistricts(data) {
    localStorage.setItem(constants.DISTRICTS, data)
  }

  static async getDistricts() {
    var json = localStorage.getItem(constants.DISTRICTS)
    if (json !== null) {
      return JSON.parse(json)
    }

    const { data } = await BaseService.getAllDistrict()
    if (data !== null && data.length > 0) {
      this.setDistricts(JSON.stringify(data))
      return data
    }

    return null
  }

  static setCommunes(data) {
    localStorage.setItem(constants.COMMUNES, data)
  }

  static async getCommunes() {
    var json = localStorage.getItem(constants.COMMUNES)
    if (json !== null) {
      return JSON.parse(json)
    }

    const { data } = await BaseService.getAllCommune()
    if (data !== null && data.length > 0) {
      this.setCommunes(JSON.stringify(data))
      return data
    }

    return null
  }

  static checkResponseExpried(status, data) {
    const result =
      status === constants.statusCode.badRequest &&
      typeof data.message !== constants.undefined &&
      data.message.toLowerCase() === 'token invalid.'
    if (result) {
      this.clearLocalStorage()
    }
    return result
  }

  static getPostOfficeId() {
    var user = this.getCurrentUser()
    if (user !== null) {
      return user.postOfficeId
    }

    return -1
  }
}
