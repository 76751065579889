<template>
  <v-layout>
    <v-layout column>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-layout column class="border-primary rounded pa-2 px-lg-6 py-sm-3">
          <v-row>
            <v-col cols="sm-6" class="col-12">
              <v-layout column>
                <!-- Địa chỉ người gửi -->
                <v-layout justify-start>
                  <h4>
                    {{ $t("info-sender") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                  </h4>
                </v-layout>
                <validation-provider
                  nane="address_sender"
                  :rules="requireRules"
                >
                  <v-text-field v-show="false" v-model="itemAddressSelected" />
                </validation-provider>
                <v-btn
                  v-if="isShowAddressCreateForOrder"
                  class="container--fluid"
                  outlined
                  :disabled="!disableAction"
                  @click="onShowAddressCreate"
                >
                  {{ $t("add_sender_address") }}
                </v-btn>
                <v-layout v-else class="border rounded pl-2 pr-8 my-2 py-2">
                  <v-row>
                    <v-col cols="sm-8" class="col-12">
                      <v-layout column class="mr-4">
                        <label class="max-line-2 text-subtitle-2">
                          {{
                            getTextTruncateView(itemAddressSelected.name, 30)
                          }}
                          <span
                            v-if="itemAddressSelected.defaultAddress"
                            class="error--text text-min"
                          >
                            [{{ $t("default") }}]
                          </span>
                        </label>
                        <label
                          class="text-caption blur--text line-height-small"
                        >
                          {{ itemAddressSelected.phone }}
                        </label>
                        <label
                          class="text-caption blur--text line-height-small max-line-2"
                        >
                          {{
                            `${getTextTruncateView(
                              itemAddressSelected.addressDetail,
                              30
                            )}, ${getTextTruncateView(
                              itemAddressSelected.communeName,
                              30
                            )}, ${getTextTruncateView(
                              itemAddressSelected.districtName,
                              30
                            )}, ${getTextTruncateView(
                              itemAddressSelected.provinceName,
                              30
                            )}`
                          }}
                        </label>
                      </v-layout>
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <v-btn
                        color="primary"
                        outlined
                        @click="onShowAddressList"
                      >
                        {{ $t("change") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-layout>
              </v-layout>
              <v-layout justify-start>
                <h4>{{ $t("shipping_method") }}</h4>
              </v-layout>
              <v-layout justify-start>
                <v-radio-group
                  v-model="transportType"
                  mandatory
                  row
                  class="mt-0"
                  @change="onChangeTransportType"
                >
                  <v-radio
                    :label="$t('customer_bring_order')"
                    :value="transportTypeConst.individual"
                  />
                  <v-radio
                    :label="$t('shipper_pickup')"
                    :value="transportTypeConst.ship"
                  />
                </v-radio-group>
              </v-layout>

              <v-layout>
                <v-row v-if="isShowPickUpDateShipping">
                  <v-col cols="sm-6" class="col-12 pb-0">
                    <div>
                      {{ $t("pickup_date") }}
                      <span class="red--text">
                        {{ $t("icon_required") }}
                      </span>
                    </div>
                    <v-menu
                      v-model="menuPickUpDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="selectRequire"
                          vid="datePicker"
                          :rules="requireRules"
                        >
                          <v-text-field
                            v-model="pickUpDateFm"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            :placeholder="$t('place_holders.input_date')"
                            :error-messages="errors"
                            v-bind="attrs"
                            @blur="pickUpDate = parseDate(pickUpDateFm)"
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="pickUpDate"
                        no-title
                        :locale="locale"
                        :allowed-dates="
                          partnerSelect === constants.partnerGroup.ninjaVan
                            ? allowedDates
                            : null
                        "
                        :min="minAllowedDate"
                        :max="maxAllowedDate"
                        @input="onInputPickUpDate"
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="sm-6" class="col-12 pt-0 pt-sm-3">
                    <div>
                      {{ $t("pickup_time") }}
                      <span class="red--text">
                        {{ $t("icon_required") }}
                      </span>
                    </div>
                    <validation-provider
                      v-slot="{ errors }"
                      ref="pickWorkShip"
                      name="selectRequire"
                      vid="pickWorkShip"
                      :rules="requireRules"
                    >
                      <v-select
                        v-model="pickWorkShip"
                        outlined
                        dense
                        item-text="name"
                        item-value="code"
                        :disabled="timePickerDisabled"
                        :items="listPickWorkShipGhtk"
                        :error-messages="errors"
                        :placeholder="$t('place_holders.time_picker')"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-layout>
            </v-col>
            <v-col>
              <v-layout justify-start>
                <h4>{{ $t("info-shipping") }}</h4>
              </v-layout>
              <v-layout>
                <v-row>
                  <v-col>
                    <div>
                      {{ $t("group-shipping") }}
                      <span class="red--text">
                        {{ $t("icon_required") }}
                      </span>
                    </div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="require"
                      vid="partnerName"
                      :rules="requireRules"
                    >
                      <v-select
                        v-model="partnerSelect"
                        outlined
                        dense
                        :disabled="!disableAction"
                        :items="lstPartnerCreateMulti"
                        item-text="partnerName"
                        item-value="partnerId"
                        :error-messages="errors"
                        :placeholder="$t('place_holders.choice_group_shipping')"
                        @change="onChangePartner"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-layout>
              <v-layout>
                <v-row>
                  <v-col>
                    <div>
                      {{ $t("shipping_service") }}
                      <span class="red--text">
                        {{ $t("icon_required") }}
                      </span>
                    </div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="require"
                      vid="serviceName"
                      :rules="requireRules"
                    >
                      <v-select
                        v-model="serviceSelect"
                        outlined
                        dense
                        item-text="serviceDescription"
                        item-value="serviceId"
                        :disabled="!partnerSelect || !disableAction"
                        :items="lstService"
                        :error-messages="errors"
                        :placeholder="$t('place_holders.ship_service')"
                        @change="onChangeServiceDataExcel"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-layout>
              <!-- Ghi chú giao hàng -->
              <v-layout v-if="requireNote">
                <v-row>
                  <v-col>
                    <div>
                      {{ $t("note_order") }}
                      <span class="red--text">
                        {{ $t("icon_required") }}
                      </span>
                    </div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="selectRequire"
                      vid="deliveryNote"
                      :rules="requireRules"
                    >
                      <v-select
                        v-model="deliveryNoteSelected"
                        outlined
                        dense
                        class="rounded"
                        item-text="name"
                        item-value="code"
                        hide-no-data
                        :disabled="!disableAction"
                        :items="lstDeliveryNote"
                        :placeholder="$t('place_holders.type_order_note')"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-layout>
              <!-- dich vu -->
              <div class="d-flex">
                <v-layout column>
                  <div>
                    {{ $t("establish-service") }}
                    <span v-if="arrayListFee.length > 0">
                      ({{ arrayListFee.length }})
                    </span>
                  </div>
                  <v-select
                    v-model="feeSelected"
                    class="select-action"
                    outlined
                    dense
                    item-disabled
                    :disabled="!partnerSelect || !disableAction"
                    hide-details
                    :placeholder="$t('place_holders.order_status')"
                    :items="lstFeeTag"
                    item-text="name"
                    item-value="id"
                    multiple
                  >
                    <template v-slot:item="{ item }">
                      <div
                        class="d-flex align-center justify-space-between"
                        style="flex: 1"
                      >
                        <div class="d-flex">
                          <div
                            class="checkbox-action"
                            :style="item.isDisableFee ? 'cursor: default' : ''"
                          >
                            <v-checkbox
                              v-model="checkItemFee"
                              :disabled="item.isDisableFee"
                              :value="item.id"
                              hide-details
                              @change="onChangeCheckBox"
                            />
                          </div>
                          <span
                            :style="
                              item.isDisableFee
                                ? 'cursor: default; color: black'
                                : 'color: black'
                            "
                            >{{ item.name }}</span
                          >
                        </div>
                        <div>
                          <v-tooltip
                            top
                            max-width="250"
                            color="white"
                            class="box-shadow"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                style="align-self: self-end"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span
                                  style="
                                    color: #0f90e8;
                                    text-decoration: underline;
                                    cursor: pointer;
                                  "
                                  >{{ item.description }}</span
                                >
                              </div>
                            </template>
                            <label v-html="item.note" />
                          </v-tooltip>
                        </div>
                      </div>
                    </template>
                    <template v-slot:selection="{ index }">
                      <label v-if="checkItemFee && index === 0">
                        {{ $t("place_holders.order_status") }}
                      </label>
                    </template>
                  </v-select>
                  <v-layout v-if="checkItemFee.length > 0" column class="mt-2">
                    <v-layout
                      v-for="(item, index) in arrayListFee"
                      :key="index"
                    >
                      <b style="font-size: 14px">{{ item.name }}</b>
                    </v-layout>
                  </v-layout>
                </v-layout>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col>
              <v-layout column>
                <label class="font-weight-bold">
                  {{ $t("order_info") }} ({{ excelData.length }})
                </label>
                <div v-if="disableAction" class="d-flex align-center">
                  <label class="text-body-2 color-danger">
                    {{ $t("invalid_order") }} ({{ errorItems.length }})
                  </label>
                  <v-checkbox
                    v-model="invalidOrderSelected"
                    class="pa-0 mt-0"
                    hide-details
                    @click="checkInvalidOrder"
                  />
                </div>
              </v-layout>
            </v-col>
            <v-col>
              <v-layout justify-end xs-column>
                <v-btn
                  outlined
                  :disabled="!disableAction"
                  color="primary"
                  class="mr-3 mb-2 sm-mb-0"
                  @click="downloadFile"
                >
                  <v-icon class="mr-2">mdi-tray-arrow-down</v-icon>
                  {{ $t("download_template_file") }}
                </v-btn>
                <v-btn
                  :disabled="check() || !disableAction"
                  :loading="loadingFile"
                  color="primary"
                  class="mb-2 sm-mb-0"
                  :class="fileName ? 'text-transform-none' : ''"
                  @click="handleFileImport"
                >
                  <v-icon class="mr-2">mdi-tray-arrow-up</v-icon>
                  {{
                    fileName
                      ? getTextTruncateView(fileName, 15)
                      : $t("select_file_upload")
                  }}
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept=".xlsx"
                  @change="handleExcelUpload"
                />
              </v-layout>
            </v-col>
          </v-row>
          <v-data-table
            hide-default-footer
            class="border-primary"
            :height="computedHeightTable"
            :no-data-text="$t('no_data')"
            :headers="headers"
            :header-props="{ sortIcon: 'mdi-menu-swap' }"
            :items="items"
            :items-per-page="items.length"
          >
            <template v-slot:[`item.stt`]="{ index }">
              <td class="max-width-5">
                {{ index + 1 }}
              </td>
            </template>
            <template v-slot:[`item.receiverName`]="{ item }">
              <td
                class="max-width-5"
                :style="item.receiverNameError ? 'color: red' : ''"
              >
                <v-tooltip top color="#000000" content-class="tooltip-top">
                  <template v-slot:activator="{ on, attrs }">
                    <span class="max-line-1" v-bind="attrs" v-on="on">
                      {{ item.receiverName }}
                    </span>
                  </template>
                  <span>{{ item.receiverName }}</span>
                </v-tooltip>
                <span v-if="!item.receiverName">-</span>
              </td>
            </template>
            <template v-slot:[`item.receiverPhone`]="{ item }">
              <td
                class="max-width-5"
                :style="item.receiverPhoneError ? 'color: red' : ''"
              >
                {{ item.receiverPhone }}
                <span v-if="!item.receiverPhone">-</span>
              </td>
            </template>
            <template
              v-slot:[`item.receiverProvinceDistrictCommune`]="{ item }"
            >
              <td
                class="max-width-5"
                :style="
                  item.receiverPoliticalAdress === '-' ||
                  item.receiverLocationError
                    ? 'color: red'
                    : ''
                "
              >
                <v-tooltip top color="#000000" content-class="tooltip-top">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-if="item.receiverPoliticalAdress === '-'">{{
                      `${item.receiverPoliticalAdress}`
                    }}</span>
                    <span v-else class="max-line-1" v-bind="attrs" v-on="on">
                      {{
                        `${item.receiverCommuneName}, ${item.receiverDistrictName}, ${item.receiverProvinceName}`
                      }}
                    </span>
                  </template>
                  <span>
                    {{
                      `${item.receiverCommuneName}, ${item.receiverDistrictName}, ${item.receiverProvinceName}`
                    }}
                  </span>
                </v-tooltip>
              </td>
            </template>
            <template v-slot:[`item.receiverAddress`]="{ item }">
              <td
                class="max-width-5"
                :style="item.receiverAddressError ? 'color: red' : ''"
              >
                <v-tooltip top color="#000000" content-class="tooltip-top">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ getTextTruncate(item.receiverAddress, 16) }}
                    </span>
                  </template>
                  <span>{{ item.receiverAddress }}</span>
                </v-tooltip>
                <span v-if="!item.receiverAddress">-</span>
              </td>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <td
                class="max-width-5"
                :style="item.descriptionError ? 'color: red' : ''"
              >
                <v-tooltip top color="#000000" content-class="tooltip-top">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ getTextTruncate(item.description, 15) }}
                    </span>
                  </template>
                  <span>{{ item.description }}</span>
                </v-tooltip>
                <span v-if="!item.description">-</span>
              </td>
            </template>
            <template v-slot:[`item.size`]="{ item }">
              <td
                class="max-width-5"
                :style="item.sizeError ? 'color: red' : ''"
              >
                <v-tooltip top color="#000000" content-class="tooltip-top">
                  <template v-slot:activator="{ on, attrs }">
                    <span class="max-line-1" v-bind="attrs" v-on="on">
                      {{ displaySize(item.length, item.width, item.height) }}
                    </span>
                  </template>
                  <span>
                    {{ displaySize(item.length, item.width, item.height) }}
                  </span>
                </v-tooltip>
              </td>
            </template>
            <template v-slot:[`item.weightTranfer`]="{ item }">
              <td
                v-if="lstItemCreate.length > 0"
                class="max-width-5"
                :style="item.sizeError ? 'color: red' : ''"
              >
                {{ item.estimateWeight === 0 ? "0.00" : item.estimateWeight }}
              </td>
              <td
                v-else
                class="max-width-5"
                :style="item.sizeError ? 'color: red' : ''"
              >
                {{
                  item.estimateWeight
                    ? item.estimateWeight
                    : formatDecimalView(item.weightTranfer)
                }}
                <span v-if="!item.weightTranfer && !item.estimateWeight">
                  -
                </span>
              </td>
            </template>
            <template v-slot:[`item.weight`]="{ item }">
              <td
                class="max-width-5"
                :style="item.weightError ? 'color: red' : ''"
              >
                {{ formatDecimalView(item.weight) }}
                <span v-if="!item.weight">-</span>
              </td>
            </template>
            <template v-slot:[`item.codValue`]="{ item }">
              <td
                class="max-width-5"
                :style="item.codValueError ? 'color: red' : ''"
              >
                {{ item.codValue }}
                <span v-if="!item.codValue && item.codValue !== 0">-</span>
              </td>
            </template>
            <template v-slot:[`item.orderValue`]="{ item }">
              <td
                class="max-width-5"
                :style="item.insuranceValueError ? 'color: red' : ''"
              >
                {{ item.orderValue }}
              </td>
            </template>
            <template v-slot:[`item.paymentBy`]="{ item }">
              <td
                class="max-width-5"
                :style="item.paymentByError ? 'color: red' : ''"
              >
                <!-- {{ getPaymentByText(item.paymentBy) }} -->
                {{ item.paymentBy }}
              </td>
            </template>
            <template v-slot:[`item.shipValue`]="{ item }">
              <td class="max-width-5">
                {{
                  item.shipValue
                    ? formatDecimalView(item.shipValue + item.serviceFeesValue)
                    : ""
                }}
              </td>
            </template>
            <template v-slot:[`item.totalValue`]="{ item }">
              <td class="max-width-5">
                {{
                  !isNaN(item.totalValue)
                    ? formatDecimalView(item.totalValue)
                    : ""
                }}
              </td>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <td
                class="max-width-5"
                :style="item.status === -1 ? 'color: red' : ''"
              >
                <v-tooltip
                  v-if="item.status === -1"
                  top
                  color="#000000"
                  content-class="tooltip-top"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ getTextStatus(item) }}
                    </span>
                  </template>
                  <span>{{ item.message }}</span>
                </v-tooltip>
                <span v-else>{{ getTextStatus(item) }}</span>
              </td>
            </template>
            <template v-slot:[`item.action`]="{ item, index }">
              <td class="fixed-column">
                <v-layout v-if="item.status !== 1">
                  <img
                    class="cursor-pointer"
                    src="@/assets/icons/ic-pencil.svg"
                    @click="editOrder(item.id, index)"
                  />
                  <img
                    class="cursor-pointer"
                    src="@/assets/icons/ic-trash.svg"
                    @click="deleteOrder(item.id)"
                  />
                </v-layout>
              </td>
            </template>
          </v-data-table>
        </v-layout>
        <!-- Button submit -->
        <v-layout class="mt-4 d-flex justify-end">
          <v-btn
            :disabled="showBack"
            color="primary"
            class="ml-3"
            @click="onBack"
          >
            {{ $t("back") }}
          </v-btn>
          <v-btn
            :disabled="
              invalid ||
              excelData.length === 0 ||
              errorItems.length > 0 ||
              disableBtnCreate
            "
            color="primary"
            class="ml-3"
            @click="onOrderCreateMultiple"
          >
            {{ $t("create_order") }}
          </v-btn>
        </v-layout>
      </validation-observer>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />
    <!-- Dialog loading file -->
    <dialog-preLoad :dialog="loading" />
    <!-- Dialog create and edit address -->
    <dialog-create-and-edit-address
      v-model="showCreateAndEditAddress"
      :item="itemAddress"
      :default-origin="defaultAddressOrigin"
      :lst-province="lstProvince"
      :on-address-action="onAddressCreateOrUpdate"
      :on-cancel-action="onClickCancelDialogCreateAddress"
    />

    <!-- Dialog Address List -->
    <dialog-address-list
      v-model="showAddressList"
      :items="lstAddressByPage"
      :on-add-address="onGoAddAddressFromList"
      :on-detail-address="onDetailAddress"
      :on-delete-address="onShowConfirmDeleteAddress"
      :on-accept-selected-address="onAcceptSelectedAddress"
      :on-selected-address="onSelectedAddress"
      :page="page"
      :total-page="totalPage"
      :total-record="totalRecord"
      :on-change-page="onChangePage"
    />

    <!-- Dialog edit order -->
    <dialog-edit-order
      v-model="showEditOrder"
      :is-edit="true"
      :detail-data="orderDetailData"
      :lst-district="lstDistrictOrder"
      :district-disabled="districtDisabledOrder"
      :lst-commune="lstCommuneOrder"
      :commune-disabled="communeDisabledOrder"
      @re-type="reType"
      @on-save-data="onSaveData"
    />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <!-- dialog confirm reset form -->
    <dialog-confirm
      v-model="showConfirmResetForm"
      :width-dialog="300"
      :icon-src="getIcon"
      :message="message"
      :message2="message2"
      :text-cancel="textCancel"
      :text-accept="textAcceptConfirmResetForm"
      :on-cancel="onCancelConfirmResetForm"
      :on-accept="onAcceptConfirmResetForm"
    />

    <!-- dialog confirm del address -->
    <dialog-confirm
      v-model="showAddressConfirmDel"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAcceptAddressConfirmDel"
      :on-cancel="onCancelAddressConfirmDel"
      :on-accept="onAcceptAddressConfirmDel"
      :data="itemAddressDelete"
    />

    <dialog-confirm
      v-model="showConfirmDel"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAccept"
      :on-cancel="onCancelConfirmDel"
      :on-accept="onAcceptConfirmDel"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />

    <!-- notifications -->
    <dialog-notification-html
      v-model="showNotiHtml"
      :icon-src="getIcon"
      :title="$t('error_data')"
      :message="messageHtml"
    />
  </v-layout>
</template>

<script>
import DialogCreateAndEditAddress from "./components/DialogCreateAndEditAddress.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogAddressList from "./components/DialogAddressList.vue";
import DialogEditOrder from "./components/DialogEditOrder.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import DialogNotificationHtml from "@/components/dialog/DialogNotificationHtml.vue";
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogPreLoad from "@/components/dialog/DialogPreLoad.vue";
import constants from "@/constants";
import mixinHandleAddress from "./mixins/mixiHandleAddress";
import mixinHandleOrderCreate from "./mixins/mixinHandleOrderCreate";
import constPickupTime from "./mixins/constPickupTime";
import { StringUtils } from "@/helpers/stringUtils";
import { DateTimeUtils } from "@/helpers/dateTimeUtils";
import { FormUtils } from "@/helpers/formUtils";

import XLSX from "js-xlsx";
import moment from "moment";

// services
import { StorageService } from "@/services/storageService";
import { UserService } from "@/services/userService";
import { OrderService } from "@/services/orderService";
import routePaths from "@/router/routePaths";
import { CommonService } from "@/services/commonService";
import { BaseService } from "@/services/baseService";

const arrExcelKeys = [
  "description",
  "length",
  "width",
  "height",
  "weight",
  "codValue",
  "orderValue",
  "paymentBy",
  "receiverName",
  "receiverPhone",
  "receiverAddress",
  "receiverProvinceName",
  "receiverDistrictName",
  "receiverCommuneName",
  "receiverPoliticalAdress",
  "tags",
  "weightTranfer",
];

export default {
  components: {
    DialogCreateAndEditAddress,
    DialogAddressList,
    DialogEditOrder,
    DialogNotification,
    DialogConfirm,
    DialogConfirmTokenExpried,
    DialogNotificationHtml,
    DialogLoading,
    DialogPreLoad,
  },
  mixins: [mixinHandleAddress, mixinHandleOrderCreate],
  data() {
    return {
      constants,
      requireRules: {
        required: true,
      },
      transportTypeConst: constants.transportType,
      formatDatePlaceholder: constants.formatDatePlaceholder,
      serviceFeePayersConst: constants.serviceFeePayers,
      userId: JSON.parse(localStorage.getItem("CURRENT_USER")).id,
      lstProvince: [],
      lstDeliveryNote: [],
      listAllDistricts: [],
      listAllCommunes: [],
      lstPartnerCreateMulti: [],
      lstServicePartner: [],
      lstService: [],

      transportType: constants.transportType.ship,
      isShowPickUpDateShipping: true,
      menuPickUpDate: false,
      pickUpDate: null,
      pickUpDateFm: null,
      minAllowedDate: "",
      maxAllowedDate: "",
      timePicker: null,
      timePickerList: [constPickupTime.time1],
      timePickerDisabled: true,

      itemAddressDefault: null,
      itemAddressSelected: null,

      selectedFile: null,
      fileName: "",
      excelData: [],
      disableAction: true,
      checkTransportType: true,
      loading: false,

      // table
      headers: [
        {
          text: "STT",
          value: "stt",
          class: "text-subtitle-2",
          width: "80px",
        },
        {
          text: this.$t("receiver_name"),
          value: "receiverName",
          class: "text-subtitle-2",
          width: "170px",
        },
        {
          text: this.$t("receiver_phone_title"),
          sortable: false,
          value: "receiverPhone",
          class: "text-subtitle-2",
          width: "160px",
        },
        {
          text: this.$t("receiver_province_district_commune"),
          sortable: false,
          value: "receiverProvinceDistrictCommune",
          class: "text-subtitle-2",
          width: "180px",
        },
        {
          text: this.$t("receiver_address"),
          sortable: false,
          value: "receiverAddress",
          class: "text-subtitle-2",
          width: "160px",
        },
        {
          text: this.$t("product_name"),
          sortable: false,
          value: "description",
          class: "text-subtitle-2",
          width: "160px",
        },
        {
          text: this.$t("size_cm"),
          sortable: false,
          value: "size",
          class: "text-subtitle-2",
          width: "120px",
        },
        {
          text: this.$t("tranfer_weight_kg"),
          sortable: false,
          value: "weightTranfer",
          class: "text-subtitle-2",
          width: "180px",
        },
        {
          text: this.$t("order_weight_kg"),
          sortable: false,
          value: "weight",
          class: "text-subtitle-2",
          width: "180px",
        },
        {
          text: this.$t("cod_vnd"),
          sortable: false,
          value: "codValue",
          class: "text-subtitle-2",
          width: "120px",
        },
        {
          text: this.$t("order_value_vnd"),
          sortable: false,
          value: "orderValue",
          class: "text-subtitle-2",
          width: "180px",
        },
        {
          text: this.$t("human_payment"),
          sortable: false,
          value: "paymentBy",
          class: "text-subtitle-2",
          width: "140px",
        },
        {
          text: this.$t("total_fee-service"),
          sortable: false,
          value: "shipValue",
          class: "text-subtitle-2",
          width: "140px",
        },
        {
          text: this.$t("total_revenue"),
          sortable: false,
          value: "totalValue",
          class: "text-subtitle-2",
          width: "180px",
        },
        {
          text: this.$t("status"),
          sortable: false,
          value: "status",
          class: "text-subtitle-2",
          width: "100px",
        },
        {
          // text: this.$t("total_revenue"),
          sortable: false,
          value: "action",
          class: "fixed-column text-subtitle-2",
          width: "80px",
        },
      ],
      items: [],
      beforeItems: [],

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
      showConfirmTokenExpried: false,
      textCancel: this.$t("cancel"),

      showNotiHtml: false,
      messageHtml: "",
      receiverProvinceCode: null,
      receiverProvinceName: null,
      receiverDistrictCode: null,
      receiverDistrictName: null,
      receiverCommuneCode: null,
      receiverCommuneName: null,
      estimateWeight: "",
      lstItemCreate: [],
      showLoading: false,
      statusItem: 0,
      disableBtnCreate: false,
      loadingFile: false,
    };
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
    isShowAddressCreateForOrder() {
      return (
        typeof this.itemAddressSelected === constants.undefined ||
        this.itemAddressSelected === null
      );
    },
    computedHeightTable() {
      return this.items.length > 7 ? "calc(100vh - 550px)" : "";
    },
  },
  watch: {
    pickUpDate(val) {
      this.pickUpDateFm = this.formatDate(this.pickUpDate);
    },
  },
  created() {
    this.calculateMinMaxAllowedDate();
    this.initData();
  },
  methods: {
    check() {
      return this.transportType === constants.transportType.ship &&
        this.partnerSelect === constants.partnerGroup.ninjaVan
        ? this.serviceSelect === null ||
            this.timePicker === null ||
            this.pickWorkShip === null
        : this.serviceSelect === null;
    },
    async initData() {
      try {
        await Promise.all([
          // this.getLastTransportType(),
          this.getProvinces(),
          this.getDistricts(),
          this.getCommunes(),
          this.getAddressDefault(true),
          this.getAddressByPage(),
          this.getListDeliveryNote(),
          this.getAllPickWorkShip(),
          this.getPartners(),
        ]);
      } catch (error) {
        console.log(error);
      }
    },
    async getPartners() {
      try {
        this.lstPartnerCreateMulti = [];
        const { status, data } = await BaseService.getLogisticPartner();
        if (
          status === constants.statusCode.ok &&
          data !== null &&
          data.length > 0
        ) {
          this.lstServicePartner = data;
          this.lstPartnerCreateMulti = data.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.partnerId === item.partnerId)
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onChangePartner(val) {
      this.checkedFeeLst = [];
      const service = this.lstPartnerCreateMulti.find(
        (it) => it.partnerId === val
      );
      this.lstService = this.lstServicePartner.filter(
        (it) => it.partnerId === val
      );
      if (service) {
        this.requireNote = service.requireNote;
        await this.getEstablishServiceByPartner(this.partnerSelect);
      }
    },
    async getProvinces() {
      this.lstProvince = await StorageService.getProvinces();
    },
    async getDistricts() {
      this.listAllDistricts = await StorageService.getDistricts();
    },
    async getCommunes() {
      this.listAllCommunes = await StorageService.getCommunes();
    },
    async getAddressDefault(isBindSelected) {
      this.showLoading = false;
      const { status, data } = await UserService.addressGetDefault();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data !== null) {
        this.itemAddressDefault = data;
        if (isBindSelected) {
          this.itemAddressSelected = data;
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    async getListDeliveryNote() {
      this.showLoading = false;
      const { status, data } = await CommonService.getListDeliveryNote();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.lstDeliveryNote = data;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    calculateMinMaxAllowedDate() {
      const currDate = moment().format(constants.formatDateYmd);
      const hours = moment().format("HH");
      if (hours < constants.limitHourOrderCreate) {
        this.minAllowedDate = currDate;
      } else {
        let nextDate = DateTimeUtils.getTomorrow();
        if (
          this.isDayOff(nextDate) &&
          this.partnerSelect === constants.partnerGroup.ninjaVan
        ) {
          nextDate = DateTimeUtils.getNextDateByAddDay(nextDate, 1);
        } else {
          nextDate = DateTimeUtils.getNextDateByAddDay(nextDate, 0);
        }
        this.minAllowedDate = nextDate;
      }
      this.maxAllowedDate = this.getMaxAllowedDate(this.minAllowedDate);
    },
    getMaxAllowedDate(minDate) {
      var dayOfWeek = moment(minDate).day();
      var dayByPartner = null;
      if (this.partnerSelect === constants.partnerGroup.ninjaVan) {
        dayByPartner = 7 - dayOfWeek > 2;
      } else {
        dayByPartner = 7 - dayOfWeek >= 2;
      }
      if (dayByPartner) {
        return DateTimeUtils.getNextDateByAddDay(
          minDate,
          constants.numberDateAllowedOrderCreate
        );
      } else {
        return DateTimeUtils.getNextDateByAddDay(
          minDate,
          this.partnerSelect === constants.partnerGroup.ninjaVan
            ? constants.numberDateAllowedOrderCreate + 1
            : constants.numberDateAllowedOrderCreate
        );
      }
    },
    allowedDates(date) {
      return !this.isDayOff(date);
    },
    isDayOff(date) {
      const dateCompare = new Date(date).getDay();
      return dateCompare === 0;
    },
    onInputPickUpDate(val) {
      this.menuPickUpDate = false;
      this.timePickerDisabled = false;
      var newDate = new Date(val);
      var oldDate = new Date(this.parseDate(this.pickUpDateFm));
      if (newDate.getTime() !== oldDate.getTime()) {
        // this.calculateShipFeeExcel();
      }
    },
    onChangeTransportType(val) {
      if (val === constants.transportType.ship) {
        this.timePicker = null;
        this.pickUpDate = null;
        this.pickUpDateFm = null;
        this.calculateMinMaxAllowedDate();
        this.disableBtnCreate = false;
        this.isShowPickUpDateShipping = true;
      } else {
        this.timePicker = null;
        this.pickUpDate = null;
        this.pickUpDateFm = null;
        this.isShowPickUpDateShipping = false;
        this.calculateShipFee();
      }
      // this.calculateShipFeeExcel();
    },
    onChangeTimePicker() {
      this.disableBtnCreate === true;
      this.calculateShipFee();
    },
    handleFileImport() {
      this.$refs.uploader.click();
    },
    async handleExcelUpload(evt) {
      this.invalidOrderSelected = null;
      if (evt.target.files.length > 0) {
        this.loading = true;
        setTimeout(async() => {
          const file = evt.target.files[0];
          this.fileName = file.name;
          /* Boilerplate to set up FileReader */
          const reader = new FileReader();
          reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            this.mapDataJsonToObjects(data);
          };
          reader.readAsBinaryString(file);
        }, 1500);
        this.loadingFile = true;
      } else {
        this.fileName = "";
        this.excelData = [];
        this.items = [];
      }
    },
    async mapDataJsonToObjects(dataJson) {
      const results = [];
      dataJson.forEach((item, index) => {
        if (index !== 0) {
          const newItem = this.mappingArrayDataToObj(item);
          this.loading = false;
          this.loadingFile = false;
          if (newItem) {
            results.push(newItem);
          }
        }
      });
      this.excelData = results;
      this.items = results;
      this.items.forEach((item, index) => {
        item.id = index;
        item.receiverProvinceCode = this.validateAddressInfo(
          item.receiverProvinceName
        );
        item.receiverDistrictName = this.validateDisTrictInfo(
          item.receiverDistrictName
        );
        item.receiverDistrictCode =
          this.receiverDistrictCode !== undefined
            ? this.receiverDistrictCode
            : null;
        item.receiverCommuneName = this.validateCommuneInfo(
          item.receiverCommuneName
        );
        item.receiverCommuneCode =
          this.receiverCommuneCode !== undefined
            ? this.receiverCommuneCode
            : null;
        item.length =
          item.length !== undefined
            ? this.convertPrice(StringUtils.replaceComma(item.length))
            : null;
        item.width =
          item.width !== undefined
            ? this.convertPrice(StringUtils.replaceComma(item.width))
            : null;
        item.height =
          item.height !== undefined
            ? this.convertPrice(StringUtils.replaceComma(item.height))
            : null;
        item.weight =
          item.weight !== undefined
            ? this.convertPrice(StringUtils.replaceComma(item.weight))
            : null;
        item.weightTranfer =
          item.weightTranfer !== undefined
            ? this.convertPrice(StringUtils.replaceComma(item.weightTranfer))
            : null;
        item.codValue =
          item.codValue !== undefined
            ? this.convertPrice(StringUtils.replaceComma(item.codValue))
            : null;
        item.orderValue =
          item.orderValue !== undefined
            ? this.convertPrice(StringUtils.replaceComma(item.orderValue))
            : null;
      });
      this.excelData = this.items;
      if (this.partnerSelect === constants.partnerGroup.ghtk) {
        this.arrayListFee.filter((fee) => fee.id !== 2);
      }
      this.validateTableData(this.excelData);
      const isValidatedAll = this.isDataValidatedAll(this.items);
      if (isValidatedAll) {
        this.calculateShipFeeExcel();
      }
      this.showLoading = false;
      this.loadingFile = false;
      this.checkInvalidOrder();
    },
    mappingArrayDataToObj(arrData) {
      const lenKeys = arrExcelKeys.length;
      if (arrData.filter((item) => item !== null && item !== "").length < 2) {
        return null;
      }
      const item = {};
      for (let index = 0; index < lenKeys; index++) {
        if (arrData[index] === undefined) {
          if (index < 11) {
            item[arrExcelKeys[index]] = "";
          }
        }
        if (index === 10) {
          item[arrExcelKeys[10]] = arrData[10];
          item[arrExcelKeys[11]] = "";
          item[arrExcelKeys[12]] = "";
          item[arrExcelKeys[13]] = "";
          item[arrExcelKeys[14]] = "-";
          if (arrData[10].length > 0) {
            this.arrayAdress = arrData[10].split(",");
            item[arrExcelKeys[10]] = arrData[10];
            this.arrayAdress = this.arrayAdress.reverse();
            item[arrExcelKeys[14]] = "";
            if (this.arrayAdress.length > 0) {
              item[arrExcelKeys[11]] = this.arrayAdress.shift();
              this.arrayAdress = this.arrayAdress.splice(0);
              item[arrExcelKeys[14]] =
                item[arrExcelKeys[11]] + "," + item[arrExcelKeys[14]];
            }
            if (this.arrayAdress.length > 0) {
              item[arrExcelKeys[12]] = this.arrayAdress.shift();
              this.arrayAdress = this.arrayAdress.splice(0);
              item[arrExcelKeys[14]] =
                item[arrExcelKeys[12]] + "," + item[arrExcelKeys[14]];
            }
            if (this.arrayAdress.length > 0) {
              item[arrExcelKeys[13]] = this.arrayAdress.shift();
              item[arrExcelKeys[14]] =
                item[arrExcelKeys[13]] + "," + item[arrExcelKeys[14]];
            }
            if (item[arrExcelKeys[14]].length > 0) {
              item[arrExcelKeys[14]] = item[arrExcelKeys[14]].substring(
                1,
                item[arrExcelKeys[14]].length - 1
              );
            }
          }
        } else {
          if (index < 10) {
            item[arrExcelKeys[index]] = arrData[index];
            item[arrExcelKeys[16]] = "-";
            if (arrData[1] !== "" && arrData[2] !== "" && arrData[3] !== "") {
              item[arrExcelKeys[16]] = "";
              item[arrExcelKeys[1]] = arrData[1];
              item[arrExcelKeys[2]] = arrData[2];
              item[arrExcelKeys[3]] = arrData[3];
              if (this.partnerSelect === 1) {
                item[arrExcelKeys[16]] =
                  (item[arrExcelKeys[1]] *
                    item[arrExcelKeys[2]] *
                    item[arrExcelKeys[3]]) /
                  6000;
                item[arrExcelKeys[16]] = StringUtils.formatDecimal(
                  item[arrExcelKeys[16]]
                );
              } else {
                if (this.serviceSelect === 4) {
                  item[arrExcelKeys[16]] =
                    (item[arrExcelKeys[1]] *
                      item[arrExcelKeys[2]] *
                      item[arrExcelKeys[3]]) /
                    4000;
                  item[arrExcelKeys[16]] = StringUtils.formatDecimal(
                    item[arrExcelKeys[16]]
                  );
                } else {
                  item[arrExcelKeys[16]] =
                    (item[arrExcelKeys[1]] *
                      item[arrExcelKeys[2]] *
                      item[arrExcelKeys[3]]) /
                    6000;
                  item[arrExcelKeys[16]] = StringUtils.formatDecimal(
                    item[arrExcelKeys[16]]
                  );
                }
              }
            }
          }
        }
      }
      return item;
    },
    calculateShipFee() {
      this.calculateShipFeeExcel();
    },
    checkValidShipFeeExcel() {
      // if (this.isShowPickUpDateShipping && !this.pickUpDate) return false;

      if (!this.itemAddressSelected) return false;

      if (!this.excelData || this.excelData.length === 0) return false;

      return true;
    },
    async calculateShipFeeExcel() {
      if (this.checkValidShipFeeExcel()) {
        this.showLoading = true;
        const pars = this.bindCalculateFeesRequestPars();
        const { status, data } = await OrderService.viewFromExcel(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok) {
          this.disableBtnCreate = false;
          this.excelData = data;
          this.items = data;
          this.items.forEach((it) => {
            this.estimateWeight = it.estimateWeight;
          });
          this.items.forEach((item, index) => {
            item.id = index;
            this.lstDeliveryNote.forEach((it) => {
              if (it.code === item.note) {
                item.note = it.name;
              }
            });
            this.listAllDistricts.forEach((it) => {
              if (it.fullName === item.receiverDistrictName) {
                item.receiverDistrictCode = it.code;
              }
            });
            this.listAllCommunes.forEach((it) => {
              if (it.fullName === item.receiverCommuneName) {
                item.receiverCommuneCode = it.code;
              }
            });
            if (item.paymentBy === constants.serviceFeePayers.sender) {
              item.paymentBy = "Người gửi";
            } else if (item.paymentBy === constants.serviceFeePayers.receiver) {
              item.paymentBy = "Người nhận";
            }
            this.beforeItems.forEach((it, idx) => {
              if (it.isInsurance === this.$t("yes")) {
                this.items[idx].isInsurance = "Y";
              }
              if (it.isInsurance === this.$t("no")) {
                this.items[idx].isInsurance = "N";
              }
              if (!it.isInsurance) {
                this.items[idx].isInsurance = "-";
              }
            });
            if (item.orderValue) {
              item.orderValue = this.convertPrice(item.orderValue);
            }
            if (item.codValue !== 0) {
              item.codValue = this.convertPrice(item.codValue);
            }
          });
          this.validateTableData(this.excelData);
        } else {
          let msgNoti = "";
          if (data.message === constants.errorValidation) {
            var jsonObj = data.details;
            var keys = Object.keys(jsonObj);
            for (var i = 0; i < keys.length; i++) {
              if (i < keys.length - 1) {
                msgNoti += jsonObj[keys[i]] + ",";
              } else {
                msgNoti += jsonObj[keys[i]];
              }
            }
          } else {
            msgNoti = data.message;
          }
          this.toggleDialogNoti({ state: true, msg: msgNoti });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, constants.timeOut);
        }
      }
    },
    bindCalculateFeesRequestPars() {
      const pars = {
        custId: this.userId,
        transportType: this.transportType,
        partnerId: this.partnerSelect,
        poId: StorageService.getPostOfficeId(),
        addressId: this.itemAddressSelected.id,
        tags: this.checkItemFee.toString(),
        ordersDetailViewRequests: this.bindOrdersDetailViewRequests(),
      };
      pars["serviceId"] = this.serviceSelect;
      if (this.transportType === constants.transportType.ship) {
        if (this.partnerSelect === constants.partnerGroup.ninjaVan) {
          pars["pickupTimeFrom"] = this.parsePickupTimeFrom();
          pars["pickupTimeTo"] = this.parsePickupTimeTo();
        } else {
          pars["pickDate"] = this.formatDateYmd(this.pickUpDate);
          pars["pickWorkShift"] = this.pickWorkShip;
        }
      }

      return pars;
    },
    bindOrdersDetailViewRequests() {
      const results = [];
      const arrData = this.items;
      this.beforeItems = this.items;
      arrData.forEach((item) => {
        const newItem = {
          receiverPhone: item.receiverPhone,
          receiverName: item.receiverName,
          receiverProvinceCode: this.validateAddressInfo(
            item.receiverProvinceName
          ),
          receiverProvinceName: this.receiverProvinceCode
            ? this.receiverProvinceName
            : item.receiverProvinceName,
          receiverDistrictName: item.receiverDistrictName,
          receiverCommuneName: item.receiverCommuneName,
          receiverAddress: item.receiverAddress,
          note: item.noteCode,
          description: item.description,
          length: StringUtils.numStrToInt(item.length),
          width: StringUtils.numStrToInt(item.width),
          height: StringUtils.numStrToInt(item.height),
          weight: StringUtils.numStrToFloat(item.weight),
          estimateWeight: this.calculateEstimateWeight(
            item.length,
            item.width,
            item.height
          ),
          isInsurance: item.isInsurance,
          paymentBy: item.paymentByValue,
          orderValue: StringUtils.numStrToFloat(item.orderValue),
          codValue: StringUtils.numStrToFloat(item.codValue),
        };
        if (item.status === constants.statusCreateOrder.edited) {
          newItem["status"] = constants.statusCreateOrder.edited;
        } else if (item.status === constants.statusCreateOrder.success) {
          newItem["status"] = constants.statusCreateOrder.success;
        }
        if (item.isInsurance === this.$t("yes")) {
          const fee =
            parseFloat(StringUtils.numStrToFloat(item.orderValue)) * 0.01;
          newItem["insuranceValue"] = fee;
        }

        results.push(newItem);
      });

      return results;
    },
    convertPrice(val) {
      if (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatDateYmd(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDateDmy);
    },

    parsePickupTimeFrom() {
      const timeFrom = this.timePicker?.from;
      return `${this.pickUpDate}T${timeFrom}`;
    },
    parsePickupTimeTo() {
      const timeTo = this.timePicker?.to;
      return `${this.pickUpDate}T${timeTo}`;
    },
    // getTextAddressReceiver(address, commune, district, province) {
    //   return `${address.trim()}, ${commune}, ${district}, ${province}`;
    // },
    calculateEstimateWeight(length, width, height) {
      if (length && width && height) {
        if (this.partnerSelect === 1) {
          const weightTranfer =
            (StringUtils.numStrToInt(length) *
              StringUtils.numStrToInt(width) *
              StringUtils.numStrToInt(height)) /
            6000;
          if (weightTranfer >= 1000) {
            return StringUtils.numStrToInt(weightTranfer);
          } else {
            return StringUtils.formatDecimal(weightTranfer);
          }
        } else {
          if (this.serviceSelect === constants.servicesShipping.over) {
            const weightTranfer =
              (StringUtils.numStrToInt(length) *
                StringUtils.numStrToInt(width) *
                StringUtils.numStrToInt(height)) /
              4000;
            if (weightTranfer >= 1000) {
              return StringUtils.numStrToInt(weightTranfer);
            } else {
              return StringUtils.formatDecimal(weightTranfer);
            }
          } else {
            const weightTranfer =
              (StringUtils.numStrToInt(length) *
                StringUtils.numStrToInt(width) *
                StringUtils.numStrToInt(height)) /
              6000;
            if (weightTranfer >= 1000) {
              return StringUtils.numStrToInt(weightTranfer);
            } else {
              return StringUtils.formatDecimal(weightTranfer);
            }
          }
        }
      }
      return 0;
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    formatDate(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate);
    },
    parseDate(date) {
      return DateTimeUtils.parseDate(date);
    },
    isNumber(event) {
      return FormUtils.isNumber(event);
    },
    isDecimal(evt) {
      return FormUtils.isDecimal(evt);
    },
    formatDecimalView(val) {
      if (typeof val === "number") {
        return StringUtils.formatDecimal(val);
      } else {
        return val;
      }
    },
    async downloadFile(url) {
      const { status, data } = await OrderService.downloadTemplate(url);
      if (status === constants.statusCode.ok) {
        var fileUrl = URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "order_template.xlsx");
        document.body.appendChild(link);
        link.click();
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    async onOrderCreateMultiple() {
      var valid = await this.$refs.observer.validate();
      if (valid && this.items.length > 0) {
        this.showLoading = true;
        this.disableBtnCreate = true;
        const pars = {
          ordersAddRequests: this.bindRequestPars(),
        };
        const { status, data } = await OrderService.createMultiple(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok && data.status === 1) {
          this.toggleDialogNoti({
            state: true,
            type: constants.typeAlert.success,
            msg: this.$t("success_order_create"),
          });
          setTimeout(() => {
            this.toggleDialogNoti();
            this.$router.push(routePaths.ORDER_LIST);
          }, 2500);
        } else if (status === constants.statusCode.ok && data.status === -1) {
          this.lstItemCreate = data.ordersAddRequests;
          this.lstItemCreate.forEach((item) => {
            if (item.paymentBy === constants.serviceFeePayers.sender) {
              item.paymentBy = "Người gửi";
              item.paymentByValue = constants.serviceFeePayers.sender;
            } else if (item.paymentBy === constants.serviceFeePayers.receiver) {
              item.paymentBy = "Người nhận";
              item.paymentByValue = constants.serviceFeePayers.receiver;
            }
          });
          this.items = this.lstItemCreate;
          this.items.forEach((item, index) => {
            item.id = index + 1;
          });
          this.excelData = this.items;
          this.disableAction = false;
          let msgNoti = "";
          msgNoti = data.message;
          this.toggleDialogNoti({ state: true, msg: msgNoti });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, 2500);
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true;
        } else {
          let msgNoti = "";
          if (data.message === constants.errorValidation) {
            var jsonObj = data.details;
            var keys = Object.keys(jsonObj);
            for (var i = 0; i < keys.length; i++) {
              if (i < keys.length - 1) {
                msgNoti += jsonObj[keys[i]] + ",";
              } else {
                msgNoti += jsonObj[keys[i]];
              }
            }
          } else {
            msgNoti = data.message;
          }

          this.toggleDialogNoti({ state: true, msg: msgNoti });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, 2500);
          this.disableBtnCreate = false;
        }
      }
    },
    bindRequestPars() {
      const results = [];
      const data = this.items;
      data.forEach((item) => {
        const newItem = {
          custId: this.userId,
          partnerId: this.partnerSelect,
          addressId: this.itemAddressSelected.id,
          transportType: this.transportType,
          receiverPhone: item.receiverPhone,
          receiverName: item.receiverName,
          receiverProvinceCode: item.receiverProvinceCode,
          receiverProvinceName: item.receiverProvinceName,
          receiverDistrictCode: item.receiverDistrictCode,
          receiverDistrictName: item.receiverDistrictName,
          receiverCommuneName: item.receiverCommuneName,
          receiverCommuneCode: item.receiverCommuneCode,
          receiverAddress: item.receiverAddress,
          note: this.deliveryNoteSelected,
          description: item.description,
          length: item.length,
          width: item.width,
          height: item.height,
          weight: item.weight,
          estimateWeight: item.estimateWeight,
          paymentBy: this.getPaymentByText(item.paymentBy),
          shipValue: item.shipValue,
          totalValue: item.totalValue,
          tags: this.checkItemFee.toString(),
          serviceFeesValue: item.serviceFeesValue,
        };

        if (item.status === null) {
          newItem["status"] = 0;
        } else if (item.status === constants.statusCreateOrder.edited) {
          newItem["status"] = constants.statusCreateOrder.fail;
        } else {
          newItem["status"] = item.status;
        }
        newItem["serviceId"] = this.serviceSelect;
        if (this.transportType === constants.transportType.ship) {
          if (this.partnerSelect === constants.partnerGroup.ninjaVan) {
            newItem["pickupTimeFrom"] = this.parsePickupTimeFrom();
            newItem["pickupTimeTo"] = this.parsePickupTimeTo();
          } else {
            newItem["pickDate"] = this.formatDateYmd(this.pickUpDate);
            newItem["pickWorkShift"] = this.pickWorkShip;
          }
        }

        if (item.codValue === 0) {
          newItem["codValue"] = 0;
        } else {
          newItem["codValue"] = StringUtils.numStrToFloat(item.codValue);
        }
        if (item.orderValue === "-") {
          newItem["orderValue"] = 0;
        } else {
          newItem["orderValue"] = StringUtils.numStrToFloat(item.orderValue);
        }
        if (item.insuranceValue) {
          newItem["orderValue"] = StringUtils.numStrToFloat(item.orderValue);
          newItem["insuranceValue"] = item.insuranceValue;
        }

        results.push(newItem);
      });
      return results;
    },
    getTextStatus(item) {
      if (item.status === constants.statusCreateOrder.success) {
        return this.$t("create_order_success");
      } else if (item.status === constants.statusCreateOrder.fail) {
        return this.$t("create_order_fail");
      } else if (item.status === constants.statusCreateOrder.edited) {
        return this.$t("order_edited");
      }
    },
    displaySize(length, width, height) {
      if (height && width && length) {
        return `${length} * ${width} * ${height}`;
      }
      if (!height && width && length) {
        return `${length} * ${width} * -`;
      }
      if (!width && length && height) {
        return `${length} * - * ${height}`;
      }
      if (!length && width && height) {
        return `- * ${width} * ${height}`;
      }
      if (!height && !width && length) {
        return `${length} * - * -`;
      }
      if (!width && !length && height) {
        return `- * - * ${height}`;
      }
      if (!height && !length && width) {
        return `- * ${width} * -`;
      }
      if (!length && !width && !height) {
        return `- * - * -`;
      }
    },
    getPaymentByText(val) {
      return val === this.$t("sender")
        ? this.serviceFeePayersConst.sender
        : this.serviceFeePayersConst.receiver;
    },
    getNoteByName(val) {
      const item = this.lstDeliveryNote.find((x) => x.name === val);
      if (item) {
        return item.code;
      }
      return "";
    },
    getTextTruncate(val, size) {
      if (val.length > size) {
        return val.substring(0, size) + "...";
      } else {
        return val;
      }
    },
    replaceProvinceName(val) {
      val = this.removeVietnameseTones(val);
      val = val.replace(/ + /g, " ");
      val = val.toLowerCase().replace("thanh pho ", "");
      val = val.toLowerCase().replace("tinh ", "");
      val = val.toLowerCase().replace("tp ", "");
      val = val.trim().replaceAll(" ", "_");
      return val;
    },
    validateAddressInfo(val) {
      this.receiverProvinceCode = null;
      this.lstProvince.forEach((item) => {
        if (
          this.replaceProvinceName(item.unsignedName) ===
          this.replaceProvinceName(val)
        ) {
          this.receiverProvinceCode = item.code;
          this.receiverProvinceName = item.fullName;
        }
      });
      return this.receiverProvinceCode;
    },
    replaceDistrictName(val) {
      val = this.removeVietnameseTones(val);
      val = val.toLowerCase().replace("quan ", "");
      val = val.toLowerCase().replace("huyen ", "");
      val = val.toLowerCase().replace("thanh pho ", "");
      val = val.toLowerCase().replace("thi xa ", "");
      val = val.trim().replaceAll(" ", "_");
      return val;
    },
    validateDisTrictInfo(val) {
      this.receiverDistrictCode = null;
      this.receiverDistrictName = val;
      this.listAllDistricts.forEach((item) => {
        if (
          this.replaceDistrictName(item.unsignedName) ===
            this.replaceDistrictName(val) &&
          this.receiverProvinceCode === item.provinceCode
        ) {
          this.receiverDistrictCode = item.code;
          this.receiverDistrictName = item.fullName;
        }
      });
      return this.receiverDistrictName;
    },
    replaceCommuneName(val) {
      val = this.removeVietnameseTones(val);
      val = val.toLowerCase().replace("phuong ", "");
      val = val.toLowerCase().replace("xa ", "");
      val = val.toLowerCase().replace("thi tran ", "");
      val = val.trim().replaceAll(" ", "_");
      return val;
    },
    validateCommuneInfo(val) {
      this.receiverCommuneCode = null;
      this.receiverCommuneName = val;
      this.listAllCommunes.forEach((item) => {
        if (
          this.replaceCommuneName(item.unsignedName) ===
            this.replaceCommuneName(val) &&
          this.receiverDistrictCode === item.districtCode
        ) {
          this.receiverCommuneCode = item.code;
          this.receiverCommuneName = item.fullName;
        }
      });
      return this.receiverCommuneName;
    },
    onBack() {
      this.$router.push(routePaths.ORDERS);
    },
  },
};
</script>

<style src="@/styles/createMultiOrder.scss" lang="scss" />
