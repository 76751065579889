import { BaseService } from './baseService'
import apis from './apis'

export class UserService extends BaseService {
  static async register(pars) {
    try {
      const response = await this.request().post(apis.register, pars)
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async otpVerify(pars) {
    try {
      const response = await this.request().post(apis.otp_verify, pars)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async otpResend(pars) {
    try {
      const response = await this.request().post(apis.otp_resend, pars)
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async addressGetAll(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.address_get_all,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async addressGetDefault(pars) {
    try {
      const response = await this.request({ auth: true }).get(
        apis.address_get_default
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async addressCreate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.address_create,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async addressUpdate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.address_update,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async addressDelete(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.address_delete,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getInfo() {
    try {
      const response = await this.request({ auth: true }).get(apis.user_info)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async forgotPassword(pars) {
    try {
      const response = await this.request().post(apis.forgot_password, pars)
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async checkReferCode(pars) {
    try {
      const response = await this.request({ auth: true }).get(
        `${apis.check_refer_code}/${pars}`
      );
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async forgotPasswordVerify(pars) {
    try {
      const response = await this.request().post(
        apis.forgot_password_verify_otp,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async forgotPasswordUpdate(pars) {
    try {
      const response = await this.request().post(
        apis.forgot_password_update,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async changePasswordCheck(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.change_password_check,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async changePasswordUpdate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.change_password_update,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async updateShop(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.update_shop,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
}
