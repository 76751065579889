<template>
  <v-layout justify-end pl-lg-16 pt-8 d-none d-md-flex>
    <v-layout column class="col-xl-10 col-12">
      <h2 class="logo mb-8" />
      <v-layout column class="mt-4 mb-16">
        <h2 class="text-uppercase font-weight-large text-h3-1">
          {{ $t('brand_network') }}
        </h2>
        <label class="primary--text text-body-2">
          {{ $t('nation_network') }}
        </label>
      </v-layout>
      <div class="pl-10 pl-sm-0">
        <div class="bg-post-office" />
      </div>
    </v-layout>
  </v-layout>
</template>
<script>
export default {
  name: 'PostOffice'
}
</script>
