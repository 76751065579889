import { BaseService } from './baseService'
import { StorageService } from './storageService'
import apis from './apis'
import constants from '@/constants'

export class AuthService extends BaseService {
  /**
   * Handle request login
   */
  static async login(pars) {
    try {
      // var data = new FormData()
      // data.append('username', pars.username)
      // data.append('password', pars.password)
      // data.append('grant_type', pars.grant_type)
      const response = await this.request().post(apis.login, pars)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async revoke() {
    try {
      const response = await this.request({ auth: true }).get(apis.revoke)
      if (
        response.data.status === constants.statusCode.ok
      ) {
        this.logout()
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  static logout() {
    StorageService.clearLocalStorage()
  }
}
