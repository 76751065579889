<template>
  <div class="pos-rel" style="float: left">
    <apexchart
      type="donut"
      :options="chartOptions"
      :series="series"
      :width="width"
    />
    <v-avatar
      color="#FFFFFF"
      :size="sizeAvatar"
      class="donut-total-data donut-total-dataa"
      @click="goOrderList"
    >
      <v-layout column>
        <span class="text--body-6-14 color-gray">{{ $t("total") }}</span>
        <span class="text--body-5-20">{{ formatDecimal(total) }}</span>
      </v-layout>
    </v-avatar>
  </div>
</template>

<script>
import { StringUtils } from "@/helpers/stringUtils";

export default {
  components: {},
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      width: 250,
      sizeAvatar: 100
    };
  },
  created() {
    if (screen.width <= 1280) {
      this.sizeAvatar = 200
    }
    if (screen.width <= 400) {
      this.sizeAvatar = 200
    }
    if (screen.width <= 820 && screen.width > 500) {
      this.sizeAvatar = 90
    }
  },
  methods: {
    goOrderList() {
      this.$emit("go-order-list");
    },
    formatDecimal(val) {
      return val !== undefined ? StringUtils.formatDecimal(val) : ''
    },
  },
};
</script>

<style></style>
