<template>
  <v-layout justify-start class="border rounded pl-2 pr-3 my-2 pt-2">
    <v-checkbox
      v-model="item.checked"
      color="primary"
      class="d-flex align-start"
      @change="onChecked(item)"
    />
    <v-layout column class="mr-4">
      <label class="text-subtitle-2">
        {{ item.name }}
        <span
          v-if="item.defaultAddress"
          class="error--text text-min"
        >[{{ $t('default') }}]</span>
      </label>
      <label class="text-caption blur--text line-height-small">
        {{ item.phone }}
      </label>
      <label class="text-caption blur--text line-height-small">
        {{
          `${item.addressDetail}, ${item.communeName}, ${item.districtName}, ${item.provinceName}`
        }}
      </label>
    </v-layout>
    <v-layout justify-end>
      <v-icon class="mr-1 custom-bg-icon" @click="onDetail(item)"> mdi-pencil-outline </v-icon>
      <v-icon v-if="!item.defaultAddress" class="custom-bg-icon" @click="onDelete(item)">
        mdi-trash-can-outline
      </v-icon>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  name: 'ItemAddressView',
  props: {
    item: {
      type: Object,
      default: null
    },
    onDetail: {
      type: Function,
      default: null
    },
    onDelete: {
      type: Function,
      default: null
    },
    onChecked: {
      type: Function,
      default: null
    }
  }
}
</script>
