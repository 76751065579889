<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="sm-12" class="col-12">
          <v-col class="border-primary rounded-lg px-3 px-sm-6 py-6">
            <validation-observer ref="observer_bank" v-slot="{ invalid }">
              <v-form @submit.prevent="submitBank">
                <v-row>
                  <!-- Ten ngan hang -->
                  <v-col cols="sm-6" class="col-12">
                    <v-layout column>
                      <label class="text-body-2">
                        {{ $t("bank_name") }}
                        <span class="red--text">{{ $t("required") }}</span>
                      </label>
                      <validation-provider
                        v-slot="{ errors }"
                        vid="bankName"
                        name="selectRequire"
                        :rules="requireRules"
                      >
                        <v-autocomplete
                          v-model="bankSelected"
                          clearable
                          outlined
                          dense
                          class="rounded-lg"
                          hide-no-data
                          item-value="id"
                          :placeholder="$t('place_holders.bank_name')"
                          :item-text="getItemBankText"
                          :items="lstBank"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-layout>
                  </v-col>
                  <!-- Ten chu tai khoan -->
                  <v-col cols="sm-6" class="col-12">
                    <v-layout column>
                      <label class="text-body-2">
                        {{ $t("account_name") }}
                        <span class="red--text">{{ $t("required") }}</span>
                      </label>
                      <validation-provider
                        v-slot="{ errors }"
                        vid="accountName"
                        name="require"
                        :rules="requireRules"
                      >
                        <v-text-field
                          v-model="accountName"
                          outlined
                          dense
                          class="rounded-lg"
                          :placeholder="$t('place_holders.account_name')"
                          :maxlength="maximumSmallText"
                          :error-messages="errors"
                          @keypress="isLetter($event)"
                          @change="accountName = trimSpace(accountName)"
                        />
                      </validation-provider>
                    </v-layout>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Ten chi nhanh -->
                  <v-col cols="sm-6" class="col-12">
                    <v-layout column>
                      <label class="text-body-2">
                        {{ $t("branch_name") }}
                      </label>
                      <v-text-field
                        v-model="branchName"
                        outlined
                        dense
                        class="rounded-lg"
                        :placeholder="$t('place_holders.branch_name')"
                        :maxlength="maximumSmallText"
                        @change="branchName = trimSpace(branchName)"
                      />
                    </v-layout>
                  </v-col>
                  <!-- So tai khoan -->
                  <v-col cols="sm-6" class="col-12">
                    <v-layout column>
                      <label class="text-body-2">
                        {{ $t("account_number") }}
                        <span class="red--text">{{ $t("required") }}</span>
                      </label>
                      <validation-provider
                        v-slot="{ errors }"
                        vid="accountNumber"
                        name="require"
                        :rules="requireRules"
                      >
                        <v-text-field
                          v-model="accountNumber"
                          type="text"
                          pattern="\d*"
                          outlined
                          dense
                          class="rounded-lg"
                          :placeholder="$t('place_holders.account_number')"
                          :maxlength="maximumSmallText"
                          :error-messages="errors"
                          @keypress="isNumber($event)"
                          @paste.prevent="onPastePhone($event)"
                        />
                      </validation-provider>
                    </v-layout>
                  </v-col>
                </v-row>
                <v-layout justify-end>
                  <v-btn color="primary" type="submit" :disabled="invalid">
                    {{ $t("save") }}
                  </v-btn>
                </v-layout>
              </v-form>
            </validation-observer>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <!-- dialog confirm password -->
    <dialog-confirm-password
      v-model="showConfirmPassword"
      :width-dialog="600"
      @on-submit="onSubmitConfirmPassword"
    />

    <DialogConfirmTokenExpried v-model="showConfirmTokenExpried" />
  </div>
</template>

<script>
import constants from "@/constants";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirmPassword from "@/components/dialog/DialogConfirmPassword.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import { BankService } from "@/services/bankService";
import { StorageService } from "@/services/storageService";
import { FormUtils } from "@/helpers/formUtils";
import { StringUtils } from "@/helpers/stringUtils";

export default {
  components: {
    DialogNotification,
    DialogConfirmTokenExpried,
    DialogConfirmPassword,
  },
  data() {
    return {
      requireRules: {
        required: true,
      },
      maximumSmallText: constants.maximumSmallText,
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
      textCancel: this.$t("cancel"),
      textAccept: this.$t("save"),
      bankAccount: null,
      bankSelected: null,
      lstBank: [],
      branchName: "",
      accountName: "",
      accountNumber: "",
      showConfirmTokenExpried: false,
      showLoading: false,
      showConfirmPassword: false,
      custId: JSON.parse(localStorage.getItem("CURRENT_USER")).id
    };
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getBanks();
      await this.getBankAccount();
    },
    submitBank() {
      const valid = this.$refs.observer_bank.validate();
      if (valid) {
        this.message = this.$t("are_you_save_changes");
        this.showConfirmPassword = true;
      }
    },
    async getBanks() {
      this.showLoading = true;
      const { status, data } = await BankService.getAll();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data.length > 0) {
        this.lstBank = data;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    async getBankAccount() {
      this.showLoading = true;
      const { status, data } = await BankService.getAccount();
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        this.bankAccount = data;
        this.bankSelected = data.bankId;
        this.branchName = data.branch;
        this.accountName = data.name;
        this.accountNumber = data.accountNumber;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    bindRequestBankPars() {
      const pars = {
        bankId: this.bankSelected,
        name: this.accountName.trim(),
        branch: this.branchName.trim(),
        accountNumber: this.accountNumber.trim(),
        custId: this.custId
      };
      return pars;
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    async onSubmitConfirmPassword(password) {
      this.showConfirmPassword = false;
      const pars = this.bindRequestBankPars();
      if (password) {
        pars["password"] = password;
      }
      const { status, data } = await BankService.createOrUpdate(pars);
      if (status === constants.statusCode.ok) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t("saved_success"),
        });
        setTimeout(() => {
          this.toggleDialogNoti();
          this.getBankAccount();
        }, constants.timeOut);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({
          state: true,
          msg: data.message,
        });
        setTimeout(() => {
          this.toggleDialogNoti();
        }, constants.timeOut);
      }
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt);
    },
    onPastePhone(evt) {
      const val = FormUtils.isOnPasteOnlyNumber(evt);
      if (val !== null) {
        this.accountNumber = val;
      }
    },
    trimSpace(val) {
      return val ? val.trim() : "";
    },
    isLetter(evt) {
      return FormUtils.isLetter(evt);
    },
    getItemBankText(item) {
      return `${item.code} - ${item.name}`;
    },
  },
};
</script>

<style lang="scss">
.minh-content {
  background: white !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
</style>
