<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="sm-12" class="col-12">
          <v-col class="border-primary rounded-lg px-3 px-sm-6 py-6">
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-form @submit.prevent="submit">
                <v-row>
                  <v-col cols="sm-6" class="col-12">
                    <v-layout column>
                      <label class="text-body-2">
                        {{ `${$t("name")} ${$t("shop")}` }}
                        <span class="red--text">{{ $t("required") }}</span>
                      </label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="require"
                        vid="fullName"
                        :rules="requireRules"
                      >
                        <v-text-field
                          v-model="name"
                          outlined
                          dense
                          :error-messages="errors"
                          class="rounded-lg"
                          :maxlength="maximumSmallText"
                          :placeholder="$t('place_holders.shop_name')"
                          @change="name = trimSpace(name)"
                        />
                      </validation-provider>
                    </v-layout>
                  </v-col>
                  <v-col cols="sm-6" class="col-12">
                    <!-- email -->
                    <v-layout column>
                      <label class="text-body-2">
                        {{ $t("email") }}
                        <span class="red--text">{{ $t("required") }}</span>
                      </label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="email"
                        :rules="emailRules"
                      >
                        <v-text-field
                          v-model="email"
                          outlined
                          dense
                          class="rounded-lg"
                          :error-messages="errors"
                          :maxlength="maximumEmail"
                          :placeholder="$t('place_holders.email')"
                          @change="email = trimSpace(email)"
                        />
                      </validation-provider>
                    </v-layout>
                  </v-col>
                </v-row>
                <!-- phone -->
                <v-row>
                  <v-col cols="sm-6" class="col-12">
                    <v-layout column>
                      <label class="text-body-2">
                        {{ $t("phone") }}
                        <span class="red--text">{{ $t("required") }}</span>
                      </label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="phone"
                        :rules="phoneRules"
                      >
                        <v-text-field
                          v-model="phone"
                          type="text"
                          pattern="\d*"
                          outlined
                          dense
                          :error-messages="errors"
                          class="rounded-lg"
                          :maxlength="maximumPhone"
                          :placeholder="$t('place_holders.phone')"
                          @keypress="isNumber($event)"
                          @paste.prevent="onPastePhone($event)"
                        />
                      </validation-provider>
                    </v-layout>
                  </v-col>
                  <v-col cols="sm-6" class="col-12">
                    <!-- address -->
                    <v-layout column>
                      <label class="text-body-2">
                        {{ $t("address") }}
                        <span class="red--text">{{ $t("required") }}</span>
                      </label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="selectRequire"
                        :rules="requireRules"
                      >
                        <v-autocomplete
                          v-model="provinceSelected"
                          outlined
                          dense
                          class="rounded-lg"
                          item-text="name"
                          item-value="code"
                          hide-no-data
                          :error-messages="errors"
                          :placeholder="$t('place_holders.address')"
                          :items="lstProvince"
                        />
                      </validation-provider>
                    </v-layout>
                  </v-col>
                </v-row>
                <v-layout justify-space-between>
                  <v-btn color="primary" outlined @click="onGotoChangePassword">
                    {{ $t("change_password") }}
                  </v-btn>
                  <v-btn color="primary" type="submit" :disabled="invalid">
                    {{ $t("save") }}
                  </v-btn>
                </v-layout>
              </v-form>
            </validation-observer>
          </v-col>
        </v-col>
      </v-row>
    </v-container>

    <!-- <v-layout justify-space-around>
      <v-layout class="col-6 mr-2" />
      <v-layout column class="border-primary rounded-lg px-6 mt-6 ml-2 col-6">
        <h6 class="mb-4 text-h6 text-uppercase">
          {{ $t('checking_calendar') }}
        </h6>
        <validation-observer ref="observer_comparison" v-slot="{}">
          <v-form @submit.prevent="submitComparison">
            <v-layout column>
              <label class="text-body-2">
                {{ $t('checking_calendar') }}
                <span class="red--text">{{ $t('required') }}</span>
              </label>
              <validation-provider
                v-slot="{ errors }"
                vid="checkingCalendar"
                name="selectRequire"
                :rules="requireRules"
              >
                <v-autocomplete
                  v-model="comparisonSelected"
                  outlined
                  dense
                  readonly
                  class="rounded-lg"
                  item-text="name"
                  item-value="id"
                  hide-no-data
                  :placeholder="$t('place_holders.checking_calendar')"
                  :items="lstComparison"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-layout>
            <v-layout justify-end>
              <v-btn color="primary" type="submit" :disabled="invalid">
                {{ $t('save') }}
              </v-btn>
            </v-layout>
          </v-form>
        </validation-observer>
      </v-layout>
    </v-layout> -->

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <!-- dialog confirm password -->
    <dialog-confirm-password
      ref="DialogConfirmPassword"
      v-model="showConfirmPassword"
      :width-dialog="600"
      @on-submit="onSubmitConfirmPassword"
    />

    <DialogConfirmTokenExpried v-model="showConfirmTokenExpried" />
  </div>
</template>

<script>
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirmPassword from "@/components/dialog/DialogConfirmPassword.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import constants from "@/constants";
import { ComparisonService } from "@/services/comparisonService";
import { StringUtils } from "@/helpers/stringUtils";
import { FormUtils } from "@/helpers/formUtils";
import { StorageService } from "@/services/storageService";
import { UserService } from "@/services/userService";
import routePaths from "@/router/routePaths";

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried,
    DialogConfirmPassword,
  },
  data() {
    return {
      requireRules: {
        required: true,
      },
      name: "",
      maximumSmallText: constants.maximumSmallText,
      phone: "",
      maximumPhone: constants.maximumPhone,
      phoneRules: {
        required: true,
        min: constants.minimumPhone,
        max: constants.maximumPhone,
        regex: constants.regExp.phone,
      },
      email: "",
      maximumEmail: constants.maximumEmail,
      emailRules: {
        required: true,
        email: true,
        min: constants.minimumEmail,
        max: constants.maximumEmail,
        regex: constants.regExp.email,
      },
      provinceSelected: null,
      lstProvince: [],

      comparisonSelected: null,
      lstComparison: [],

      showLoading: false,

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",

      textCancel: this.$t("cancel"),
      textAccept: this.$t("save"),
      showConfirmTokenExpried: false,
      showConfirmPassword: false,
      userId: JSON.parse(localStorage.getItem("CURRENT_USER")).id
    };
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getProvinces();
      // await this.getComparisons()
      await this.getUserInfo();
    },
    async getProvinces() {
      this.lstProvince = await StorageService.getProvinces();
    },
    async getComparisons() {
      this.showLoading = true;
      const { status, data } = await ComparisonService.getAll();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data.length > 0) {
        this.lstComparison = data;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    async getUserInfo() {
      this.showLoading = true;
      const { status, data } = await UserService.getInfo();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.name = data.name;
        this.phone = data.phone;
        this.email = data.email;
        this.provinceSelected = data.provinceCode;
        // if (this.lstComparison && this.lstComparison.length > 0) {
        //   if (data.comparisonScheduleId) {
        //     this.comparisonSelected = data.comparisonScheduleId
        //   } else {
        //     this.comparisonSelected = this.getDefaultScheduleIdForGuest()
        //   }
        // }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    getDefaultScheduleIdForGuest() {
      if (this.lstComparison && this.lstComparison.length === 0) return 0;

      const item = this.lstComparison.find(
        (x) =>
          x.type === constants.valueFilterForGuest.type &&
          x.runDays === constants.valueFilterForGuest.runDays
      );
      if (typeof item !== constants.undefined && item) {
        return item.id;
      } else {
        return 0;
      }
    },
    submit() {
      const valid = this.$refs.observer.validate();
      if (valid) {
        this.message = this.$t("are_you_save_changes");
        this.showConfirmPassword = true;
        this.$refs.DialogConfirmPassword.resetForm();
      }
    },
    async onSubmitConfirmPassword(password) {
      const pars = this.bindRequestInfoPars();
      if (password) {
        pars["password"] = password;
      }
      const { status, data } = await UserService.updateShop(pars);
      if (status === constants.statusCode.ok) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t("saved_success"),
        });
        setTimeout(() => {
          this.toggleDialogNoti();
        }, constants.timeOut);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({
          state: true,
          msg: data.message,
        });
        setTimeout(() => {
          this.toggleDialogNoti();
          window.location.reload()
        }, 2000);
      }
      this.showConfirmPassword = false;
    },
    bindRequestInfoPars() {
      const pars = {
        name: this.name.trim(),
        phone: this.phone.trim(),
        email: this.email.trim(),
        provinceCode: this.provinceSelected.trim(),
        custId: this.userId
      };
      return pars;
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    onPastePhone(evt) {
      const val = FormUtils.isOnPasteOnlyNumber(evt);
      if (val !== null) {
        this.phone = val;
      }
    },
    trimSpace(val) {
      return val ? val.trim() : "";
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt);
    },
    isLetter(evt) {
      return FormUtils.isLetter(evt);
    },
    getItemBankText(item) {
      return `${item.code} - ${item.name}`;
    },
    onGotoChangePassword() {
      this.$router.push(routePaths.CHANGE_PASSWORD);
    },
  },
};
</script>
<style lang="scss">
.minh-content {
  background: white !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
</style>
