import { format } from 'date-fns' // parse
import moment from 'moment'
import constants from '@/constants'

export class DateTimeUtils {
  static formatDateTime(val, formatStr) {
    if (!val) return ''

    // const dt = new Date(val)
    // return format(dt, formatStr)
    return moment(val).format(formatStr)
  }

  static getDateNow() {
    return new Date(Date.now()).toISOString().substr(0, 10)
  }

  static getTomorrow() {
    return moment().add(1, 'days').format(constants.formatDateYmd)
  }

  static getNextDateByAddDay(date, addDay) {
    return moment(date).add(addDay, 'days').format(constants.formatDateYmd)
  }

  static parseDate(date) {
    if (!date) return null

    const [day, month, year] = date.split('/')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }

  static parseDateToInt(date) {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return parseInt(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`)
  }

  static formatPickUpDateTime(from, to) {
    if (!from) return ''

    const fromDt = new Date(from)
    const toDt = new Date(to)
    return `${format(fromDt, constants.formatDateTimeHm)} - ${format(
      toDt,
      constants.formatTimeHm
    )}`
  }

  static startOfDay(date) {
    return moment(date).format(constants.formatDateTimeUtc)
  }

  static endOfDay(date) {
    return moment(date).add(1, 'days').add(-1, 'milliseconds').format(constants.formatDateTimeUtc)
  }
}
