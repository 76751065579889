<template>
  <v-app>
    <v-layout white>
      <v-layout box-noauth bg-main />
      <v-layout column justify-center>
        <v-container zindex-1>
          <v-layout column>
            <v-layout col d-none d-md-flex>
              <h2 class="logo pos-logo-full" />
            </v-layout>
            <v-layout justify-center>
              <v-col cols="12" class="col-sm-8 col-md-7 col-lg-5 pa-0 pa-sm-3">
                <v-layout
                  column
                  align-center
                  white
                  rounded-xl
                  box-shadow
                  px-6
                  py-16
                  pa-sm-16
                >
                  <label
                    class="text-uppercase font-weight-medium text-size-title mt-8"
                  >
                    {{ $t('enter_code_otp') }}
                  </label>
                  <label class="mb-10 text-body-2">
                    {{ `${$t('enter_code_sent_to_phone')} ${phoneShow}` }}
                  </label>
                  <label
                    :class="
                      countDown === 0
                        ? 'error--text text-body-2'
                        : 'text-body-2'
                    "
                  >
                    {{ messageCountDown }}
                    <span v-if="countDown > 0" class="primary--text">
                      {{ countDown }}s
                    </span>
                  </label>
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form @submit.prevent="submit">
                      <v-layout column>
                        <v-row class="justify-center">
                          <v-col cols="12" class="col-sm-10">
                            <validation-provider
                              v-slot="{ errors }"
                              name="otp"
                              :rules="otpRules"
                            >
                              <v-otp-input
                                v-model="otp"
                                length="6"
                                type="password"
                                pattern="\d*"
                                dense
                                :error-messages="errors"
                                :disabled="isStateSuppend"
                                @keypress="isNumber($event)"
                                @input="onInputOtp"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-layout>
                      <div>
                        <v-btn
                          block
                          x-large
                          color="primary"
                          type="submit"
                          class="rounded-lg mt-12 mb-2"
                          :disabled="invalid"
                        >
                          <span class="font-weight-medium white--text">
                            {{ $t('continue') }}
                          </span>
                        </v-btn>
                      </div>
                    </v-form>
                  </validation-observer>

                  <span
                    v-if="countDown === 0"
                    class="text-body-2 cursor-pointer"
                    @click="onResendOtp"
                  >
                    {{ $t('resend_otp') }}?
                  </span>
                </v-layout>
              </v-col>
            </v-layout>
          </v-layout>
        </v-container>
      </v-layout>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <dialog-success
      v-model="showSuccess"
      :title="titleSuccess"
      :content="contentSuccess"
      :img-src="classImgSrcSuccess"
    />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />
  </v-app>
</template>

<script>
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogSuccess from '@/components/dialog/DialogSuccess.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import constants from '@/constants'
import routePaths from '@/router/routePaths'
import { FormUtils } from '@/helpers/formUtils'
import { UserService } from '@/services/userService'
import { StringUtils } from '@/helpers/stringUtils'
import moment from 'moment'
import { DateTimeUtils } from '@/helpers/dateTimeUtils'
import { StorageService } from '@/services/storageService'

export default {
  components: { DialogLoading, DialogSuccess, DialogNotification },
  data: () => ({
    typeVerify: constants.typeOtp.register,
    storeData: null,
    otp: '',
    otpRules: {
      required: true,
      min: constants.maximumOtp,
      max: constants.maximumOtp
    },
    messageCountDown: '',
    countDown: 0,
    isStateSuppend: false,

    // loading
    showLoading: false,

    showSuccess: false,
    titleSuccess: '',
    contentSuccess: '',
    classImgSrcSuccess: 'bg-register-suscess',

    // notifications
    showNoti: false,
    typeNoti: constants.typeAlert.warning,
    message: ''
  }),
  computed: {
    phoneShow() {
      if (this.storeData.phone) {
        return this.storeData.phone
      }

      return ''
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    }
  },
  created() {
    this.typeVerify = parseInt(this.$route.query.type)
    if (this.typeVerify === constants.typeOtp.forgotPassword) {
      this.storeData = this.$store.state.user.forgotPassword
    } else {
      this.storeData = this.$store.state.user.registerUser
    }

    this.titleSuccess = this.$t('register_success')
    this.contentSuccess = this.$t('start_bfast_right_now')
    const msg = this.$route.query.message
    if (this.isOverLimit(msg)) {
      this.countDown = 0
      this.messageCountDown = this.$t('verify_code_expired')
      this.showNotiOverLimit(msg)
      this.isStateSuppend = true
    } else {
      this.countDown = this.calculateTimeCountDown()
      if (this.countDown > 0) {
        this.messageCountDown = this.$t('code_expires_later')
        this.countDownTimer()
      } else {
        this.messageCountDown = this.$t('verify_code_expired')
      }
    }
  },
  methods: {
    calculateTimeCountDown() {
      const timestamp = StorageService.getTimeGetOtp()
      const currentDate = moment().format()
      const checkTime = moment(currentDate).diff(moment(timestamp), 'seconds')
      if (checkTime <= 0) return constants.initTimeCountDown

      if (checkTime > 0 && checkTime < constants.initTimeCountDown) {
        return constants.initTimeCountDown - checkTime
      }

      return 0
    },
    async submit() {
      var valid = this.$refs.observer.validate()
      if (valid) {
        if (this.typeVerify === constants.typeOtp.forgotPassword) {
          this.verifyForgotPassword()
        } else {
          this.verifyRegiter()
        }
      }
    },
    async verifyForgotPassword() {
      this.showLoading = true
      const pars = this.bindRequestPars()
      const { status, data } = await UserService.forgotPasswordVerify(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok) {
        this.$router.push({
          path: routePaths.FORGOT_PASSWORD_UPDATE,
          query: {
            token: data.token
          }
        })
      } else {
        this.toggleDialogNoti({
          state: true,
          msg: data.message
        })
        this.otp = ''
        setTimeout(() => {
          this.toggleDialogNoti()
        }, constants.timeOut)
      }
    },
    async verifyRegiter() {
      this.showLoading = true
      const pars = this.bindRequestRegisterPars()
      const { status, data } = await UserService.otpVerify(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok) {
        this.showSuccess = true
        if (
          this.storeData.introduceCode !== null &&
          this.storeData.introduceCode !== ''
        ) {
          this.titleSuccess = this.$t('register_success')
          this.contentSuccess = this.$t('start_bfast_right_now')
          this.classImgSrcSuccess = 'bg-register-suscess-has-code'
        }
        setTimeout(() => {
          this.$router.push(routePaths.LOGIN)
        }, constants.timeOut)
      } else {
        this.toggleDialogNoti({
          state: true,
          msg: data.message
        })
        this.otp = ''
        setTimeout(() => {
          this.toggleDialogNoti()
        }, constants.timeOut)
      }
    },
    bindRequestPars() {
      const rqPars = this.storeData
      rqPars['otp'] = this.otp
      return rqPars
    },
    bindRequestRegisterPars() {
      const rqPars = this.storeData
      rqPars['otp'] = this.otp
      return rqPars
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt)
    },
    onInputOtp(value) {
      if (value.length === constants.maximumOtp) {
        this.invalid = false
      } else {
        this.invalid = true
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.messageCountDown = this.$t('verify_code_expired')
      }
    },
    async onResendOtp() {
      if (this.isStateSuppend) return

      this.showLoading = true
      const pars = {
        phone: StorageService.getUserName()
      }
      const { status, data } = await UserService.otpResend(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok) {
        StorageService.setTimeGetOtp(data.metadata.timestamp)
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: data.payload
        })
        this.messageCountDown = this.$t('code_expires_later')
        this.countDown = this.calculateTimeCountDown()
        this.countDownTimer()
        this.otp = ''
        setTimeout(() => {
          this.toggleDialogNoti()
        }, 1500)
      } else {
        if (this.isOverLimit(data.message)) {
          this.showNotiOverLimit(data.message)
          this.isStateSuppend = true
        } else {
          this.toggleDialogNoti({
            state: true,
            msg: data.message
          })
        }

        this.otp = ''
        setTimeout(() => {
          this.toggleDialogNoti()
        }, constants.timeOut)
      }
    },
    isOverLimit(msg) {
      return (
        typeof msg !== constants.undefined &&
        msg !== null &&
        msg.startsWith(constants.startsWithOtpOverLimit)
      )
    },
    showNotiOverLimit(msg) {
      const arrMsg = msg.split(',')
      const msgShow = `${this.$t('send_otp_too')} ${arrMsg[0]} ${this.$t(
        'times'
      )}. ${this.$t('please_try_again_later')} ${DateTimeUtils.formatDateTime(
        arrMsg[1],
        constants.formatDateTimeHm
      )}`

      this.toggleDialogNoti({
        state: true,
        msg: msgShow
      })

      const expDate = arrMsg[1]
      const currentDate = moment().format()
      const checkTime = moment(currentDate).diff(moment(expDate), 'seconds')
      if (checkTime < 0) {
        const timeOut = Math.abs(checkTime) * 1000
        setTimeout(() => {
          this.toggleDialogNoti()
          this.isStateSuppend = false
        }, timeOut)
      }
    },
    toggleDialogNoti(
      pars = {
        state: false,
        type: constants.typeAlert.warning,
        msg: ''
      }
    ) {
      this.showNoti = pars.state
      this.typeNoti = pars.type
      this.message = pars.msg
    }
  }
}
</script>
<style src="@/styles/form.scss" lang="scss" />
