import constants from '@/constants'

export class StringUtils {
  static formatNumber(value) {
    if (value === null || value === '') {
      return ''
    }

    const newVal = this.replaceComma(value)
    if (isNaN(newVal)) return ''

    return newVal
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, constants.signFormatMoney)
  }

  static formatDecimal(val) {
    if (val === null || val === '') {
      return ''
    }

    const valStr = this.replaceComma(val)
    if (isNaN(valStr)) return ''

    if (this.isExistSeparation(valStr)) {
      const arrVal = valStr.split(constants.separation)
      let newVal = valStr
      if (
        arrVal.length === 2 &&
        arrVal[1].toString().length > constants.maximumAfterDecimal
      ) {
        newVal = parseFloat(newVal)
          .toFixed(constants.maximumAfterDecimal)
          .toString()
      }

      return newVal
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, constants.signFormatMoney)
    } else {
      return valStr
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, constants.signFormatMoney)
    }
  }

  static isExistSeparation(value) {
    return value.toString().indexOf(constants.separation) > -1
  }

  static isExistComma(value) {
    return value.toString().indexOf(',') > -1
  }

  static replaceSeparation(value) {
    return value.toString().replace(/[.]/g, '')
  }

  static replaceComma(val) {
    return val?.toString().replace(/[,]/g, '')
  }

  static numStrToFloat(val) {
    if (val === null || val === '') return 0

    const newVal = this.replaceComma(val)
    return parseFloat(newVal)
  }

  static numStrToInt(val) {
    if (val === null || val === '') return 0

    const newVal = this.replaceComma(val)
    return parseInt(newVal)
  }

  static getIconSrc(type) {
    switch (type) {
      case constants.typeAlert.success:
        return 'mdi-check-circle'
      case constants.typeAlert.error:
        return 'mdi-alert-octagon-outline'
      case constants.typeAlert.warning:
        return 'mdi-alert-outline'
      default:
        return 'mdi-information-outline'
    }
  }

  static replaceHorizontal(val) {
    return val.replace(/[-]/g, '')
  }

  static getColorByStatus(status) {
    switch (status) {
      case constants.orderStatus.cancelled:
        return 'rgba(117, 140, 163, 0.15)'
      case constants.orderStatus.waiting:
        return 'rgba(24, 158, 255, 0.15)'
      case constants.orderStatus.delivering:
      case constants.orderStatus.refundingToSender:
      case constants.orderStatus.waitingDelivery:
      case constants.orderStatus.refundedToSender:
        return 'rgba(255, 152, 0, 0.15)'
      case constants.orderStatus.successfulDelivery:
      case constants.orderStatus.completed:
        return 'rgba(55, 208, 70, 0.15)'
      case constants.orderStatus.pickUpFailed:
        return 'rgba(222, 41, 76, 0.15)'
      case constants.orderStatus.parcelWeight:
        return ''
      default:
        return ''
    }
  }

  static getClassTextByStatus(status) {
    switch (status) {
      case constants.orderStatus.cancelled:
        return 'status-cancelled--text'
      case constants.orderStatus.waiting:
        return 'status-waiting--text'
      case constants.orderStatus.delivering:
      case constants.orderStatus.refundingToSender:
      case constants.orderStatus.waitingDelivery:
      case constants.orderStatus.refundedToSender:
        return 'status-delivering--text'
      case constants.orderStatus.successfulDelivery:
      case constants.orderStatus.completed:
        return 'status-completed--text'
      case constants.orderStatus.pickUpFailed:
        return 'status-pickupfailed--text'
      case constants.orderStatus.parcelWeight:
        return ''
      default:
        return ''
    }
  }

  static getPathNameByPath(path) {
    return path.substring(1, path.length)
  }
}
export const getTextTruncate = (val, size) => {
  if (val.length > size) {
    return val.substring(0, size) + "...";
  } else {
    return val;
  }
};

export const normalizeString = (s, transform = s => s.toLocaleLowerCase()) => {
  let normalized = transform(s)
  if (String.prototype.normalize) {
    try {
      normalized = normalized.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, "d")
        .replace(/Đ/g, "d")
    } catch (e) {
      // just ignore
    }
  }
  return normalized
}
