import constants from '@/constants'
import vi from 'vee-validate/dist/locale/vi.json'
import translations from './global.json'

const msgRequired = translations['message_required']
const incorrectFormat = '{_field_} ' + translations['incorrect_format']
const msgPassword = `${translations['password']} ${translations['must_belong']} ${constants.minimumPassword} ${translations['to'].toLowerCase()} ${constants.maximumPassword} ${translations['and']} ${translations['include']} ${translations['full']}: ${translations['numbers']}, ${translations['uppercase_letters']}, ${translations['lowercase_letters']}, ${translations['special_characters']}`
const incorrectPassword = '{_field_} ' + translations['incorrect']
const msgSelectRequired = translations['select_info']
const msgWaybill = '{_field_} ' + ` ${translations['must_belong']} ${constants.minimumWaybill} ${translations['to'].toLowerCase()} ${constants.maximumWaybill} ${translations['characters']}, ${translations['not_contain']} ${translations['special_characters']}`
const msgInvalid = '{_field_} ' + translations['invalid']
const msgOrder = '{_field_} ' + translations['less_than_zero']

export default {
  messages: vi.messages,
  names: {
    email: translations['email'],
    phone: translations['phone'],
    password: translations['password'],
    confirmPassword: translations['confirm_password'],
    shopName: `${translations['name']} ${translations['shop']}`,
    province: `${translations['address']} ${translations['shop']}`,
    orderQuantity: `${translations['order_quantity']}/${translations['month']}`,
    serviceProvider: translations['service_provider'],
    waybillrequire: translations['way_bill_required'],
    orderWeight: translations['order_weight'],
    orderValue: translations['order_value']
  },
  fields: {
    email: {
      required: msgRequired,
      email: incorrectFormat,
      min: incorrectFormat,
      max: incorrectFormat,
      regex: incorrectFormat
    },
    phone: {
      required: msgRequired,
      min: incorrectFormat,
      max: incorrectFormat,
      regex: incorrectFormat
    },
    password: {
      required: msgRequired,
      min: msgPassword,
      max: msgPassword,
      regex: msgPassword
    },
    confirmPassword: {
      required: msgRequired,
      confirmed: incorrectPassword
    },
    shopName: {
      required: msgRequired
    },
    province: {
      required: msgSelectRequired
    },
    orderQuantity: {
      required: msgRequired
    },
    serviceProvider: {
      required: msgRequired
    },
    require: {
      required: msgRequired
    },
    selectRequire: {
      required: msgSelectRequired
    },
    waybillrequire: {
      min: msgWaybill,
      max: msgWaybill,
      regex: msgWaybill
    },
    orderWeight: {
      required: msgRequired,
      regex: msgInvalid
    },
    orderValue: {
      required: msgRequired,
      regex: msgOrder
    }
  }
}
