<template>
  <v-dialog v-model="internalValue" :max-width="widthDialog" persistent>
    <v-card>
      <v-card-title class="justify-center text-uppercase">
        <label>{{ title }}</label>
      </v-card-title>
      <v-card-text class="text-center mt-3">
        <v-row justify="center" class="black--text px-10">
          {{ content }}
        </v-row>
      </v-card-text>
      <v-layout justify-center>
        <div :class="imgSrc" />
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import constants from '@/constants'
export default {
  name: 'DialogSuccess',
  props: {
    widthDialog: {
      type: Number,
      default: constants.widthDialog
    },
    value: Boolean,
    title: {
      type: String,
      default: null
    },
    content: {
      type: String,
      default: null
    },
    imgSrc: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      internalValue: this.value
    }
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value(val, oldVal) {
      if (val === oldVal) return

      this.internalValue = val
    }
  }
}
</script>
