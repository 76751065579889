<template>
  <v-dialog
    v-model="internalValue"
    :max-width="widthDialog"
    persistent
    content-class="br-24"
  >
    <v-layout
      column
      white
      rounded-xl
      box-shadow
      pa-sm-16
      class="pos-rel overflow-x-hidden"
    >
      <v-icon @click="closeDialog" class="close-icon cursor-pointer">mdi-close</v-icon>
      <label
        class="text-uppercase font-weight-medium text-size-title mt-8 text-center"
      >
        {{ $t("input_password") }}
      </label>
      <p class="mt-2 mb-10 text-body-2 text-center">
        {{ $t("enter_password_confirm") }}
      </p>
      <validation-observer ref="observer" v-slot="{ valid }">
        <v-layout column>
          <label class="text-subtitle-1">
            {{ $t("curent_password") }}
            <span class="red--text">{{ $t("required") }}</span>
          </label>
          <validation-provider
            v-slot="{ errors }"
            name="password"
            :rules="passwordRules"
          >
            <v-text-field
              v-model="password"
              outlined
              dense
              class="rounded-lg"
              :append-icon="isPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isPassword ? 'password' : 'text'"
              :maxlength="maximumPassword"
              :error-messages="errors"
              :placeholder="$t('place_holders.password')"
              @click:append="isPassword = !isPassword"
            />
          </validation-provider>
        </v-layout>
        <div>
          <v-btn
            block
            large
            color="primary"
            type="submit"
            class="rounded-lg mt-10 mb-8"
            :disabled="!valid"
            @click="submit"
          >
            <span class="font-weight-medium white--text">
              {{ $t("continue") }}
            </span>
          </v-btn>
        </div>
      </validation-observer>
    </v-layout>
  </v-dialog>
</template>

<script>
import constants from "@/constants";

export default {
  props: {
    widthDialog: {
      type: Number,
      default: constants.widthDialog,
    },
    value: Boolean,
  },
  data() {
    return {
      internalValue: this.value,
      password: "",
      isPassword: true,
      maximumPassword: constants.maximumPassword,
      passwordRules: {
        required: true,
        min: constants.minimumPassword,
        max: constants.maximumPassword,
        regex: constants.regExp.password,
      },

      // loading
      showLoading: false,

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
    };
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return;
      this.$emit("input", val);
    },
    value(val, oldVal) {
      if (val === oldVal) return;
      this.internalValue = val;
    },
  },
  methods: {
    submit() {
      var valid = this.$refs.observer.validate();
      if (valid) {
        this.$emit("on-submit", this.password);
      }
    },
    closeDialog() {
      this.internalValue = false;
    },
    async resetForm() {
      if (this.$refs.observer) {
        this.password = null
        await this.$refs.observer.validate()
        await this.$refs.observer.reset()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  right: 25px;
  top: 25px;
}
</style>
