<template>
  <div>
    <v-layout>
      <v-layout column class="col-7 px-0">
        <v-layout justify-space-between align-center class="mb-3">
          <h6 class="text-subtitle-2 text-uppercase col-4 pa-0">
            {{ $t('cash_flow_report') }}
          </h6>
          <v-layout justify-end>
            <v-menu
              ref="menu_report"
              v-model="menuReport"
              :close-on-content-click="false"
              :return-value.sync="reportDates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  append-icon="mdi-calendar"
                  outlined
                  dense
                  hide-details=""
                  readonly
                  clearable
                  :value="reportDateRangeText"
                  :placeholder="`${$t('from')}     -     ${$t('to')}`"
                  class="mr-4 col-6"
                  v-bind="attrs"
                  v-on="on"
                  @input="onInputReportDateRange"
                />
              </template>
              <v-date-picker
                v-model="reportDates"
                range
                no-title
                :locale="locale"
                :max="maxAllowedDate"
              >
                <v-spacer />
                <v-btn text color="primary" @click="menuReport = false">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu_report.save(reportDates)"
                >
                  {{ $t('select') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-btn color="primary">
              {{ $t('search') }}
            </v-btn>
          </v-layout>
        </v-layout>
        <v-data-table
          hide-default-footer
          class="border-primary"
          :no-data-text="$t('not_found')"
          :headers="reportHeaders"
          :header-props="{ sortIcon: 'mdi-menu-swap' }"
          :items="reportItems"
        />
      </v-layout>
      <v-layout column class="col-5 pl-5 pr-0">
        <v-layout justify-end class="mb-10">
          <v-btn color="primary">
            <v-icon class="mr-2">mdi-cached</v-icon>
            {{ $t('checking_request') }}
          </v-btn>
        </v-layout>
        <v-layout>
          <label class="col-3 pa-0 text-subtitle-2">Chờ đối soát: </label>
          <span class="text-body-2">
            Là tiền của các đơn ở trạng thái Đã giao hàng và chờ đối soát trong
            kỳ tiếp theo
          </span>
        </v-layout>
        <v-layout>
          <label class="col-3 pa-0 text-subtitle-2">Đã đối soát: </label>
          <span class="text-body-2">
            Là tiền của các đơn ở trạng thái Đã giao hàng và đã được đối soát
          </span>
        </v-layout>
        <v-layout>
          <label class="col-3 pa-0 text-subtitle-2">Chưa giao: </label>
          <span class="text-body-2">
            Là tiền của các đơn đã lấy hàng nhưng chưa Giao thành công
          </span>
        </v-layout>
        <v-layout>
          <label class="col-3 pa-0 text-subtitle-2">Tổng tiền: </label>
          <span class="text-body-2">
            Là tổng tiền tất cả các đơn hàng (trừ các đơn ở trạng thái Đang chờ
            lấy hàng)
          </span>
        </v-layout>
      </v-layout>
    </v-layout>
    <v-divider />
    <v-layout column>
      <v-layout justify-space-between align-center class="col-7 px-0">
        <h6 class="text-subtitle-2 text-uppercase col-4 pa-0">
          {{ $t('history_of_comparisons') }}
        </h6>
        <v-layout justify-end>
          <v-menu
            ref="menu_his"
            v-model="menuHis"
            :close-on-content-click="false"
            :return-value.sync="hisDates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                append-icon="mdi-calendar"
                outlined
                dense
                hide-details=""
                readonly
                clearable
                :value="hisDateRangeText"
                :placeholder="`${$t('from')}     -     ${$t('to')}`"
                class="mr-4 col-6"
                v-bind="attrs"
                v-on="on"
                @input="onInputHisDateRange"
              />
            </template>
            <v-date-picker
              v-model="hisDates"
              range
              no-title
              :locale="locale"
              :max="maxAllowedDate"
            >
              <v-spacer />
              <v-btn text color="primary" @click="menuHis = false">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu_his.save(hisDates)"
              >
                {{ $t('select') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn color="primary">
            {{ $t('search') }}
          </v-btn>
        </v-layout>
      </v-layout>
      <v-data-table
        hide-default-footer
        class="border-primary"
        :no-data-text="$t('not_found')"
        :headers="hisHeaders"
        :header-props="{ sortIcon: 'mdi-menu-swap' }"
        :items="hisItems"
      >
        <template v-slot:footer>
          <custom-pagination
            :page="page"
            :start-record="computedStartRecord"
            :end-record="computedEndRecord"
            :total-record="totalRecord"
            :total-page="totalPage"
            :on-change-page="onChangePage"
          />
        </template>
      </v-data-table>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />
  </div>
</template>

<script>
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import moment from 'moment'
import constants from '@/constants'
import { StringUtils } from '@/helpers/stringUtils'
import { DateTimeUtils } from '@/helpers/dateTimeUtils'

export default {
  components: {
    DialogLoading,
    CustomPagination
  },
  data() {
    return {
      menuReport: false,
      reportDates: [],
      reportDatesFm: [],
      reportHeaders: [
        {
          text: '',
          sortable: false,
          value: 'name'
        },
        { text: this.$t('money_cod'), sortable: false, value: 'calories' },
        {
          text: this.$t('service_fee'),
          sortable: false,
          value: 'fat'
        },
        { text: this.$t('promotion'), sortable: false, value: 'carbs' },
        { text: this.$t('bill_number'), sortable: false, value: 'protein' }
      ],
      reportItems: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0
        },
        {
          name: 'Cupcake',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3
        }
      ],

      menuHis: false,
      hisDates: [],
      hisDatesFm: [],
      hisHeaders: [
        {
          text: this.$t('checking_period'),
          value: 'trackingId'
        },
        { text: this.$t('checking_day'), value: 'receiverName' },
        { text: this.$t('checking_code'), value: 'receiverPhone' },
        {
          text: this.$t('bill_number'),
          sortable: false,
          value: 'receiverName'
        },
        { text: this.$t('money_cod'), sortable: false, value: 'receiverName' },
        {
          text: this.$t('service_fee'),
          sortable: false,
          value: 'receiverPhone'
        },
        { text: this.$t('promotion'), sortable: false, value: 'createdAtFm' },
        {
          text: this.$t('checking_money'),
          sortable: false,
          value: 'updatedAtFm'
        }
      ],
      hisItems: [],
      totalPage: 0,
      page: 1,
      totalRecord: 0,

      showLoading: false
    }
  },
  computed: {
    maxAllowedDate() {
      return moment().format(constants.formatDateYmd)
    },
    reportDateRangeText() {
      return this.reportDatesFm.join(' - ')
    },
    hisDateRangeText() {
      return this.hisDatesFm.join(' - ')
    },
    computedStartRecord() {
      if (this.hisItems !== null && this.hisItems.length > 0) {
        return (this.page - 1) * constants.itemsPerPage + 1
      } else {
        return 0
      }
    },
    computedEndRecord() {
      if (this.hisItems !== null && this.hisItems.length > 0) {
        return (this.page - 1) * constants.itemsPerPage + this.hisItems.length
      } else {
        return (this.page - 1) * constants.itemsPerPage
      }
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    }
  },
  watch: {
    reportDates(val) {
      const newDates = []
      val.forEach((i) => {
        const item = this.formatDate(i)
        newDates.push(item)
      })
      this.reportDatesFm = newDates
    },
    hisDates(val) {
      const newDates = []
      val.forEach((i) => {
        const item = this.formatDate(i)
        newDates.push(item)
      })
      this.hisDatesFm = newDates
    }
  },
  methods: {
    onInputReportDateRange(val) {
      if (!val) {
        this.reportDates = []
      }
    },
    onInputHisDateRange(val) {
      if (!val) {
        this.hisDates = []
      }
    },
    onChangePage(page) {
      this.page = page
    },
    formatDate(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate)
    },
    parseDate(date) {
      return DateTimeUtils.parseDate(date)
    }
  }
}
</script>
