var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"col-12",attrs:{"cols":"sm-12"}},[_c('v-col',{staticClass:"border-primary rounded-lg px-3 px-sm-6 py-6"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{staticClass:"col-12",attrs:{"cols":"sm-6"}},[_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(((_vm.$t("name")) + " " + (_vm.$t("shop"))))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"require","vid":"fullName","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","error-messages":errors,"maxlength":_vm.maximumSmallText,"placeholder":_vm.$t('place_holders.shop_name')},on:{"change":function($event){_vm.name = _vm.trimSpace(_vm.name)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)],1),_c('v-col',{staticClass:"col-12",attrs:{"cols":"sm-6"}},[_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("email"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"email","rules":_vm.emailRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","error-messages":errors,"maxlength":_vm.maximumEmail,"placeholder":_vm.$t('place_holders.email')},on:{"change":function($event){_vm.email = _vm.trimSpace(_vm.email)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-12",attrs:{"cols":"sm-6"}},[_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("phone"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"phone","rules":_vm.phoneRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"type":"text","pattern":"\\d*","outlined":"","dense":"","error-messages":errors,"maxlength":_vm.maximumPhone,"placeholder":_vm.$t('place_holders.phone')},on:{"keypress":function($event){return _vm.isNumber($event)},"paste":function($event){$event.preventDefault();return _vm.onPastePhone($event)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1)],1),_c('v-col',{staticClass:"col-12",attrs:{"cols":"sm-6"}},[_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("address"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"selectRequire","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","item-text":"name","item-value":"code","hide-no-data":"","error-messages":errors,"placeholder":_vm.$t('place_holders.address'),"items":_vm.lstProvince},model:{value:(_vm.provinceSelected),callback:function ($$v) {_vm.provinceSelected=$$v},expression:"provinceSelected"}})]}}],null,true)})],1)],1)],1),_c('v-layout',{attrs:{"justify-space-between":""}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.onGotoChangePassword}},[_vm._v(" "+_vm._s(_vm.$t("change_password"))+" ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)]}}])})],1)],1)],1)],1),_c('dialog-loading',{attrs:{"message":_vm.$t('processing')},model:{value:(_vm.showLoading),callback:function ($$v) {_vm.showLoading=$$v},expression:"showLoading"}}),_c('dialog-notification',{attrs:{"icon-src":_vm.getIcon,"message":_vm.message},model:{value:(_vm.showNoti),callback:function ($$v) {_vm.showNoti=$$v},expression:"showNoti"}}),_c('dialog-confirm-password',{ref:"DialogConfirmPassword",attrs:{"width-dialog":600},on:{"on-submit":_vm.onSubmitConfirmPassword},model:{value:(_vm.showConfirmPassword),callback:function ($$v) {_vm.showConfirmPassword=$$v},expression:"showConfirmPassword"}}),_c('DialogConfirmTokenExpried',{model:{value:(_vm.showConfirmTokenExpried),callback:function ($$v) {_vm.showConfirmTokenExpried=$$v},expression:"showConfirmTokenExpried"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }