<template>
  <v-app>
    <v-layout white>
      <v-layout box-noauth bg-main />
      <v-layout column justify-center>
        <v-container zindex-1>
          <v-layout column>
            <v-layout col d-none d-md-flex>
              <h2 class="logo pos-logo-full" />
            </v-layout>
            <v-layout justify-center>
              <v-col
                cols="12"
                class="col-sm-8 col-md-7 col-lg-5 col-xl-4 pa-0 pa-sm-3"
              >
                <v-layout
                  column
                  white
                  rounded-xl
                  box-shadow
                  px-6
                  py-16
                  pa-sm-16
                >
                  <label
                    class="text-uppercase font-weight-medium text-size-title mt-8 text-center"
                  >
                    {{ $t('update_password') }}
                  </label>
                  <p class="mt-2 mb-10 text-body-2 text-center">
                    {{ $t('enter_new_password') }}
                  </p>
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form @submit.prevent="submit">
                      <v-layout column>
                        <label class="text-body-2">
                          {{ $t('new_password') }}
                          <span class="red--text">{{ $t('required') }}</span>
                        </label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="password"
                          :rules="passwordRules"
                        >
                          <v-text-field
                            v-model="password"
                            outlined
                            dense
                            class="rounded-lg"
                            :append-icon="
                              isPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="isPassword ? 'password' : 'text'"
                            :maxlength="maximumPassword"
                            :error-messages="errors"
                            :placeholder="$t('place_holders.password')"
                            @click:append="isPassword = !isPassword"
                          />
                        </validation-provider>
                      </v-layout>
                      <v-layout column>
                        <label class="text-body-2">
                          {{ $t('confirm_password') }}
                          <span class="red--text">{{ $t('required') }}</span>
                        </label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="confirmPassword"
                          :rules="confirmPasswordRules"
                        >
                          <v-text-field
                            v-model="confirmPassword"
                            outlined
                            dense
                            class="rounded-lg"
                            :append-icon="
                              isPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="isPasswordConfirm ? 'password' : 'text'"
                            :maxlength="maximumPassword"
                            :error-messages="errors"
                            :placeholder="$t('place_holders.confirn_password')"
                            @click:append="
                              isPasswordConfirm = !isPasswordConfirm
                            "
                          />
                        </validation-provider>
                      </v-layout>
                      <div>
                        <v-btn
                          block
                          large
                          color="primary"
                          type="submit"
                          class="rounded-lg mt-10 mb-8"
                          :disabled="invalid"
                        >
                          <span class="font-weight-medium white--text">
                            {{ $t('continue') }}
                          </span>
                        </v-btn>
                      </div>
                    </v-form>
                  </validation-observer>
                </v-layout>
              </v-col>
            </v-layout>
          </v-layout>
        </v-container>
      </v-layout>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
      :is-show-brand="isShowBrand"
    />
  </v-app>
</template>

<script>
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import constants from '@/constants'
import routePaths from '@/router/routePaths'
import { FormUtils } from '@/helpers/formUtils'
import { StringUtils } from '@/helpers/stringUtils'
import { StorageService } from '@/services/storageService'
import { UserService } from '@/services/userService'

export default {
  components: { DialogLoading, DialogNotification },
  data: () => ({
    token: '',
    password: '',
    isPassword: true,
    maximumPassword: constants.maximumPassword,
    passwordRules: {
      required: true,
      min: constants.minimumPassword,
      max: constants.maximumPassword,
      regex: constants.regExp.password
    },
    confirmPassword: '',
    isPasswordConfirm: true,
    confirmPasswordRules: {
      required: true,
      confirmed: 'password'
    },

    // loading
    showLoading: false,

    // notifications
    showNoti: false,
    typeNoti: constants.typeAlert.warning,
    message: '',
    isShowBrand: false
  }),
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    }
  },
  created() {
    this.token = this.$route.query.token
    if (!this.token) {
      this.$router.push(routePaths.LOGIN)
    }
  },
  methods: {
    async submit() {
      var valid = this.$refs.observer.validate()
      if (valid && this.token) {
        this.showLoading = true
        const pars = this.bindRequestPars()
        const { status, data } = await UserService.forgotPasswordUpdate(pars)
        this.showLoading = false
        if (status === constants.statusCode.ok) {
          this.toggleDialogNoti({
            state: true,
            type: constants.typeAlert.success,
            msg: this.$t('update_password_success')
          })
          StorageService.setUserName(null)
          setTimeout(() => {
            this.$router.push(routePaths.LOGIN)
          }, constants.timeOut)
        } else {
          let msgNoti = ''
          if (data.message === constants.errorValidation) {
            var jsonObj = data.details
            var keys = Object.keys(jsonObj)
            for (var i = 0; i < keys.length; i++) {
              if (i < keys.length - 1) {
                msgNoti += jsonObj[keys[i]] + ','
              } else {
                msgNoti += jsonObj[keys[i]]
              }
            }
          } else {
            msgNoti = data.message
          }

          this.toggleDialogNoti({ state: true, msg: msgNoti })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    bindRequestPars() {
      const pars = {
        phone: StorageService.getUserName(),
        password: this.password,
        otpToken: this.token
      }

      return pars
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt)
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: '' }
    ) {
      this.showNoti = pars.state
      this.typeNoti = pars.type
      this.message = pars.msg
    }
  }
}
</script>
<style src="@/styles/form.scss" lang="scss" />
