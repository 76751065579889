<template>
  <v-dialog v-model="internalValue" persistent width="500">
    <v-card class="px-4 py-2">
      <v-layout justify-end>
        <v-icon size="20" @click="onCloseDialog">
          mdi-close
        </v-icon>
      </v-layout>
      <v-layout column class="px-2">
        <v-layout column>
          <label class="text-subtitle-1 font-weight-large text-center">
            Tạo đơn hàng
          </label>
          <label class="text-caption text-center">
            Vui lòng lựa chọn hình thức tạo đơn hàng
          </label>
        </v-layout>
        <v-layout justify-space-between class="order-create-actions mt-8 mb-5">
          <v-layout
            class="w-190 rounded-lg mr-3 pa-4 xs-column hover-pointer"
            :class="activePackage ? 'border-primary' : 'border'"
            @click="goOrderCreate"
          >
            <v-layout justify-center align-center>
              <v-btn
                fab
                small
                color="rgba(15, 144, 232, 0.1)"
                :class="activePackage ? 'active' : ''"
              >
                <i class="type-icon icon-package" />
              </v-btn>
            </v-layout>
            <v-layout column :class="activePackage ? 'primary--text' : ''">
              <label class="text-subtitle-2 font-weight-large hover-pointer">{{
                $t("order_create_one")
              }}</label>
              <span class="text-min">{{ $t("caption_order_create_one") }}</span>
              <span class="text-min">{{ $t("caption_order_create_2") }}</span>
            </v-layout>
          </v-layout>
          <v-layout
            class="w-190 rounded-lg ml-3 pa-4 xs-column hover-pointer"
            :class="activePackages ? 'border-primary' : 'border'"
            @click="goOrderCreateMultiple"
          >
            <v-layout justify-center align-center>
              <v-btn
                fab
                small
                color="rgba(15, 144, 232, 0.1)"
                :class="activePackages ? 'active' : ''"
              >
                <i class="type-icon icon-packages" />
              </v-btn>
            </v-layout>
            <v-layout column :class="activePackages ? 'primary--text' : ''">
              <label class="text-subtitle-2 font-weight-large hover-pointer">{{
                $t("create_multiple_order")
              }}</label>
              <span class="text-min">{{ $t("caption_order_create_mul") }}</span>
              <span class="text-min">{{ $t("caption_order_create_2") }}</span>
            </v-layout>
          </v-layout>
        </v-layout>
        <!--TODO- an do call loi api-->
        <v-layout class="order-create-multi-box hover-pointer">
          <v-layout
            class="w-190 rounded-lg pa-4 xs-column"
            :class="activeMultiBox ? 'border-primary' : 'border'"
            @click="goOrderCreateMultiBox"
          >
            <v-layout justify-center align-center>
              <v-btn
                fab
                small
                color="rgba(15, 144, 232, 0.1)"
                :class="activeMultiBox ? 'active' : ''"
              >
                <i class="type-icon icon-multibox" />
              </v-btn>
            </v-layout>
            <v-layout column :class="activeMultiBox ? 'primary--text' : ''">
              <label class="text-subtitle-2 font-weight-large hover-pointer">{{
                $t("create_multi_box_order")
              }}</label>
              <span class="text-min">{{ $t("caption_order_create_mul") }}</span>
              <span class="text-min">{{ $t("caption_order_create_2") }}</span>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    activePackage: {
      type: Boolean,
      default: undefined,
    },
    activePackages: {
      type: Boolean,
      default: undefined,
    },
    activeMultiBox: {
      type: Boolean,
      default: undefined,
    },
    internalValue: {
      type: Boolean,
      default: undefined,
    },
  },
  methods: {
    goOrderCreate() {
      this.$emit("go-order-create");
    },
    goOrderCreateMultiple() {
      this.$emit("go-order-create-multiple");
    },
    goOrderCreateMultiBox() {
      this.$emit("go-order-create-multi-box")
    },
    onCloseDialog() {
      this.$emit("on-close-dialog");
    }
  },
};
</script>

<style>
.hover-pointer {
  cursor: pointer;
}
</style>
