import constants from '@/constants'

export class DataUtils {
  static calculatePage(totalRecord) {
    if (totalRecord % constants.itemsPerPage !== 0) {
      return parseInt(totalRecord / constants.itemsPerPage) + 1
    } else {
      return parseInt(totalRecord / constants.itemsPerPage)
    }
  }

  static calculatePageByItemsPerPage(totalRecord, itemsPerPage) {
    if (totalRecord % itemsPerPage !== 0) {
      return parseInt(totalRecord / itemsPerPage) + 1
    } else {
      return parseInt(totalRecord / itemsPerPage)
    }
  }

  static isRoleAccept(role) {
    const acceptRoles = constants.roleAccept
    return acceptRoles.includes(role)
  }
}
