import constants from '@/constants'
import { AuthService } from '@/services/authService'
import { UserService } from '@/services/userService'

export default {
  async loginUser({ commit }, pars) {
    try {
      const response = await AuthService.login(pars)
      if (
        response.status === constants.statusCode.ok &&
        response.data
      ) {
        commit('SET_CURRENT_USER', response.data)
      }
      return response
    } catch (e) {
      e['error'] = true
      return e
    }
  },

  async registerUser({ commit }, pars) {
    try {
      const response = await UserService.register(pars)
      if (response.status === constants.statusCode.ok && response.data) {
        commit('SET_REGISTER_USER', pars)
      }
      return response
    } catch (e) {
      e['error'] = true
      return e
    }
  },

  async forgotPassword({ commit }, pars) {
    try {
      const response = await UserService.forgotPassword(pars)
      if (response.status === constants.statusCode.ok && response.data) {
        commit('SET_FORGOT_PASSWORD', pars)
      }
      return response
    } catch (e) {
      e['error'] = true
      return e
    }
  },

  async checkCurrentPassword({ commit }, pars) {
    try {
      const response = await UserService.changePasswordCheck(pars)
      if (response.status === constants.statusCode.ok && response.data) {
        commit('SET_CHECK_CURRENT_PASSWORD', response.data)
      }
      return response
    } catch (e) {
      e['error'] = true
      return e
    }
  },

  async changePasswordUpdate({ commit }, pars) {
    try {
      const response = await UserService.changePasswordUpdate(pars)
      if (response.status === constants.statusCode.ok && response.data) {
        commit('SET_CHECK_CURRENT_PASSWORD', null)
      }
      return response
    } catch (e) {
      e['error'] = true
      return e
    }
  }
}
