<template>
  <div id="dashboard">
    <div class="description-section py-8 px-6">
      <v-layout justify-space-between align-center>
        <v-layout column>
          <span class="text--body-5-20 color-white">
            {{ $t("greeting") }}, {{ currentUser.name }} !
          </span>
          <span class="color-white pt-6">
            <span class="fw-500">{{ $t("brand") }}</span> - {{ $t("slogan") }}
          </span>
        </v-layout>
        <v-btn
          class="text-none br-10"
          x-large
          outlined
          dark
          @click="onShowDialogCreateOrderOption"
        >
          <v-icon size="32" class="mr-1">mdi-plus-circle-outline</v-icon>
          <span class="text--body-5-20">{{ $t("create") }}</span>
        </v-btn>
      </v-layout>
    </div>
    <div class="wrap-chart pt-4">
      <div class="left-chart py-6 px-4" style="height: auto">
        <div class="d-flex align-center justify-space-between">
          <span class="title-chart text--body-7-20 text-capitalize flex-30">
            {{ $t("order_name") }}
          </span>
          <div class="d-flex justify-end align-center flex-70">
            <span v-if="circleType === 'TODAY'" class="text-date text--body-6-14">
              {{ circleStartTime }}
            </span>
            <span v-if="circleType === 'WEEK'" class="text-date text--body-6-14">
              {{ convertDateMonthFormat(circleStartTime) }} -
              {{ circleEndTime }}
            </span>
            <span
              v-if="circleType === 'MONTH'"
              class="text-date text--body-6-14 text-capitalize"
            >
              {{ $t("month") }} {{ currentMonth }}
            </span>
            <v-menu
              v-if="circleType === 'custom'"
              ref="menuDateFromTo"
              v-model="menuDateFromTo"
              :close-on-content-click="false"
              :return-value.sync="datesPicker"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  :error-messages="$t(errorDateFromTo)"
                  append-icon="mdi-calendar-range"
                  outlined
                  dense
                  hide-details="auto"
                  readonly
                  clearable
                  placeholder="DD/MM - DD/MM"
                  v-bind="attrs"
                  v-on="on"
                  @input="onInputDateRange"
                />
              </template>
              <v-date-picker
                v-model="datesPicker"
                :allowed-dates="allowedDates"
                :locale="localDate"
                fomat="DD/MM/YYYY"
                no-title
                range
              >
                <v-spacer />
                <v-btn text color="primary" @click="menuDateFromTo = false">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn text color="primary" @click="onChangeDates">
                  {{ $t("select") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-select
              v-model="circleType"
              outlined
              dense
              hide-selected
              hide-details
              floating
              solo
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
              :items="lstCircleType"
              item-text="name"
              item-value="value"
              attach
              class="calendar-text--body-4 ml-2"
              style="max-width: 40%"
              @change="changeTypeCalendar()"
            />
          </div>
        </div>
        <div v-if="totalPieChartData === 0">
          <empty-box :loading="loading" :subtitle="'empty_order_msg'" />
        </div>
        <v-layout v-else align-center justify-center class="position-left-side">
          <piechart
            :series="series"
            :chart-options="chartOptions"
            :total="totalPieChartData"
            @go-order-list="goOrderList"
          />
          <div class="d-flex flex-column">
            <div class="d-flex align-center">
              <v-avatar color="#8E8EA1" size="15" />
              <span
                class="link text--body-4-14 ml-2"
                @click="goOrderList($t('have_not_received'))"
              >
                {{ $t("have_not_received") }}: {{ series[0] }} {{ $t("order") }}
              </span>
              <span
                v-if="totalPieChartData !== 0"
                class="text--body-4-14 color-gray ml-2"
              >
                ({{ calculatePercentage(series[0]) }}%)
              </span>
              <span v-else class="text--body-4-14 color-gray ml-2">(0%)</span>
            </div>
            <span class="text--body-4-14 ml-6">
              COD: <span style="color: #0f90e8">{{ listCod[0] }} VND</span>
            </span>
            <div class="d-flex align-center">
              <v-avatar color="#ACE647" size="15" />
              <span
                class="link text--body-4-14 ml-2"
                @click="goOrderList($t('delivering'))"
              >
                {{ $t("delivering") }}: {{ series[1] }} {{ $t("order") }}
              </span>
              <span
                v-if="totalPieChartData !== 0"
                class="text--body-4-14 color-gray ml-2"
              >
                ({{ calculatePercentage(series[1]) }}%)
              </span>
              <span v-else class="text--body-4-14 color-gray ml-5">(0%)</span>
            </div>
            <span class="text--body-4-14 ml-6">
              COD: <span style="color: #0f90e8">{{ listCod[1] }} VND</span>
            </span>
            <div class="d-flex align-center">
              <v-avatar color="#0F90E8" size="15" />
              <span
                class="link text--body-4-14 ml-2"
                @click="goOrderList($t('success_delivery'))"
              >
                {{ $t("success_delivery") }}: {{ series[2] }} {{ $t("order") }}
              </span>
              <span
                v-if="totalPieChartData !== 0"
                class="text--body-4-14 color-gray ml-2"
              >
                ({{ calculatePercentage(series[2]) }}%)
              </span>
              <span v-else class="text--body-4-14 color-gray ml-5">(0%)</span>
            </div>
            <span class="text--body-4-14 ml-6">
              COD: <span style="color: #0f90e8">{{ listCod[2] }} VND</span>
            </span>
            <div class="d-flex align-center">
              <v-avatar color="#FF4A55" size="15" />
              <span
                class="link text--body-4-14 ml-2"
                @click="goOrderList($t('refund_form'))"
              >
                {{ $t("refund_form") }}: {{ series[3] }} {{ $t("order") }}
              </span>
              <span
                v-if="totalPieChartData !== 0"
                class="text--body-4-14 color-gray ml-2"
              >
                ({{ calculatePercentage(series[3]) }}%)
              </span>
              <span v-else class="text--body-4-14 color-gray ml-5">(0%)</span>
            </div>
            <span class="text--body-4-14 ml-6">
              COD: <span style="color: #0f90e8">{{ listCod[3] }} VND</span>
            </span>
            <div class="d-flex align-center">
              <v-avatar color="#ED893E" size="15" />
              <span
                class="link text--body-4-14 ml-2"
                @click="goOrderList($t('cancelled'))"
              >
                {{ $t("cancelled") }}: {{ series[4] }} {{ $t("order") }}
              </span>
              <span
                v-if="totalPieChartData !== 0"
                class="text--body-4-14 color-gray ml-2"
              >
                ({{ calculatePercentage(series[4]) }}%)
              </span>
              <span v-else class="text--body-4-14 color-gray ml-5">(0%)</span>
            </div>
            <span class="text--body-4-14 ml-6">
              COD: <span style="color: #0f90e8">{{ listCod[4] }} VND</span>
            </span>
          </div>
        </v-layout>
      </div>
      <div class="right-chart py-6 px-4">
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center flex-30">
            <span class="title-chart text--body-7-20 text-capitalize mr-2">
              {{ $t("order_delivered") }}
            </span>
          </div>
          <div class="d-flex align-center justify-end flex-70">
            <span v-if="lineType === 'WEEK'" class="text-date text--body-6-14">
              {{ convertDateMonthFormat(lineStartTime) }} - {{ lineEndTime }}
            </span>
            <span v-if="lineType === 'YEAR'" class="text-date text--body-6-14">
              {{ lineYear }}
            </span>
            <v-btn small elevation="0" icon @click="previousCalendar()">
              <v-icon color="#22242C">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              v-if="lineType === 'WEEK'"
              small
              elevation="0"
              icon
              :disabled="isDisabledButtonNextWeek"
              @click="nextCalendar()"
            >
              <v-icon color="#22242C">mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              v-if="lineType === 'YEAR'"
              small
              elevation="0"
              icon
              :disabled="lineYear >= currentYear"
              @click="nextCalendar()"
            >
              <v-icon color="#22242C">mdi-chevron-right</v-icon>
            </v-btn>
            <v-select
              v-model="lineType"
              outlined
              dense
              hide-selected
              hide-details
              floating
              solo
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
              :items="lstLineType"
              item-text="name"
              item-value="value"
              attach
              style="max-width: 42%"
              @change="changeTypeLineCalendar()"
            />
          </div>
        </div>
        <div v-if="!lineSeries.length">
          <empty-box :loading="loading" :subtitle="'empty_order_msg'" />
        </div>
        <v-layout v-else align-center>
          <apexchart
            ref="lineChart"
            class="mx-auto pie-dounut-size"
            type="line"
            :options="lineChartOptions"
            :series="lineSeries"
            :width="450"
            :height="300"
          />
        </v-layout>
      </div>
    </div>
    <dialog-create-order-option
      :internal-value="isShowCreateOrderOptions"
      :active-package="activePackage"
      :active-packages="activePackages"
      :active-multi-box="activeMultiBox"
      @on-close-dialog="onCloseDialog"
      @go-order-create="goOrderCreate"
      @go-order-create-multiple="goOrderCreateMultiple"
      @go-order-create-multi-box="goOrderCreateMultiBox"
    />
  </div>
</template>

<script>
import EmptyBox from "@/components/EmptyBox.vue";
import DialogCreateOrderOption from "@/components/dialog/DialogCreateOrderOption.vue";
import Piechart from "./components/Piechart.vue";
import { mapActions } from "vuex";
import constants from "@/constants";
import routePaths from "@/router/routePaths";
import moment from "moment";
import { OrderService } from "@/services/orderService";
import { DataBreadCrumbUtils } from "@/helpers/dataBreadCrumbUtils";
import { StringUtils } from "@/helpers/stringUtils";

export default {
  name: "Dashboard",
  components: {
    EmptyBox,
    DialogCreateOrderOption,
    Piechart,
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem("CURRENT_USER")),
      isShowCreateOrderOptions: false,
      activePackage: false,
      activePackages: false,
      activeMultiBox: false,
      series: [],
      listCod: [0, 0, 0, 0, 0],
      totalPieChartData: 0,
      loading: false,

      circleType: constants.typeOfDay.week,
      lstCircleType: [
        {
          name: "Hôm nay",
          value: constants.typeOfDay.today,
        },
        {
          name: "Tuần này",
          value: constants.typeOfDay.week,
        },
        {
          name: "Tháng này",
          value: constants.typeOfDay.month,
        },
        {
          name: "Tùy chọn",
          value: "custom",
        },
        {
          name: "Tất cả",
          value: "all",
        },
      ],
      startTime: moment().clone().startOf("isoWeek"),
      endTime: moment().clone().endOf("isoWeek"),
      dayNow: null,
      circleStartTime: null,
      circleEndTime: null,
      currentMonth: null,
      today: null,
      currentYear: null,
      isDisabledButtonNextWeek: false,

      menuDateFromTo: false,
      datesPicker: [],
      datesPickerFm: [],
      errorDateFromTo: null,

      lineType: constants.typeOfDay.week,
      lstLineType: [
        {
          name: "Theo tuần",
          value: constants.typeOfDay.week,
        },
        {
          name: "Theo năm",
          value: constants.typeOfDay.year,
        },
      ],
      lineSeriesInit: [
        {
          name: "Đơn hàng",
          type: "column",
          data: [],
        },
        {
          name: "COD",
          type: "line",
          data: [],
        },
      ],
      lineSeries: [],
      lineChartOptions: {
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        colors: ["#0F90E8", "#ED893E"],
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: "50%",
          },
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          show: false,
        },
        dataLabels: {
          enabled: false,
          // enabledOnSeries: [1],
        },
        labels: [],
        yaxis: [
          {
            title: {
              show: false,
            },
          },
          {
            opposite: true,
            title: {
              show: false,
            },
          },
        ],
        legend: {
          show: false,
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          x: {
            show: false,
          },
        },
      },
      lineStartTime: null,
      lineEndTime: null,
      lineYear: new Date().getFullYear(),
      yearLineLabels: [
        "T1",
        "T2",
        "T3",
        "T4",
        "T5",
        "T6",
        "T7",
        "T8",
        "T9",
        "T10",
        "T11",
        "T12",
      ],
      weekLineLabels: [
        "Thứ 2",
        "Thứ 3",
        "Thứ 4",
        "Thứ 5",
        "Thứ 6",
        "Thứ 7",
        "CN",
      ],
      yearLineDataList: [
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 1,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 2,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 3,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 4,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 5,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 6,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 7,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 8,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 9,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 10,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 11,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 12,
          status: null,
        },
      ],
      weekLineDataList: [
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 2,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 3,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 4,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 5,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 6,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 7,
          status: null,
        },
        {
          amount: 0,
          codValue: 0,
          createdDate: null,
          dayOrMonth: 8,
          status: null,
        },
      ],
    };
  },
  computed: {
    localDate() {
      return this.$i18n.locale === "vi" ? "vi-VN" : "en-US";
    },
    dateRangeText() {
      return this.datesPickerFm.join(" - ");
    },
    chartOptions: function() {
      const listCod = this.listCod;
      return {
        labels: [
          this.$t("have_not_received"),
          this.$t("delivering"),
          this.$t("success_delivery"),
          this.$t("refund_form"),
          this.$t("cancelled"),
        ],
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        chart: {
          type: "donut",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              switch (config.dataPointIndex) {
                case 0:
                  this.goOrderList(this.$t("have_not_received"));
                  break;
                case 1:
                  this.goOrderList(this.$t("delivering"));
                  break;
                case 2:
                  this.goOrderList(this.$t("success_delivery"));
                  break;
                case 3:
                  this.goOrderList(this.$t("refund_form"));
                  break;
                case 4:
                  this.goOrderList(this.$t("cancelled"));
                  break;
                default:
                  break;
              }
            },
          },
        },
        colors: ["#8E8EA1", "#ACE647", "#0F90E8", "#FF4A55", "#ED893E"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 500,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        tooltip: {
          enabled: true,
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            let total = 0;
            series.forEach((item) => {
              total += item;
            });
            return (
              '<div class="d-flex flex-column py-2 px-8" style="background-color: #343743; color: #ffffff">' +
              "<span>" +
              w.globals.labels[seriesIndex] +
              ": " +
              "<span class='font-weight-bold'>" +
              series[seriesIndex] +
              "</span>" +
              " (" +
              ((series[seriesIndex] / total) * 100).toFixed(1) +
              "%)" +
              "</span>" +
              "<span class='text-center'>" +
              "COD: " +
              "<span class='font-weight-bold'>" +
              listCod[seriesIndex] +
              "</span>" +
              " VND" +
              "</span>" +
              "</div>"
            );
          },
        },
      };
    },
  },
  watch: {
    datesPicker(val) {
      if (val.length === 1) {
        this.startDateSearch = val[0];
      } else {
        this.startDateSearch = null;
      }

      // validate dates
      this.errorDateFromTo = "";
      if (
        this.datesPicker[0] !== undefined &&
        this.datesPicker[1] !== undefined
      ) {
        const selectDate = moment(this.datesPicker[1], constants.formatDateYmd);
        const timeExprired = moment(
          this.datesPicker[0],
          constants.formatDateYmd
        );
        this.createDateFrom = this.datesPicker[0];
        this.createDateTo = this.datesPicker[1];
        const diff = selectDate.diff(timeExprired, "days");
        if (diff < 0) {
          this.errorDateFromTo = "Error 1";
        } else if (diff > 365) {
          this.errorDateFromTo = "Error 2";
        } else {
          this.errorDateFromTo = "";
        }
      }
      this.datesPickerFm = [];
      if (val.length > 0) {
        val.forEach((item) => {
          const newItem = this.formatDate(item, constants.formatDateMonth);
          this.datesPickerFm.push(newItem);
        });
      }
    },
    lineEndTime() {
      this.isDisabledButtonNextWeek = false;
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentDay = today.getDate();
      const lineEndMonth = moment(
        this.lineEndTime,
        constants.formatDatePlaceholder
      ).format("MM");
      const lineEndDay = moment(
        this.lineEndTime,
        constants.formatDatePlaceholder
      ).format("DD");
      if (
        (lineEndDay > currentDay && lineEndMonth > currentMonth) ||
        lineEndMonth > currentMonth
      ) {
        this.isDisabledButtonNextWeek = true;
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions("layout", ["setOrderCreate", "setBreadcrumbs"]),
    async initData() {
      this.initDate();
      this.getCircleStatisticData();
      this.getLineStatisticData();
    },
    initDate() {
      var today = new Date();
      this.currentYear = today.getFullYear();
      this.today =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      this.circleStartTime = this.startTime
        ? this.convertDateFormat(this.startTime)
        : null;
      this.circleEndTime = this.endTime
        ? this.convertDateFormat(this.endTime)
        : null;
      this.lineStartTime = this.startTime
        ? this.convertDateFormat(this.startTime)
        : null;
      this.lineEndTime = this.endTime
        ? this.convertDateFormat(this.endTime)
        : null;
    },
    onShowDialogCreateOrderOption() {
      this.isShowCreateOrderOptions = true;
    },
    onCloseDialog() {
      this.isShowCreateOrderOptions = false;
    },
    goOrderCreate() {
      this.activePackage = true;
      this.activePackages = false;
      this.activeMultiBox = false
      this.isShowCreateOrderOptions = false;
      const pathName = StringUtils.getPathNameByPath(
        this.$router.currentRoute.path
      );
      if (pathName !== routePaths.ORDER_CREATE) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE);
        this.setOrderCreate(true);
        this.$router.push(routePaths.ORDER_CREATE);
      }
    },
    goOrderCreateMultiple() {
      this.activePackage = false;
      this.activePackages = true;
      this.activeMultiBox = false
      this.isShowCreateOrderOptions = false;
      const pathName = StringUtils.getPathNameByPath(
        this.$router.currentRoute.path
      );
      if (pathName !== routePaths.ORDER_CREATE_MULTIPE) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE_MULTIPE);
        this.setOrderCreate(true);
        this.$router.push(routePaths.ORDER_CREATE_MULTIPE);
      }
    },
    goOrderCreateMultiBox() {
      this.activePackage = false
      this.activePackages = false
      this.activeMultiBox = true
      this.isShowCreateOrderOptions = false
      const pathName = StringUtils.getPathNameByPath(this.$router.currentRoute.path)
      if ((pathName !== routePaths.ORDER_CREATE_MULTI_BOX)) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE_MULTI_BOX)
        this.setOrderCreate(true)
        this.$router.push(routePaths.ORDER_CREATE_MULTI_BOX)
      }
    },
    allowedDates(val) {
      if (this.startDateSearch) {
        return val >= this.startDateSearch && val <= this.today;
      } else {
        return val <= this.today;
      }
    },
    onInputDateRange(val) {
      if (!val) {
        this.datesPicker = [];
      }
    },
    onChangeDates() {
      if (this.datesPicker.length === 2) {
        this.$refs.menuDateFromTo.save(this.datesPicker);
        this.circleStartTime = moment(this.datesPicker[0]).format(
          constants.formatDatePlaceholder
        );
        this.circleEndTime = moment(this.datesPicker[1]).format(
          constants.formatDatePlaceholder
        );
        this.getCircleStatisticData();
      }
    },
    changeTypeCalendar() {
      this.clearDate();
      if (this.circleType === constants.typeOfDay.today) {
        this.dayNow = moment().format(constants.formatDatePlaceholder);
        this.circleStartTime = this.dayNow;
        this.circleEndTime = this.dayNow;
        this.getCircleStatisticData();
      }
      if (this.circleType === constants.typeOfDay.week) {
        this.circleStartTime = this.convertDateFormat(this.startTime);
        this.circleEndTime = this.convertDateFormat(this.endTime);
        this.getCircleStatisticData();
      }
      if (this.circleType === constants.typeOfDay.month) {
        this.circleStartTime = moment()
          .clone()
          .startOf("month")
          .format(constants.formatDatePlaceholder);
        this.currentMonth = moment(
          this.circleStartTime,
          constants.formatDatePlaceholder
        ).format("MM/YYYY");
        this.circleEndTime = moment()
          .clone()
          .endOf("month")
          .format(constants.formatDatePlaceholder);
        this.getCircleStatisticData();
      }
      if (this.circleType === "all") {
        this.circleStartTime = null;
        this.circleEndTime = null;
        this.getCircleStatisticData();
      }
    },
    changeTypeLineCalendar() {
      if (this.lineType === constants.typeOfDay.week) {
        this.lineStartTime = this.convertDateFormat(this.startTime);
        this.lineEndTime = this.convertDateFormat(this.endTime);
        this.getLineStatisticData();
      }
      if (this.lineType === constants.typeOfDay.year) {
        this.lineYear = new Date().getFullYear();
        this.lineStartTime = `01/01/${this.lineYear}`;
        this.lineEndTime = `31/12/${this.lineYear}`;
        this.getLineStatisticData();
      }
    },
    previousCalendar() {
      if (this.lineType === constants.typeOfDay.week) {
        const previousWeek = moment(
          this.lineStartTime,
          constants.formatDatePlaceholder
        )
          .subtract(1, "days")
          .format(constants.formatDatePlaceholder);
        this.lineStartTime = moment(
          previousWeek,
          constants.formatDatePlaceholder
        )
          .clone()
          .startOf("isoWeek")
          .format(constants.formatDatePlaceholder);
        this.lineEndTime = moment(previousWeek, constants.formatDatePlaceholder)
          .clone()
          .endOf("isoWeek")
          .format(constants.formatDatePlaceholder);
        this.getLineStatisticData();
      }
      if (this.lineType === constants.typeOfDay.year) {
        this.lineYear = this.lineYear - 1;
        this.lineStartTime = `01/01/${this.lineYear}`;
        this.lineEndTime = `31/12/${this.lineYear}`;
        this.getLineStatisticData();
      }
    },
    nextCalendar() {
      if (this.lineType === constants.typeOfDay.week) {
        const nextWeek = moment(
          this.lineEndTime,
          constants.formatDatePlaceholder
        )
          .subtract(-1, "days")
          .format(constants.formatDatePlaceholder);
        this.lineStartTime = moment(nextWeek, constants.formatDatePlaceholder)
          .clone()
          .startOf("isoWeek")
          .format(constants.formatDatePlaceholder);
        this.lineEndTime = moment(nextWeek, constants.formatDatePlaceholder)
          .clone()
          .endOf("isoWeek")
          .format(constants.formatDatePlaceholder);
        this.getLineStatisticData();
      }
      if (this.lineType === constants.typeOfDay.year) {
        this.lineYear = this.lineYear + 1;
        this.lineStartTime = `01/01/${this.lineYear}`;
        this.lineEndTime = `31/12/${this.lineYear}`;
        this.getLineStatisticData();
      }
    },
    clearCircleData() {
      this.listCod = [0, 0, 0, 0, 0];
      this.series = [];
      this.totalPieChartData = 0;
    },
    clearDate() {
      this.datesPicker = [];
    },
    async getCircleStatisticData() {
      this.clearCircleData();
      const pars = {
        custId: this.currentUser.id,
        fromDate: this.circleStartTime
          ? moment(this.circleStartTime, constants.formatDatePlaceholder).format("YYYY-MM-DD")
          : null,
        toDate: this.circleEndTime
          ? moment(this.circleEndTime, constants.formatDatePlaceholder).format("YYYY-MM-DD")
          : null,
      };
      const { status, data } = await OrderService.getCircleStatistic(pars);
      if (status === constants.statusCode.ok && data && data.data.length) {
        this.totalPieChartData = data.totalRecord;
        data.data.forEach((item) => {
          if (item.status === this.$t("have_not_received")) {
            this.listCod[0] = this.convertPrice(item.cod);
          }
          if (item.status === this.$t("delivering")) {
            this.listCod[1] = this.convertPrice(item.cod);
          }
          if (item.status === this.$t("success_delivery")) {
            this.listCod[2] = this.convertPrice(item.cod);
          }
          if (item.status === this.$t("refund_form")) {
            this.listCod[3] = this.convertPrice(item.cod);
          }
          if (item.status === this.$t("cancelled")) {
            this.listCod[4] = this.convertPrice(item.cod);
          }
        });
        this.chartOptions.labels.forEach((item) => {
          var findItem = data.data.find((it) => it.status === item);
          if (findItem !== undefined) {
            this.series.push(findItem.amount);
          } else {
            this.series.push(0);
          }
        });
      } else {
        this.series = [0, 0, 0, 0, 0];
      }
    },
    clearLineData() {
      this.lineSeriesInit = [
        {
          name: "Đơn hàng",
          type: "column",
          data: [],
        },
        {
          name: "COD",
          type: "line",
          data: [],
        },
      ];
      this.lineSeries = [];
      this.lineChartOptions.labels = [];
    },
    async getLineStatisticData() {
      this.clearLineData();
      const pars = {
        custId: this.currentUser.id,
        timeType: this.lineType,
        fromDate: this.lineStartTime
          ? moment(this.lineStartTime, constants.formatDatePlaceholder).format("YYYY-MM-DD")
          : null,
        toDate: this.lineEndTime
          ? moment(this.lineEndTime, constants.formatDatePlaceholder).format("YYYY-MM-DD")
          : null,
      };
      const { status, data } = await OrderService.getLineStatistic(pars);
      if (status === constants.statusCode.ok && data.length) {
        if (this.lineType === constants.typeOfDay.year) {
          var yearOptions = { ...this.lineChartOptions };
          this.yearLineLabels.forEach((item) => {
            yearOptions.labels.push(item);
          });
          data.forEach((item) => {
            this.yearLineDataList.forEach((it) => {
              if (it.dayOrMonth === item.dayOrMonth && item.dayOrMonth) {
                it.amount = item.amount;
                it.codValue = item.codValue;
              }
            });
          });
          this.lineSeriesInit.forEach((item) => {
            this.yearLineDataList.forEach((it) => {
              if (item.type === "column") {
                item.data.push(it.amount);
              }
              if (item.type === "line") {
                item.data.push(it.codValue);
              }
            });
            this.lineSeries.push(item);
          });
          // this.$refs.lineChart.updateOptions(yearOptions);
        }
        if (this.lineType === constants.typeOfDay.week) {
          var weekOptions = { ...this.lineChartOptions };
          this.weekLineLabels.forEach((item) => {
            weekOptions.labels.push(item);
          });
          data.forEach((item) => {
            this.weekLineDataList.forEach((it) => {
              if (it.dayOrMonth === item.dayOrMonth && item.dayOrMonth) {
                it.amount = item.amount;
                it.codValue = item.codValue;
              }
            });
          });
          this.lineSeriesInit.forEach((item) => {
            this.weekLineDataList.forEach((it) => {
              if (item.type === "column") {
                item.data.push(it.amount);
              }
              if (item.type === "line") {
                item.data.push(it.codValue);
              }
            });
            this.lineSeries.push(item);
          });
          // this.$refs.lineChart.updateOptions(weekOptions);
        }
      } else {
        this.lineSeries = [];
      }
    },
    convertDateFormat(val) {
      return moment(val, constants.formatDatePlaceholder).format(
        constants.formatDatePlaceholder
      );
    },
    convertDateMonthFormat(val) {
      return moment(val, constants.formatDatePlaceholder).format(
        constants.formatDateMonth
      );
    },
    convertPrice(val) {
      if (val !== 0 && val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    formatDate(strDate, format) {
      if (typeof strDate === "undefined") return "";
      return moment.utc(strDate).format(format);
    },
    calculatePercentage(val) {
      return ((val / this.totalPieChartData) * 100).toFixed(1);
    },
    goOrderList(type) {
      this.addBreadCrumbs(routePaths.ORDER_LIST);
      this.$router.push({
        path: routePaths.ORDER_LIST,
        query: {
          type: type,
          fromDate: this.circleStartTime,
          toDate: this.circleEndTime,
        },
      });
    },
    addBreadCrumbs(pathActive) {
      const breadCrumbs =
        DataBreadCrumbUtils.getBreadCrumbByPathActive(pathActive);
      this.setBreadcrumbs(breadCrumbs);
    },
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  margin: -16px -12px;
  background: #e5e5e5;
  flex: 1;
  display: flex;
  flex-direction: column;
  .description-section {
    background-color: #0f90e8;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
  .wrap-chart {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .left-chart,
    .right-chart {
      flex: 1;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 20px 70px rgba(86, 89, 146, 0.1);
      border-radius: 8px;
      height: 100%;
      min-width: 450px;
    }
  }
}
@media (max-width: 1280px) {
  .title-chart {
    font-size: 18px;
  }
  .text-date {
    font-size: 12px;
  }
}
@media (max-width: 430px) {
  .title-chart {
    font-size: 14px;
  }
  .text-date {
    font-size: 12px;
  }
.wrap-chart {
  .left-chart {
    .position-left-side {
      display: contents !important;
      .pos-rel {
        transform: scale(0.75) !important;
        left: -55px;
      }
    }
  }
  .right-chart {
    .pie-dounut-size {
      transform: scale(0.75) !important;
    }
  }
}
}
@media (max-width: 450px) {
  .left-chart,
  .right-chart {
    min-width: -webkit-fill-available !important;
  }
}
</style>
