import constants from "@/constants";
import { DateTimeUtils } from "@/helpers/dateTimeUtils";
import { StringUtils } from "@/helpers/stringUtils";
import { FormUtils } from "@/helpers/formUtils";
import { UserService } from "@/services/userService";
import moment from "moment";
import { OrderService } from "@/services/orderService";
import routePaths from "@/router/routePaths";
import constPickupTime from "./constPickupTime";
import { StorageService } from "@/services/storageService";
import { CommonService } from "@/services/commonService";
import { getTextTruncate } from "@/helpers/stringUtils.js"
var mixinHandleOrderCreate = {
  props: {
    detailData: {
      type: Object,
      default: undefined
    },
    lstDistrict: {
      type: Array,
      default: undefined
    },
    districtDisabled: {
      type: Boolean,
      default: true,
    },
    lstCommune: {
      type: Array,
      default: undefined
    },
    communeDisabled: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      transportTypeConst: constants.transportType,
      transportType: constants.transportType.ship,
      isShowPickUpDateShipping: true,

      menuPickUpDate: false,
      pickUpDate: null,
      pickUpDateFm: null,
      formatDatePlaceholder: constants.formatDatePlaceholder,
      minAllowedDate: "",
      maxAllowedDate: "",

      timePicker: null,
      timePickerList: [constPickupTime.time1],
      timePickerDisabled: true,

      itemAddressDefault: null,
      itemAddressSelected: null,

      phone: "",
      maximumPhone: constants.maximumPhone,
      phoneRules: {
        required: true,
        regex: constants.regExp.phone,
      },

      fullName: "",
      maximumSmallText: constants.maximumSmallText,

      provinceOrderCodeSelected: null,
      provinceOrderNameSelected: null,
      lstDistrictOrder: [],
      districtOrderSelected: null,
      districtOrderSelectedName: null,
      districtDisabledOrder: true,
      lstCommuneOrder: [],
      communeOrderSelected: null,
      communeOrderSelectedName: null,
      communeDisabledOrder: true,

      customAddress: "",
      productDescription: "",
      maximumMediumText: constants.maximumMediumText,
      length: "10",
      maximumSizeLwH: constants.maximumSizeLwHFm,
      maximumSizeLWH: constants.maximumSizeLwH,
      width: "10",
      height: "10",
      orderWeight: "",
      maximumOrderWeight: constants.maximumOrderWeight,
      orderWeightRules: {
        required: true,
        regex: constants.regExp.not_zero,
      },
      orderValueRules: {
        required: true,
        regex: constants.regExp.not_zero,
      },

      cod: "",
      codValueError: "",
      codValueHideError: false,
      maximumPriceFm: constants.maximumPriceValueFm,
      isShowTextBoxTypeOrderValue: false,
      insurance: constants.orderInsurance.no,
      orderInsuranceConst: constants.orderInsurance,
      orderValue: "",
      editOrderDetail: {},
      editRowDataId: null,
      editProvinceOrderSelected: null,
      orderValueError: "",
      orderValueHideError: false,
      orderWeightError: "",
      orderWeightHideError: false,
      receiverPhoneError: "",
      receiverPhoneHideError: false,
      wayBillRequire: "",
      statusError: false,
      minimumWaybill: constants.minimumWaybill,
      maximumWaybill: constants.maximumWaybill,
      waybillRules: {
        min: constants.minimumWaybill,
        max: constants.maximumWaybill,
        regex: constants.regExp.waybill,
      },
      waybillPrefix: constants.waybillPrefix,

      logisticServiceList: [{ name: "Tiêu chuẩn", value: 1 }],
      logisticService: null,
      serviceFeePayer: constants.serviceFeePayers.sender,
      serviceFeePayersConst: constants.serviceFeePayers,
      deliveryNoteSelected: "",
      lstDeliveryNote: [],
      transportFee: "0",
      insuranceFee: "0",
      totalFee: "0",
      totalRevenue: "0",
      totalService: "0",
      totalServiceFee: "0",

      showConfirmResetForm: false,
      textAcceptConfirmResetForm: "",
      message2: "",
      currentUser: JSON.parse(localStorage.getItem("CURRENT_USER")),
      menuPhone: false,
      listReceiver: [],
      lstProvince: [],
      listAllDistricts: [],
      listAllCommunes: [],
      receiverTimeout: null,
      fillDataFromChoosePhone: false,
      items: [],
      originItems: [],
      excelData: [],
      errorItems: [],
      showEditOrder: false,
      invalidOrderSelected: null,
      showConfirmDel: false,
      deleteItemId: null,
      orderDetailData: {
        editProvinceOrderSelected: null,
        editDistrictOrderSelected: null,
        editCommuneOrderSelected: null,
        receiverPhone: null,
        receiverName: null,
        receiverAddress: null,
        noteCode: null,
        description: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        codValue: null,
        isInsurance: null,
        orderValue: null,
        paymentByValue: null,
      },
      trackingIdNull: false,
      receiverNameNull: false,
      receiverPhoneNull: false,
      receiverLocationNull: false,
      receiverAddressNull: false,
      noteNull: false,
      descriptionNull: false,
      sizeNull: false,
      weightNull: false,
      codValueNull: false,
      insuranceValueNull: false,
      paymentByNull: false,
      lstFeeTag: [],
      lstService: [],
      lstPartner: [],
      checkFeeTmpLst: [],
      checkedFeeLst: [],
      partnerSelect: null,
      serviceSelect: null,
      requireNote: false,
      code: null,
      lstServiceUserId: [],
      checkValueCode: [],
      arrList: [],
      feeService: '0',
      valueService: 0,
      initFeeService: 0,
      dataCodeCheckBox: [],
      fee: 0,
      feeSelected: "",
      valueInsuTran: 0,
      arrayListFee: [],
      checkItemFee: [],
      listFeeTagMap: [],
      arrayAdress: [],
      valueServiceIns: 0,
      isDisableInsurance: false,
      ServiceFee: 0,
      showLoading: false,
      disableBtnCreate: false,
      showBack: true,
      listPickWorkShipGhtk: [],
      pickWorkShip: null,
      token: ''
    };
  },
  computed: {
    isShowAddressCreateForOrder() {
      return (
        typeof this.itemAddressSelected === constants.undefined ||
        this.itemAddressSelected === null
      );
    },
    computedEstimateWeightExcel() {
      if (this.detailData.length && this.detailData.width && this.detailData.height) {
        if (this.detailData.partnerSelect === constants.partnerGroup.ninjaVan) {
          const weightTranfer =
            (StringUtils.numStrToInt(this.detailData.length) *
              StringUtils.numStrToInt(this.detailData.width) *
              StringUtils.numStrToInt(this.detailData.height)) /
            6000;
          return StringUtils.formatDecimal(weightTranfer);
        } else {
          if (this.detailData.serviceSelect === constants.servicesShipping.over) {
            const weightTranfer =
              (StringUtils.numStrToInt(this.detailData.length) *
                StringUtils.numStrToInt(this.detailData.width) *
                StringUtils.numStrToInt(this.detailData.height)) /
              4000;
            return StringUtils.formatDecimal(weightTranfer);
          } else {
            const weightTranfer =
              (StringUtils.numStrToInt(this.detailData.length) *
                StringUtils.numStrToInt(this.detailData.width) *
                StringUtils.numStrToInt(this.detailData.height)) /
              6000;
            return StringUtils.formatDecimal(weightTranfer);
          }
        }
      }
      return 0;
    },
    isInvalidOrderWeight() {
      return parseFloat(StringUtils.replaceComma(this.orderWeight)) <= 0;
    },
    isInvalidOrderValue() {
      return (
        parseFloat(StringUtils.replaceComma(this.orderValue)) >
        constants.maximumOrderValue
      );
    },
    isInvalidCodValue() {
      return (
        parseFloat(StringUtils.replaceComma(this.cod)) >
        constants.maximumOrderValue
      );
    },
    isWeightError() {
      if (this.serviceSelect === constants.servicesShipping.over) {
        return parseFloat(StringUtils.replaceComma(this.orderWeight)) < constants.weight
      } else if ((this.serviceSelect === constants.servicesShipping.standard || this.serviceSelect === constants.servicesShipping.express)) {
        return parseFloat(StringUtils.replaceComma(this.orderWeight)) >= constants.weight
      }
    },
    isCheckPhone() {
      if (this.itemAddressSelected !== undefined && this.itemAddressSelected !== null) {
        return this.phone === this.itemAddressSelected.phone
      } else {
        return false;
      }
    },
    isInvalidAction() {
      return (
        this.isInvalidOrderWeight ||
        this.isInvalidOrderValue ||
        this.isInvalidCodValue || this.isWeightError || this.isCheckPhone
      );
    },
  },
  watch: {
    pickUpDate(val) {
      this.pickUpDateFm = this.formatDate(this.pickUpDate);
    },
    orderValue() {
      this.lstFeeTag.forEach((item) => {
        item.isDisableFeeTag = false
        if (item.requireValue && this.orderValue && parseFloat(item.requireValue) <= parseFloat(this.orderValue.replaceAll(",", ""))) {
          item.isDisableFeeTag = true
          if (!this.checkedFeeLst.includes(item.id)) {
            this.checkedFeeLst.push(item.id)
          }
          this.checkCode();
        }
      })
    }
  },
  async created() {
    await this.getAddressDefault(true);
    this.textAcceptConfirmResetForm = this.$t("re_type");
    this.calculateMinMaxAllowedDate();
    this.logisticService = this.logisticServiceList[0];
  },
  methods: {
    computedEstimateWeight(length, width, height) {
      if (length && width && height) {
        if (this.partnerSelect === constants.partnerGroup.ninjaVan) {
          const weightTranfer =
            (StringUtils.numStrToInt(length) *
              StringUtils.numStrToInt(width) *
              StringUtils.numStrToInt(height)) /
            6000;
          return StringUtils.formatDecimal(weightTranfer);
        } else {
          if (this.serviceSelect === constants.servicesShipping.over) {
            const weightTranfer =
              (StringUtils.numStrToInt(length) *
                StringUtils.numStrToInt(width) *
                StringUtils.numStrToInt(height)) /
              4000;
            return StringUtils.formatDecimal(weightTranfer);
          } else {
            const weightTranfer =
              (StringUtils.numStrToInt(length) *
                StringUtils.numStrToInt(width) *
                StringUtils.numStrToInt(height)) /
              6000;
            return StringUtils.formatDecimal(weightTranfer);
          }
        }
      }
      return 0;
    },

    async onOrderCreate() {
      var valid = this.$refs.observer.validate();
      if (valid) {
        this.showLoading = true;
        this.disableBtnCreate = true;
        const pars = this.bindRequestPars();
        const { status, data } = await OrderService.create(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok) {
          this.toggleDialogNoti({
            state: true,
            type: constants.typeAlert.success,
            msg: this.$t("success_order_create"),
          });
          setTimeout(() => {
            this.toggleDialogNoti();
            this.$router.push(routePaths.ORDER_LIST);
          }, 2500);
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true;
        } else {
          let msgNoti = "";
          if (data.message === constants.errorValidation) {
            var jsonObj = data.details;
            var keys = Object.keys(jsonObj);
            for (var i = 0; i < keys.length; i++) {
              if (i < keys.length - 1) {
                msgNoti += jsonObj[keys[i]] + ",";
              } else {
                msgNoti += jsonObj[keys[i]];
              }
            }
          } else {
            msgNoti = data.message;
          }

          this.toggleDialogNoti({ state: true, msg: msgNoti });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, 2500);
          this.disableBtnCreate = false
        }
      }
    },
    bindRequestPars() {
      const pars = {
        custId: this.itemAddressSelected.custId,
        partnerId: this.partnerSelect,
        transportType: this.transportType,
        addressId: this.itemAddressSelected.id,
        receiverName: this.fullName.trim(),
        receiverPhone: this.phone.trim(),
        receiverProvinceCode: parseInt(this.provinceOrderCodeSelected),
        receiverProvinceName: this.provinceOrderNameSelected,
        receiverDistrictCode: parseInt(this.districtOrderSelected),
        receiverDistrictName: this.districtOrderSelectedName,
        receiverCommuneCode: parseInt(this.communeOrderSelected),
        receiverCommuneName: this.communeOrderSelectedName,
        receiverAddress: this.customAddress,
        note: this.deliveryNoteSelected,
        description: this.productDescription.trim(),
        length: StringUtils.numStrToInt(this.length),
        width: StringUtils.numStrToInt(this.width),
        height: StringUtils.numStrToInt(this.height),
        weight: StringUtils.numStrToFloat(this.orderWeight),
        estimateWeight: StringUtils.numStrToFloat(this.computedEstimateWeight(this.length, this.width, this.height)),
        paymentBy: this.serviceFeePayer,
        shipValue: StringUtils.numStrToFloat(this.transportFee),
        totalValue: StringUtils.numStrToFloat(this.totalRevenue),
      };
      const arrTag = this.checkedFeeLst.filter((item, index) => {
        return this.checkedFeeLst.indexOf(item) === index
      })
      pars["serviceId"] = this.serviceSelect
      pars["tags"] = arrTag.toString()
      if (this.wayBillRequire.trim()) {
        pars["deliveryCode"] = this.wayBillRequire.trim();
      }
      if (this.transportType === constants.transportType.ship) {
        pars["pickDate"] = this.formatDateYmd(this.pickUpDate)
        pars["pickWorkShift"] = this.pickWorkShip
      }

      if (this.cod) {
        pars["codValue"] = StringUtils.numStrToFloat(this.cod);
      }
      pars["orderValue"] = !this.orderValue ? StringUtils.numStrToFloat(this.cod) : StringUtils.numStrToFloat(this.orderValue);
      if (this.insurance === this.orderInsuranceConst.yes) {
        pars["orderValue"] = StringUtils.numStrToFloat(this.orderValue);
        pars["insuranceValue"] = StringUtils.numStrToFloat(this.insuranceFee);
      }

      return pars;
    },
    async getListDeliveryNote() {
      this.showLoading = false;
      const { status, data } = await CommonService.getListDeliveryNote();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.lstDeliveryNote = data;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    async getLastTransportType() {
      const { status, data } = await OrderService.getLastTransportType(
        this.currentUser.id
      );
      if (status === constants.statusCode.ok && data) {
        this.transportType = data;
        if (this.transportType === constants.transportType.individual) {
          this.isShowPickUpDateShipping = false;
        } else {
          this.isShowPickUpDateShipping = true;
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    parsePickupTimeFrom() {
      const timeFrom = this.timePicker?.from;
      return `${this.pickUpDate}T${timeFrom}`;
    },
    parsePickupTimeTo() {
      const timeTo = this.timePicker.to;
      return `${this.pickUpDate}T${timeTo}`;
    },
    async getAddressDefault(isBindSelected) {
      this.showLoading = false;
      const { status, data } = await UserService.addressGetDefault();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data !== null) {
        this.itemAddressDefault = data;
        if (isBindSelected) {
          this.itemAddressSelected = data;
          this.calculateShipFee();
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    formatDate(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate);
    },
    formatDateYmd(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDateDmy);
    },
    parseDate(date) {
      return DateTimeUtils.parseDate(date);
    },
    async getShops() {
      const pars = {
        poId: null
      }
      const { status, data } = await UserService.getShops(pars);
      if (status === constants.statusCode.ok && data) {
        this.listShop = data;
        this.listShopInit = data;
      }
    },
    async getAllPickWorkShip() {
      const { status, data } = await OrderService.getAllPickWorkShip();
      if (status === constants.statusCode.ok && data) {
        this.listPickWorkShipGhtk = data;
      }
    },
    searchShopOrder(val) {
      this.listShop = this.listShopInit.filter((item) =>
        item.unsignedName
          .toLowerCase()
          .includes(this.removeVietnameseTones(val).toLowerCase())
      );
    },
    async onChangeTransportType(val) {
      this.isShowPickUpDateShipping = Boolean(val === constants.transportType.ship)
      if (val === constants.transportType.ship) {
        this.calculateMinMaxAllowedDate();
      }
    },
    calculateMinMaxAllowedDate() {
      const currDate = moment().format(constants.formatDateYmd);
      const hours = moment().format("HH");
      if (hours < constants.limitHourOrderCreate) {
        this.minAllowedDate = currDate;
      } else {
        var nextDate = DateTimeUtils.getTomorrow();
        if (this.isDayOff(nextDate) && this.partnerSelect === constants.partnerGroup.ninjaVan) {
          nextDate = DateTimeUtils.getNextDateByAddDay(nextDate, 1);
        } else {
          nextDate = DateTimeUtils.getNextDateByAddDay(nextDate, 0);
        }

        this.minAllowedDate = nextDate;
      }
      this.maxAllowedDate = this.getMaxAllowedDate(this.minAllowedDate);
    },
    getMaxAllowedDate(minDate) {
      var dayOfWeek = moment(minDate).day();
      var dayByPartner = null
      if (this.partnerSelect === constants.partnerGroup.ninjaVan) {
        dayByPartner = 7 - dayOfWeek > 2
      } else {
        dayByPartner = 7 - dayOfWeek >= 2
      }
      if (dayByPartner) {
        return DateTimeUtils.getNextDateByAddDay(
          minDate,
          constants.numberDateAllowedOrderCreate
        );
      } else {
        return DateTimeUtils.getNextDateByAddDay(
          minDate,
          this.partnerSelect === constants.partnerGroup.ninjaVan ? constants.numberDateAllowedOrderCreate + 1 : constants.numberDateAllowedOrderCreate
        );
      }
    },
    allowedDates(date) {
      return !this.isDayOff(date);
    },
    isDayOff(date) {
      const dateCompare = new Date(date).getDay();
      return dateCompare === 0;
    },
    onInputPickUpDate(val) {
      this.menuPickUpDate = false;
      this.timePickerDisabled = false;
    },
    isToDay(val) {
      var inputDate = new Date(val);
      // Get today's date
      var todaysDate = new Date();
      return inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0);
    },
    isOrderWeight(event) {
      return FormUtils.isDecimal(event);
    },
    async onChangeProvinceOrder(val) {
      if (parseInt(this.provinceOrderCodeSelected) !== val) {
        this.provinceOrderCodeSelected = val;
        if (this.lstProvince.length) {
          this.lstProvince.forEach((item) => {
            if (item.code === val) {
              this.provinceOrderNameSelected = item.fullName;
            }
          });
        }
        if (this.isEdit === true) {
          this.clearDistrictExcel()
          this.clearCommuneExcel()
        } else {
          this.clearDistrict();
          this.clearCommune();
        }
        this.fillDataFromChoosePhone = false;
        await Promise.all([this.getDistrictByProvinceCodeOrder(val), this.calculateShipFee()]);
        this.$refs.inputProvinceOrder.blur();
        this.$refs.inputDistrictOrder.focus();
      }
    },
    clearDistrictExcel() {
      this.$refs.observer.refs.districtOrder.reset();
      this.lstDistrictOrder = [];
      this.detailData.editDistrictOrderSelected = null
    },
    clearCommuneExcel() {
      this.detailData.editCommuneOrderSelected = null
      this.$refs.observer.refs.communeOrder.reset();
      this.lstCommuneOrder = [];
      this.communeDisableds = true;
    },
    clearDistrict() {
      this.districtOrderSelected = null;
      this.districtOrder ? this.$refs.observer.refs.districtOrder.reset() : '';
      this.lstDistrictOrder = [];
      this.districtDisabledOrder = true;
    },
    clearCommune() {
      this.communeOrderSelected = null;
      this.communeOrder ? this.$refs.observer.refs.communeOrder.reset() : '';
      this.lstCommuneOrder = [];
      this.communeDisabledOrder = true;
    },
    // list quan/huyen
    async getDistrictByProvinceCodeOrder(code) {
      this.showLoading = false;
      const pars = {
        provinceCode: code,
      };
      const { data } = await UserService.findDistrictByProvinceCode(pars);
      this.showLoading = false;
      if (data != null && data.length > 0 && !this.fillDataFromChoosePhone) {
        this.lstDistrictOrder = data;
        this.lstDistricts = data;
        this.districtDisableds = false;
        this.districtDisabledOrder = false;
      } else if (this.provinceOrderCodeSelected && this.fillDataFromChoosePhone) {
        this.lstDistrictOrder = data;
        this.lstDistricts = data;
        this.lstDistrictOrder.forEach((item) => {
          if (item.fullName === this.districtOrderSelectedName) {
            this.districtOrderSelected = item.code;
            this.districtOrderSelectedName = item.name;
            this.districtDisabledOrder = false;
            this.getCommuneByDistrictCodeOrder(this.districtOrderSelected);
          }
        });
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    async onChangeDistrictOrder(val) {
      this.districtOrderSelected = val;
      if (this.lstDistrictOrder.length) {
        this.lstDistrictOrder.forEach((item) => {
          if (item.code === val) {
            this.districtOrderSelectedName = item.fullName;
          }
        });
      }
      this.clearCommune();
      this.fillDataFromChoosePhone = false;
      await this.getCommuneByDistrictCodeOrder(val);
      this.$refs.inputDistrictOrder.blur();
      this.$refs.inputCommuneOrder.focus();
    },
    async onChangeCommuneOrder(val) {
      if (this.lstCommuneOrder.length) {
        this.lstCommuneOrder.forEach((item) => {
          if (item.code === val) {
            this.communeOrderSelectedName = item.fullName;
          }
        });
      }
    },
    // list phuong/xa
    async getCommuneByDistrictCodeOrder(code) {
      this.showLoading = false;
      const pars = {
        districtCode: code,
      };
      const { data } = await UserService.findCommuneByDistrictCode(pars);
      this.showLoading = false;
      if (data != null && data.length > 0 && !this.fillDataFromChoosePhone) {
        this.lstCommuneOrder = data;
        this.lstCommunes = data;
        this.communeDisableds = false;
        this.communeDisabledOrder = false;
      } else if (this.districtOrderSelected && this.fillDataFromChoosePhone) {
        this.lstCommuneOrder = data;
        this.lstCommuneOrder.forEach((item) => {
          if (item.fullName === this.communeOrderSelectedName) {
            this.communeOrderSelected = item.code;
            this.communeOrderSelectedName = item.name;
            this.communeDisabledOrder = false;
          }
        });
      }
    },
    onPastePhone(evt) {
      const val = FormUtils.isOnPasteOnlyNumber(evt);
      if (val !== null) {
        this.phone = val;
        this.inputPhone(this.phone);
      }
    },
    async inputPhone(val) {
      val = val + "";
      if (this.itemAddressSelected !== undefined && this.itemAddressSelected !== null) {
        if (val === this.itemAddressSelected.phone) {
          const msg = this.$t("duplicate_phone_sender_receiver");
          this.receiverPhoneError = msg
          this.receiverPhoneHideError = true;
        } else {
          this.receiverPhoneError = ''
          this.receiverPhoneHideError = false;
        }
      } else {
        this.receiverPhoneError = ''
        this.receiverPhoneHideError = false;
      }
      if (val.length > 3) {
        clearTimeout(this.receiverTimeout);
        this.receiverTimeout = setTimeout(async() => {
          const pars = {
            custId: this.itemAddressSelected.custId,
            phone: val,
          };
          const { status, data } = await OrderService.getReceiverInfoByPhone(
            pars
          );
          if (status === constants.statusCode.ok && data) {
            this.menuPhone = true;
            this.listReceiver = data;
          }
        }, 1000);
      } else {
        this.listReceiver = [];
      }
    },
    async choosePhone(val) {
      this.fillDataFromChoosePhone = true;
      this.phone = val;
      if (this.phone === this.itemAddressSelected.phone) {
        const msg = this.$t("duplicate_phone_sender_receiver");
        this.receiverPhoneError = msg
        this.receiverPhoneHideError = true;
      } else {
        this.receiverPhoneError = ''
        this.receiverPhoneHideError = false;
      }
      this.listReceiver.forEach((item) => {
        if (item.phone === val) {
          this.fullName = item.name;
          this.provinceOrderCodeSelected = item.provinceCode;
          this.provinceOrderNameSelected = item.provinceName;
          this.districtOrderSelectedName = item.districtName;
          this.communeOrderSelectedName = item.communeName;
          this.customAddress = item.address;
        }
      });
      await this.getDistrictByProvinceCodeOrder(this.provinceOrderCodeSelected);
    },
    resetLocationData() {
      this.editOrderDetail.editDistrictOrderSelected = null;
      this.editOrderDetail.editCommuneOrderSelected = null;
      this.editOrderDetail.editProvinceOrderSelected = null;
      this.districtDisabledOrder = true;
      this.communeDisabledOrder = true;
    },
    // sửa order tạo exel
    async editOrder(id, index) {
      this.resetLocationData();
      this.editRowDataId = index;
      this.editOrderDetail = this.items[index];
      for (var key in this.editOrderDetail) {
        this.orderDetailData[key] = this.editOrderDetail[key];
      }
      this.orderDetailData.noteCode = this.editOrderDetail.noteCode ? this.editOrderDetail.noteCode : null;
      this.orderDetailData.length = (this.editOrderDetail.length !== "-" && this.editOrderDetail.length.length < 6) || this.editOrderDetail.length !== "" ? this.editOrderDetail.length : null;
      this.orderDetailData.width = (this.editOrderDetail.width !== "-" && this.editOrderDetail.width.length < 6) || this.editOrderDetail.width !== "" ? this.editOrderDetail.width : null;
      this.orderDetailData.height = (this.editOrderDetail.height !== "-" && this.editOrderDetail.height.length < 6) || this.editOrderDetail.height !== "" ? this.editOrderDetail.height : null;
      this.orderDetailData.weight = this.editOrderDetail.weight !== "-" ? this.editOrderDetail.weight : null;
      this.orderDetailData.codValue = this.editOrderDetail.codValue !== "-" ? this.editOrderDetail.codValue : null;
      this.orderDetailData.orderValue = this.editOrderDetail.orderValue !== "-" ? this.editOrderDetail.orderValue : null;
      this.orderDetailData.editProvinceOrderSelected = this.editOrderDetail.receiverProvinceCode;
      const editDistrictOrderSelected = this.editOrderDetail.receiverDistrictName;
      const editCommuneOrderSelected = this.editOrderDetail.receiverCommuneName;
      this.showEditOrder = true;
      if (this.orderDetailData.editProvinceOrderSelected) {
        await this.getDistrictByProvinceCodeOrder(
          this.orderDetailData.editProvinceOrderSelected
        );
        if (editDistrictOrderSelected) {
          this.lstDistrictOrder.forEach(async(item) => {
            if (
              item.name === editDistrictOrderSelected
            ) {
              this.orderDetailData.editDistrictOrderSelected = item.code;
              this.districtDisabledOrder = false;
              await this.getCommuneByDistrictCodeOrder(
                this.orderDetailData.editDistrictOrderSelected
              );
              this.communeDisabledOrder = false;
              if (editCommuneOrderSelected) {
                this.lstCommuneOrder.forEach((it) => {
                  if (it.fullName === editCommuneOrderSelected) {
                    this.orderDetailData.editCommuneOrderSelected = it.code;
                  }
                });
              } else {
                this.orderDetailData.editCommuneOrderSelected = "";
              }
            }
          });
        } else {
          this.orderDetailData.editDistrictOrderSelected = "";
          this.orderDetailData.editCommuneOrderSelected = "";
        }
      } else {
        this.orderDetailData.editDistrictOrderSelected = "";
        this.orderDetailData.editCommuneOrderSelected = "";
      }
    },
    replaceDistrictName(val) {
      val = this.removeVietnameseTones(val);
      val = val.toLowerCase().replace("quan ", "");
      val = val.toLowerCase().replace("huyen ", "");
      val = val.toLowerCase().replace("thanh pho ", "");
      val = val.toLowerCase().replace("thi xa ", "");
      val = val.trim().replaceAll(" ", "_");
      return val;
    },
    deleteOrder(id) {
      this.deleteItemId = id;
      this.message = this.$t("confirm_delete_this_order");
      this.typeNoti = constants.typeAlert.warning;
      this.showConfirmDel = true;
    },
    onCancelConfirmDel() {
      this.showConfirmDel = false;
      this.message = '';
    },
    onAcceptConfirmDel() {
      this.showConfirmDel = false;
      if (this.deleteItemId !== undefined || this.deleteItemId !== null) {
        this.items = this.items.filter((item) => item.id !== this.deleteItemId);
        this.excelData = this.excelData.filter((item) => item.id !== this.deleteItemId);
        this.checkInvalidOrder()
        const isValidatedAll = this.isDataValidatedAll(this.excelData);
        if (this.excelData.length > 0 && this.lstItemCreate.length === 0 && this.errorItems.length === 0) {
          this.items = this.excelData
          this.invalidOrderSelected = false;
          if (isValidatedAll) {
            this.calculateShipFeeExcel();
          }
        }
        const checkStatus = this.items.filter(item => item.status === -1)
        if (checkStatus.length === 0 && this.lstItemCreate.length) {
          this.showBack = false;
          this.disableBtnCreate = true
        } else {
          this.showBack = true;
          this.disableBtnCreate = false
        }
      }
    },
    clearValidateVariable(item) {
      item.isTrackingIdDuplicated = false;
      item.trackingIdError = false;
      item.receiverNameError = false;
      item.receiverPhoneError = false;
      item.receiverLocationError = false;
      item.receiverAddressError = false;
      item.noteError = false;
      item.descriptionError = false;
      item.sizeError = false;
      item.weightError = false;
      item.codValueError = false;
      item.isInsuranceError = false;
      item.insuranceValueError = false;
      item.paymentByError = false;
    },
    isDataValidatedAll(items) {
      if (
        items.filter(
          (item) =>
            item.isTrackingIdDuplicated ||
            item.trackingIdError ||
            item.receiverNameError ||
            item.receiverPhoneError ||
            item.receiverLocationError ||
            item.receiverAddressError ||
            item.noteError ||
            item.descriptionError ||
            item.sizeError ||
            item.weightError ||
            item.codValueError ||
            item.isInsuranceError ||
            item.insuranceValueError ||
            item.paymentByError ||
            item.orderValueError
        ).length >= 1
      ) {
        return false;
      } else {
        return true;
      }
    },
    // Validate data excel
    validateTableData(items) {
      // var isExistValidInsuranceOrderValue = false;
      // var existedInsuranceTag = this.checkItemFee.filter((feeCode) => feeCode === constants.feeTag.insurance);
      // var isExistedInsuranceTag = existedInsuranceTag.length > 0;
      items.map((item) => {
        this.clearValidateVariable(item);
        if (item.receiverName.length > constants.maximumSmallText || !item.receiverName) {
          item.receiverNameError = true;
          this.receiverNameNull = true;
        } else {
          item.receiverNameError = false;
        }
        const phoneRegex = constants.regExp.phone;
        if (!item.receiverPhone.match(phoneRegex)) {
          item.receiverPhoneError = true;
          this.receiverPhoneNull = true;
        } else {
          if (item.receiverPhone === this.itemAddressSelected.phone) {
            item.receiverPhoneError = true;
          } else {
            item.receiverPhoneError = false;
          }
        }
        this.lstProvince.forEach((it) => {
          if (it.code === item.receiverProvinceCode) {
            item.receiverProvinceName = it.fullName;
          }
        })
        if (
          (item.receiverCommuneName &&
            item.receiverCommuneName.length > constants.maximumSmallText) ||
          (item.receiverDistrictName &&
            item.receiverDistrictName.length > constants.maximumSmallText) ||
          item.receiverProvinceName.length > constants.maximumSmallText ||
          !item.receiverProvinceCode ||
          !item.receiverDistrictCode ||
          !item.receiverCommuneCode
        ) {
          item.receiverLocationError = true;
          this.receiverLocationNull = true;
        } else if (
          !item.receiverCommuneName ||
          !item.receiverDistrictName ||
          !item.receiverProvinceName
        ) {
          if (item.receiverCommuneName === "") {
            item.receiverCommuneName = "-";
          }
          if (item.receiverDistrictName === "") {
            item.receiverDistrictName = "-";
          }
          if (item.receiverProvinceName === "") {
            item.receiverProvinceName = "-";
          }
          item.receiverLocationError = true;
        } else {
          item.receiverLocationError = false;
        }
        if (item.receiverAddress.length > constants.maximumSmallText || !item.receiverAddress) {
          item.receiverAddressError = true;
          this.receiverAddressNull = true;
        } else {
          item.receiverAddressError = false;
        }
        if (item.description.length > constants.maximumSmallText || !item.description) {
          item.descriptionError = true;
          this.descriptionNull = true;
        } else {
          item.descriptionError = false;
        }
        const numberRegex = constants.regExp.numberFm;
        item.length = item.length + "";
        item.width = item.width + "";
        item.height = item.height + "";
        if (
          item.length.length > constants.maximumSizeLwHFm ||
          !item.length.match(numberRegex) ||
          item.width.length > constants.maximumSizeLwHFm ||
          !item.width.match(numberRegex) ||
          item.height.length > constants.maximumSizeLwHFm ||
          !item.height.match(numberRegex)
        ) {
          if (item.length === "undefined") {
            item.length = "-";
          }
          if (item.width === "undefined") {
            item.width = "-";
          }
          if (item.height === "undefined") {
            item.height = "-";
          }
          item.sizeError = true;
          this.sizeNull = true;
        } else {
          item.sizeError = false;
          item.weightTranfer = this.calculateEstimateWeight(item.length, item.width, item.height)
        }
        const decimalRegex = constants.regExp.decimal;
        if (item.weight !== undefined && item.weight !== null) {
          const weightTmp = StringUtils.replaceComma(item.weight);
          if (
            !weightTmp.match(decimalRegex) || (parseFloat(weightTmp) > 999999 || parseFloat(weightTmp) <= 0)
          ) {
            item.weightError = true;
            this.weightNull = true;
          } else {
            if (this.partnerSelect === constants.partnerGroup.ghtk && this.serviceSelect === constants.servicesShipping.over) {
              if (parseFloat(StringUtils.replaceComma(item.weight)) < constants.weight) {
                item.weightError = true;
              } else {
                item.weightError = false;
              }
            } else if (this.partnerSelect === constants.partnerGroup.ghtk && (this.serviceSelect === constants.servicesShipping.express || this.serviceSelect === constants.servicesShipping.standard)) {
              if (parseFloat(StringUtils.replaceComma(item.weight)) >= constants.weight) {
                item.weightError = true;
              } else {
                item.weightError = false;
              }
            }
          }
        } else {
          if (item.weight === undefined || item.weight === null) {
            item.weight = "-";
          }
          item.weightError = true;
          this.weightNull = true;
        }
        if (item.codValue === "undefined" || (item.codValue === null) || (item.codValue === undefined)) {
          if (this.partnerSelect === constants.partnerGroup.ghtk) {
            item.codValueError = true;
            item.codValue = "-";
          } else {
            item.codValue = "-";
            item.codValueError = false;
          }
        } else {
          if (((StringUtils.numStrToInt(item.codValue) <= constants.maximumCodValue)) && StringUtils.numStrToInt(item.codValue) >= 0) {
            item.codValueError = false;
          } else {
            item.codValueError = true;
          }
        }
        if (((item.orderValue === null) || (item.orderValue === undefined))) {
          item.orderValueError = true;
          item.orderValue = "-";
        } else {
          if (StringUtils.numStrToInt(item.orderValue) <= 0 || (StringUtils.numStrToInt(item.orderValue) > constants.maximumCodValue)) {
            item.orderValueError = true;
          } else {
            // if (this.partnerSelect === constants.partnerGroup.ghtk && StringUtils.numStrToInt(item.orderValue) >= constants.maximumInsurance) {
            //   isExistValidInsuranceOrderValue = true;
            //   if (!isExistedInsuranceTag) {
            //     isExistedInsuranceTag = true;
            //     // tu dong checkbox khi don hang > 1.000.000
            //     this.lstFeeTag.filter((it) => it.id === constants.feeTag.insurance).forEach((item) => {
            //       var insuranceFee = this.checkItemFee.find((fee) => fee === constants.feeTag.insurance);
            //       if (insuranceFee === undefined) {
            //         this.checkItemFee.push(item.id)
            //         this.arrayListFee.push(item);
            //       }
            //     })
            //     const uniqueIds = []
            //     this.arrayListFee = this.arrayListFee.filter(element => {
            //       const isDuplicated = uniqueIds.includes(element.id);
            //       if (!isDuplicated) {
            //         uniqueIds.push(element.id)
            //         return true
            //       }
            //       return false
            //     })
            //   }
            // }
          }
          item.orderValueError = false;
        }
        if (item.paymentBy && this.removeVietnameseTones(item.paymentBy).toLowerCase() !== "nguoi gui" && this.removeVietnameseTones(item.paymentBy).toLowerCase() !== "nguoi nhan") {
          item.paymentByError = true;
          this.paymentByNull = true;
        } else if (!item.paymentByValue && !item.paymentBy) {
          item.paymentBy = "-";
          item.paymentByValue = "-";
          item.paymentByError = true;
          this.paymentByNull = true;
        } else {
          if (this.removeVietnameseTones(item.paymentBy).toLowerCase() === "nguoi nhan") {
            item.paymentBy = this.$t("receiver");
            item.paymentByValue = constants.serviceFeePayers.receiver;
          }
          if (this.removeVietnameseTones(item.paymentBy).toLowerCase() === "nguoi gui") {
            item.paymentBy = this.$t("sender");
            item.paymentByValue = constants.serviceFeePayers.sender;
          }
          item.paymentByError = false;
        }
        if (item.status === constants.statusCreateOrder.fail) {
          this.statusError = true
        } else if (item.status === 1) {
          this.statusError = false
        }
      });
      this.checkInvalidOrder();
      // uncheck don hang khi < 1.000.000 ghtk
      // if ((this.partnerSelect === constants.partnerGroup.ghtk) && !isExistValidInsuranceOrderValue && isExistedInsuranceTag) {
      //   this.checkItemFee = this.checkItemFee.filter((feeCode) => feeCode !== constants.feeTag.insurance);
      //   this.arrayListFee = this.arrayListFee.filter((fee) => fee.id !== constants.feeTag.insurance);
      // }
    },
    clearErrorMsg() {
      this.items[this.editRowDataId].isTrackingIdDuplicated = false;
      this.items[this.editRowDataId].trackingIdError = false;
      this.items[this.editRowDataId].receiverNameError = false;
      this.items[this.editRowDataId].receiverPhoneError = false;
      this.items[this.editRowDataId].receiverLocationError = false;
      this.items[this.editRowDataId].receiverAddressError = false;
      this.items[this.editRowDataId].descriptionError = false;
      this.items[this.editRowDataId].noteError = false;
      this.items[this.editRowDataId].sizeError = false;
      this.items[this.editRowDataId].weightError = false;
      this.items[this.editRowDataId].codValueError = false;
      this.items[this.editRowDataId].isInsuranceError = false;
      this.items[this.editRowDataId].insuranceValueError = false;
      this.items[this.editRowDataId].paymentByError = false;
    },
    async onSaveData(val, lstDistricts, lstCommunes) {
      for (const key in val) {
        this.items[this.editRowDataId][key] = val[key];
      }
      this.clearErrorMsg();
      this.lstProvince.forEach((it) => {
        if (
          it.code === this.items[this.editRowDataId].editProvinceOrderSelected
        ) {
          this.items[this.editRowDataId].receiverProvinceCode = it.code;
          this.items[this.editRowDataId].receiverProvinceName = it.name;
        }
      })
      lstDistricts.forEach((it) => {
        if (
          it.code === this.items[this.editRowDataId].editDistrictOrderSelected
        ) {
          this.items[this.editRowDataId].receiverDistrictName = it.fullName;
          this.items[this.editRowDataId].receiverDistrictCode = it.code;
        }
      });
      lstCommunes.forEach((it) => {
        if (it.code === this.items[this.editRowDataId].editCommuneOrderSelected) {
          this.items[this.editRowDataId].receiverCommuneName = it.fullName;
          this.items[this.editRowDataId].receiverCommuneCode = it.code;
        }
      });
      this.lstDeliveryNote.forEach((it) => {
        if (it.code === this.items[this.editRowDataId].noteCode) {
          this.items[this.editRowDataId].note = it.name;
        }
      });
      if (
        this.items[this.editRowDataId].paymentByValue ===
        constants.serviceFeePayers.receiver
      ) {
        this.items[this.editRowDataId].paymentBy = "Người nhận";
      }
      if (
        this.items[this.editRowDataId].paymentByValue ===
        constants.serviceFeePayers.sender
      ) {
        this.items[this.editRowDataId].paymentBy = "Người gửi";
      }
      if (this.items[this.editRowDataId].isInsurance === this.$t("no")) {
        this.items[this.editRowDataId].orderValue = "-";
      }
      if (this.items[this.editRowDataId].status === constants.statusCreateOrder.fail) {
        this.items[this.editRowDataId].status = constants.statusCreateOrder.edited
      }
      this.showEditOrder = false;
      this.validateTableData(this.items)
      this.checkInvalidOrder();
      if (this.errorItems.length === 0) {
        this.invalidOrderSelected = false;
        this.checkInvalidOrder();
      }
      this.checkInvalidOrder()
      const isValidatedAll = this.isDataValidatedAll(this.items);
      const checkStatus = this.items.filter(item => item.status === -1)
      this.disableBtnCreate = false
      if (isValidatedAll && checkStatus.length === 0) {
        await this.calculateShipFeeExcel();
      }
    },
    // check data error excel
    checkInvalidOrder() {
      if (this.invalidOrderSelected) {
        this.items = this.excelData.filter(
          (item) =>
            item.receiverNameError ||
            item.receiverPhoneError ||
            item.receiverLocationError ||
            item.receiverAddressError ||
            item.noteError ||
            item.descriptionError ||
            item.sizeError ||
            item.weightError ||
            item.codValueError ||
            item.paymentByError ||
            item.orderValueError
        );
        this.errorItems = this.items;
      } else {
        this.errorItems = this.items.filter(
          (item) =>
            item.isTrackingIdDuplicated ||
            item.trackingIdError ||
            item.receiverNameError ||
            item.receiverPhoneError ||
            item.receiverLocationError ||
            item.receiverAddressError ||
            item.noteError ||
            item.descriptionError ||
            item.sizeError ||
            item.weightError ||
            item.codValueError ||
            item.paymentByError ||
            item.orderValueError
        );
        this.items = this.excelData;
      }
    },
    onInputLength(val) {
      this.length = StringUtils.formatNumber(val);
    },
    onPasteLength(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        this.maximumSizeLwH
      );
      if (val !== null) {
        this.length = StringUtils.formatNumber(val);
      }
    },
    onInputWidth(val) {
      this.width = StringUtils.formatNumber(val);
    },
    onPasteWidth(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        this.maximumSizeLwH
      );
      if (val !== null) {
        this.width = StringUtils.formatNumber(val);
      }
    },
    onInputHeight(val) {
      this.height = StringUtils.formatNumber(val);
    },
    onPasteHeight(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        this.maximumSizeLwH
      );
      if (val !== null) {
        this.height = StringUtils.formatNumber(val);
      }
    },
    onInputOrderWeight(val) {
      if (StringUtils.isExistSeparation(val)) {
        this.maximumOrderWeight = constants.maximumOrderWeightDecimalFm;
      } else {
        this.maximumOrderWeight = constants.maximumOrderWeightFm;
      }
      this.checkValidateOrderWeight()
      this.orderWeight = StringUtils.formatDecimal(val);
    },
    onPasteOrderWeight(evt) {
      const val = FormUtils.isOnPasteOnlyDecimalAndMaximum(
        evt,
        constants.maximumOrderWeight
      );
      if (val !== null) {
        this.orderWeight = StringUtils.formatDecimal(val);
      }
    },
    checkValidateOrderWeight() {
      var msg = null;
      if (this.partnerSelect === constants.partnerGroup.ghtk && this.serviceSelect === constants.servicesShipping.over && parseFloat(StringUtils.replaceComma(this.orderWeight)) < constants.weight) {
        msg = `${this.$t("current_service_not_responding")} ${this.$t("less_weight")}`;
        this.orderWeightError = msg;
        this.orderWeightHideError = true;
      } else if ((this.partnerSelect === constants.partnerGroup.ghtk && (this.serviceSelect === constants.servicesShipping.standard || this.serviceSelect === constants.servicesShipping.express)) &&
        parseFloat(StringUtils.replaceComma(this.orderWeight)) >= constants.weight) {
        msg = `${this.$t("current_service_not_responding")} ${this.$t("than_weight")}`;
        this.orderWeightError = msg;
        this.orderWeightHideError = true;
      } else {
        this.orderWeightError = "";
        this.orderWeightHideError = false;
      }
    },
    onInputCOD(val) {
      this.cod = StringUtils.formatNumber(val);
      if (this.detailData) {
        this.detailData.codValue = StringUtils.formatNumber(val);
      }
      this.calculateFees();
      this.checkValidCodValue();
    },
    checkValidCodValue() {
      if (
        parseFloat(StringUtils.replaceComma(this.cod)) >
        constants.maximumCodValue
      ) {
        const msg = `${this.$t("cod")} ${this.$t(
          "maximum"
        )} ${StringUtils.formatNumber(constants.maximumOrderValue)} ${this.$t(
          "vnd_unit"
        )}`;
        this.codValueError = msg;
        this.codValueHideError = true;
      } else {
        this.codValueError = "";
        this.codValueHideError = false;
      }
      if (
        parseFloat(StringUtils.replaceComma(this.cod)) >
        constants.maximumCodValue && !this.orderValue
      ) {
        const msg = `${this.$t("order_value")} ${this.$t(
          "maximum"
        )} ${StringUtils.formatNumber(constants.maximumOrderValue)} ${this.$t(
          "vnd_unit"
        )}`;
        this.orderValueError = msg;
        this.orderValueHideError = true;
      } else if (parseFloat(StringUtils.replaceComma(this.cod)) <
        constants.maximumCodValue && !this.orderValue) {
        this.orderValueError = "";
        this.orderValueHideError = false;
      }
    },
    onPasteCOD(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPriceValue
      );
      if (val !== null) {
        this.cod = StringUtils.formatNumber(val);
        this.orderDetailData.codValue = StringUtils.formatNumber(val);
        this.orderDetailData.orderValue = StringUtils.formatNumber(val);
        this.checkValidCodValue();
      }
    },
    onChangeCheckBox() {
      this.listFeeTagMap = this.lstFeeTag.map((item) => item.id)
      var arr = [];
      this.checkItemFee.forEach((itTag) => {
        this.lstFeeTag.filter((it) => it.id === parseInt(itTag)).forEach((item) => {
          arr.push(item)
        })
      })
      if (this.items !== undefined && this.items.length > 0) {
        const isValidatedAll = this.isDataValidatedAll(this.excelData);
        if (isValidatedAll) {
          this.calculateShipFeeExcel();
        }
        this.checkInvalidOrder();
      }
      this.arrayListFee = arr
    },
    // on change dich vu van chuyen tao don exel
    async onChangeServiceDataExcel() {
      this.arrayListFee = []
      await this.getEstablishServiceByPartner(this.partnerSelect)
      this.validateTableData(this.excelData);
      if (this.items !== undefined && this.items.length > 0) {
        const isValidatedAll = this.isDataValidatedAll(this.excelData);
        if (isValidatedAll) {
          this.calculateShipFeeExcel();
        }
        this.checkInvalidOrder();
      }
    },
    async validateDataExcel() {
      if (this.items !== undefined && this.items.length > 0) {
        this.validateTableData(this.excelData);
        const isValidatedAll = this.isDataValidatedAll(this.excelData);
        if (isValidatedAll) {
          this.calculateShipFeeExcel();
        }
        this.checkInvalidOrder();
      }
    },
    // on change dich vu van chuyen tao don le
    async onChangeGetServiceAll(val) {
      this.checkedFeeLst = []
      this.totalServiceFee = 0
      const service = this.lstPartner.find((it) => it.partnerId === val)
      if (service) {
        this.serviceSelect = service.serviceId
        this.transportFee = service.shipFee
        this.requireNote = service.requireNote
        this.calculateFees();
        await this.getEstablishServiceByPartner(this.partnerSelect)
      }
    },
    // danh sach dich vu theo don vi van chuyen
    async getEstablishServiceByPartner(par) {
      try {
        this.checkedFeeLs = []
        const response = await OrderService.getEstablishServiceByPartner(par)
        this.lstFeeTag = response.data
        this.lstFeeTag.forEach((item) => {
          item.isDisableFeeTag = false
          if (item.requireValue) {
            const value = this.orderValue ? this.orderValue : this.cod ? this.cod : "";
            if (item.requireValue <= parseInt(value.replaceAll(",", ""))) {
              item.isDisableFeeTag = true
              this.checkedFeeLst.push(item.id)
              this.checkCode();
            }
          }
        })
      } catch (error) {
        console.log(error);
      }
    },
    checkCode() {
      clearTimeout(this.timeOutTotalService);
      this.timeOutTotalService = setTimeout(async() => {
        const payload = {
          "partnerId": this.partnerSelect,
          "serviceFeeIds": this.checkedFeeLst,
          "shipValue": this.transportFee
        }
        payload.orderValue = 0;
        if (this.cod) {
          payload.orderValue = parseInt(this.cod.replaceAll(",", ""))
        }
        if (this.orderValue) {
          payload.orderValue = parseInt(this.orderValue.replaceAll(",", ""))
        }
        const { status, data } = await OrderService.calculateServicefee(payload)
        if (status === constants.statusCode.ok && data) {
          this.totalServiceFee = data.reduce((total, fee) => total + fee.value, 0);
        }
        this.calculateFees();
      }, 800)
    },
    onShowInputValue(val) {
      this.orderValue = StringUtils.formatNumber(val);
      if (this.orderValue) {
        this.orderValue
      }
    },
    onInputOrderValue(val) {
      this.orderValue = StringUtils.formatNumber(val);
      if (this.detailData) {
        this.detailData.orderValue = StringUtils.formatNumber(val);
      }
      this.calculateFees()
      this.checkValidOrderValue();
    },
    checkValidOrderValue() {
      if (
        parseFloat(StringUtils.replaceComma(this.orderValue)) >
        constants.maximumOrderValue
      ) {
        const msg = `${this.$t("order_value")} ${this.$t(
          "maximum"
        )} ${StringUtils.formatNumber(constants.maximumOrderValue)} ${this.$t(
          "vnd_unit"
        )}`;
        this.orderValueError = msg;
        this.orderValueHideError = true;
      } else {
        this.orderValueError = "";
        this.orderValueHideError = false;
      }
    },
    checkValidInsurance(val) {
      if (val === "Y" || val === "y" || val.toLowerCase() === "yes") {
        return true;
      } else {
        return false;
      }
    },
    onPasteOrderValue(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPriceValue
      );
      if (val !== null) {
        this.orderValue = StringUtils.formatNumber(val);
        this.checkValidOrderValue();
      }
    },
    async calculateShipFee() {
      this.resetShippingInformation();
      if (this.checkValidShipFee()) {
        const pars = this.bindCalculateShipFeeRequestPars();
        const { status, data } = await OrderService.calculateShipfee(pars);
        if (status === constants.statusCode.ok) {
          this.lstPartner = data
        }
      }
    },
    formatCurrency(val) {
      return val.toLocaleString("it-IT", {
        style: "currency",
        currency: "VND",
      })
    },
    checkValidShipFee() {
      if (!this.itemAddressSelected) return false;

      if (!this.provinceOrderCodeSelected) return false;

      if (!this.length) return false;

      if (!this.width) return false;

      if (!this.height) return false;

      if (!this.orderWeight) return false;

      if (parseFloat(StringUtils.replaceComma(this.orderWeight)) <= 0) return false;

      return true;
    },
    bindCalculateShipFeeRequestPars() {
      const pars = {
        custId: this.itemAddressSelected.custId,
        transportType: this.transportType,
        addressId: this.itemAddressSelected.id,
        receiverProvinceCode: this.provinceOrderCodeSelected,
        weight: StringUtils.numStrToFloat(this.orderWeight),
        estimateWeight: StringUtils.numStrToFloat(this.computedEstimateWeight(this.length, this.width, this.height)),
      };
      return pars;
    },
    async calculateFees() {
      this.totalFee = 0
      if (this.transportFee && !isNaN(parseFloat(this.transportFee))) {
        this.totalFee += parseFloat(this.transportFee)
      }
      if (this.totalServiceFee && !isNaN(parseFloat(this.totalServiceFee))) {
        this.totalFee += parseFloat(this.totalServiceFee)
      }
      this.calculateTotalRevenue()
    },
    calculateTotalRevenue() {
      this.totalRevenue = 0;
      if (this.serviceFeePayer === this.serviceFeePayersConst.receiver) {
        if (this.transportFee && !isNaN(parseFloat(this.transportFee))) {
          this.totalRevenue += parseFloat(this.transportFee)
        }
        if (this.totalServiceFee && !isNaN(parseFloat(this.totalServiceFee))) {
          this.totalRevenue += parseFloat(this.totalServiceFee)
        }
        if (this.cod && !isNaN(parseFloat(this.cod.replaceAll(",", "")))) {
          this.totalRevenue += parseFloat(this.cod.replaceAll(",", ""))
        }
        return
      }
      if (this.cod && !isNaN(parseFloat(this.cod.replaceAll(",", "")))) {
        this.totalRevenue += parseFloat(this.cod.replaceAll(",", ""))
      }
    },
    reType() {
      this.onShowConfirmResetForm();
    },
    onShowConfirmResetForm() {
      this.typeNoti = constants.typeAlert.warning;
      this.message = this.$t("all_info_deleted");
      this.message2 = this.$t("are_you_re_enter");
      this.showConfirmResetForm = true;
    },
    onCancelConfirmResetForm() {
      this.showConfirmResetForm = false;
    },
    onAcceptConfirmResetForm() {
      this.showConfirmResetForm = false;
      this.resetFormDialogEdit();
      this.resetForm()
    },
    onChangeServiceFeePayer(val) {
      this.calculateFees();
    },
    getTextTruncateView(val, size) {
      return getTextTruncate(val, size);
    },
    removeVietnameseTones(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
      // Remove extra spaces
      // Bỏ các khoảng trắng liền nhau
      str = str.replace(/ + /g, " ");
      str = str.trim();
      // Remove punctuations
      // Bỏ dấu câu, kí tự đặc biệt
      str = str.replace(
        /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
        " "
      );
      return str;
    },
    resetShippingInformation() {
      this.totalFee = "0"
      this.totalServiceFee = "0"
      this.transportFee = "0"
      this.partnerSelect = null
      this.serviceSelect = null
      this.lstFeeTag = []
      this.lstPartner = []
      this.checkedFeeLst = []
      this.calculateFees()
    },
    resetForm() {
      this.pickUpDate = null;
      this.pickUpDateFm = null;
      this.timePicker = constPickupTime.time1;
      // this.timePickerList = []
      this.timePickerDisabled = true;
      this.calculateMinMaxAllowedDate();
      this.itemAddressSelected = this.itemAddressDefault;
      this.phone = "";
      this.fullName = "";
      this.provinceOrderCodeSelected = null;
      this.lstDistrictOrder = [];
      this.districtOrderSelected = null;
      this.districtOrderSelectedName = null;
      this.districtDisabledOrder = true;
      this.lstCommuneOrder = [];
      this.communeOrderSelected = null;
      this.communeOrderSelectedName = null;
      this.communeDisabledOrder = true;
      this.customAddress = "";
      this.productDescription = "";
      this.length = "";
      this.width = "";
      this.height = "";
      this.orderWeight = "";
      this.cod = "";
      this.isShowTextBoxTypeOrderValue = false;
      this.insurance = constants.orderInsurance.no;
      this.orderValue = "";
      // this.editOrderDetail = {};
      this.editProvinceOrderSelected = null;
      this.serviceFeePayer = constants.serviceFeePayers.sender;
      this.deliveryNoteSelected = "";
      this.transportFee = "0";
      this.insuranceFee = "0";
      this.totalFee = "0";
      this.totalRevenue = "0";
      this.valueService = "0"
      this.orderDetailData = {};
      this.orderWeightError = ""
      this.orderWeightHideError = false
      this.codValueHideError = false
      this.codValueError = ""
      this.orderValueHideError = false
      this.orderValueError = ""
      this.receiverPhoneHideError = false
      this.receiverPhoneError = ""
      this.orderDetailData.paymentByValue = constants.serviceFeePayers.sender
      this.partnerSelect = null
      this.serviceSelect = null
      this.lstFeeTag = []
      this.$refs.observer.reset();
    },
    resetFormDialogEdit() {
      this.orderDetailData = {}
    }
  },
};

export default mixinHandleOrderCreate;
