import apis from './apis'
import { BaseService } from './baseService'

export class ComparisonService extends BaseService {
  static async getAll() {
    try {
      const response = await this.request({ auth: true }).get(
        apis.comparison_get_all
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
}
