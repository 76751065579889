<template>
  <div>
    <v-navigation-drawer
      id="core-navigation-drawer"
      v-model="drawer"
      color="primary"
      :expand-on-hover="expandOnHover"
      mobile-breakpoint="960"
      :mini-variant="mini"
      mini-variant-width="100"
      app
      width="260"
      v-bind="$attrs"
    >
      <v-layout justify-center column class="mt-8">
        <div class="d-flex align-center">
          <v-layout align-center justify-center>
            <h2 class="logo cursor-pointer" @click="goToDashboard" />
          </v-layout>
          <div>
            <v-btn v-if="!mini" fab icon dark x-small class="btn-menu" @click="mini = !mini">
              <v-icon>mdi-menu-left</v-icon>
            </v-btn>
            <v-btn v-else fab icon dark x-small class="btn-menu" @click="mini = !mini">
              <v-icon>mdi-menu-right</v-icon>
            </v-btn>
          </div>
        </div>
        <v-layout justify-center class="mt-11">
          <v-btn v-if="!mini" class="ma-2" outlined color="white" @click="onShowDialogCreateOrderOption">
            <v-icon>mdi-plus</v-icon>
            {{ $t('create_order') }}
          </v-btn>
          <v-btn v-else outlined class="mr-2" small fab color="white" @click="onShowDialogCreateOrderOption">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-layout>
      </v-layout>

      <v-list v-for="(menu, i) in items" :key="i">
        <v-list-item
          v-if="!menu.child"
          link
          :to="menu.to"
          class="white--text"
          @click="goMenu(menu)"
        >
          <v-list-item-icon class="mr-3">
            <v-icon color="white" v-text="menu.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text" v-text="menu.title" />
          </v-list-item-content>
        </v-list-item>
        <v-list-group v-else v-model="menu.active" no-action class="white--text">
          <template v-slot:activator>
            <v-list-item-icon class="mr-3">
              <v-icon color="white" v-text="menu.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(item, key) in menu.child"
            :key="key"
            :to="item.to"
            link
            class="white--text"
            @click="goMenu(item)"
          >
            <v-list-item-title class="white--text" v-text="item.title" />
            <v-list-item-icon>
              <v-icon color="white" v-text="item.icon" />
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-dialog v-model="isShowCreateOrderOptions" persistent width="500">
        <v-card class="px-4 py-2">
          <v-layout justify-end>
            <v-icon size="20" @click="onCloseDialogCreateOrderOptions">mdi-close</v-icon>
          </v-layout>
          <v-layout column class="px-2">
            <v-layout column>
              <label class="text-subtitle-1 font-weight-large text-center">Tạo đơn hàng</label>
              <label class="text-caption text-center">Vui lòng lựa chọn hình thức tạo đơn hàng</label>
            </v-layout>
            <v-layout justify-space-between class="order-create-actions my-8">
              <v-layout class="w-190 rounded-lg mr-3 pa-4 xs-column hover-pointer" :class="activePackage ? 'border-primary' : 'border'" @click="goOrderCreate">
                <v-layout justify-center align-center>
                  <v-btn fab small color="rgba(15, 144, 232, 0.1)" :class="activePackage ? 'active' : ''">
                    <i class="type-icon icon-package" />
                  </v-btn>
                </v-layout>
                <v-layout column :class="activePackage ? 'primary--text' : ''">
                  <label class="text-subtitle-2 font-weight-large hover-pointer">{{ $t('order_create_one') }}</label>
                  <span class="text-min">{{ $t('caption_order_create_one') }}</span>
                  <span class="text-min">{{ $t('caption_order_create_2') }}</span>
                </v-layout>
              </v-layout>
              <v-layout class="w-190 rounded-lg ml-3 pa-4 xs-column hover-pointer" :class="activePackages ? 'border-primary' : 'border'" @click="goOrderCreateMultiple">
                <v-layout justify-center align-center>
                  <v-btn fab small color="rgba(15, 144, 232, 0.1)" :class="activePackages ? 'active' : ''">
                    <i class="type-icon icon-packages" />
                  </v-btn>
                </v-layout>
                <v-layout column :class="activePackages ? 'primary--text' : ''">
                  <label class="text-subtitle-2 font-weight-large hover-pointer">{{ $t('create_multiple_order') }}</label>
                  <span class="text-min">{{ $t('caption_order_create_mul') }}</span>
                  <span class="text-min">{{ $t('caption_order_create_2') }}</span>
                </v-layout>
              </v-layout>
            </v-layout>
            <v-layout class="order-create-multi-box">
              <v-layout
                class="w-190 rounded-lg pa-4 xs-column hover-pointer"
                :class="activeMultiBox ? 'border-primary' : 'border'"
                @click="goOrderCreateMultiBox"
              >
                <v-layout justify-center align-center>
                  <v-btn
                    fab
                    small
                    color="rgba(15, 144, 232, 0.1)"
                    :class="activeMultiBox ? 'active' : ''"
                  >
                    <i class="type-icon icon-multibox" />
                  </v-btn>
                </v-layout>
                <v-layout column :class="activeMultiBox ? 'primary--text' : ''">
                  <label class="text-subtitle-2 font-weight-large hover-pointer">{{
                    $t("create_multi_box_order")
                  }}</label>
                  <span class="text-min">{{ $t("caption_order_create_mul") }}</span>
                  <span class="text-min">{{ $t("caption_order_create_2") }}</span>
                </v-layout>
              </v-layout>
            </v-layout>
          </v-layout>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
// Utilities
import { mapState, mapActions } from 'vuex'
import routePaths from '@/router/routePaths'
import constants from '@/constants'
import { DataBreadCrumbUtils } from '@/helpers/dataBreadCrumbUtils'
import { StringUtils } from '@/helpers/stringUtils'

export default {
  name: 'DashboardCoreDrawer',
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [
        {
          icon: 'mdi-home',
          title: this.$t('dashboard'),
          to: routePaths.DASHBOARD_MAIN,
          active: false
        },
        {
          icon: 'mdi-account-box-outline',
          title: this.$t('menu.order_management'),
          child: [
            {
              title: this.$t('menu.order_list'),
              to: routePaths.ORDER_LIST,
            },
            {
              title: this.$t('menu.order_list_process'),
              to: routePaths.LIST_ORDER_PROCESS,
            },
            {
              title: this.$t('menu.order_list_partnal'),
              to: routePaths.LIST_ORDER_ONE_PART,
            }
          ],
          active: false
        },
        {
          icon: 'mdi-account-box-outline',
          title: this.$t('menu.account_management'),
          child: [
            {
              title: this.$t('menu.shop_profile'),
              to: routePaths.SHOP_PROFILE
            },
            {
              title: this.$t('menu.checking_account'),
              to: routePaths.CHECKING_ACCOUNT
            },
            {
              title: this.$t('menu.sender_address'),
              to: routePaths.ADDRESS_LIST
            }
          ],
          active: false
        }
      ],
      mini: false,
      activePackage: false,
      activePackages: false,
      activeMultiBox: false,
      isShowCreateOrderOptions: false
    }
  },
  computed: {
    ...mapState('layout', ['barColor', 'orderCreateActive']),
    drawer: {
      get() {
        return this.$store.state.layout.drawer
      },
      set(val) {
        this.setDrawer(val)
      }
    },
    profile() {
      return {
        avatar: true,
        title: this.$t('brand')
      }
    },
    stateOrderCreateActive() {
      return this.$store.state.layout.orderCreateActive
    }
  },
  created() {
    this.fillActiveMenu()
  },
  methods: {
    ...mapActions('layout', ['setDrawer', 'setOrderCreate', 'setBreadcrumbs']),
    fillActiveMenu() {
      const pathActive = this.$router.currentRoute.path
      this.bindActiveMenu(pathActive)
    },
    goMenu(menu) {
      this.bindActiveMenu(menu.to)
      this.setOrderCreate(false)
      if (this.$router.currentRoute.path === menu.to) {
        this.$router.go()
      }
    },
    bindActiveMenu(pathActive) {
      this.items.forEach((item) => {
        item.active = false

        if (item.child) {
          item.child.forEach((value) => {
            if (value.to === pathActive) {
              item.active = true
            }
          })
        }

        if (typeof item.to !== undefined && item.to === pathActive) {
          item.active = true
        }
      })
      this.addBreadCrumbs(pathActive)
    },
    onShowDialogCreateOrderOption() {
      this.isShowCreateOrderOptions = true
    },
    goOrderCreate() {
      this.activePackage = true
      this.activePackages = false
      this.activeMultiBox = false
      this.isShowCreateOrderOptions = false
      const pathName = StringUtils.getPathNameByPath(this.$router.currentRoute.path)
      if ((pathName !== routePaths.ORDER_CREATE)) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE)
        this.setOrderCreate(true)
        this.$router.push(routePaths.ORDER_CREATE)
      }
    },
    goOrderCreateMultiple() {
      this.activePackage = false
      this.activePackages = true
      this.activeMultiBox = false
      this.isShowCreateOrderOptions = false
      const pathName = StringUtils.getPathNameByPath(this.$router.currentRoute.path)
      if ((pathName !== routePaths.ORDER_CREATE_MULTIPE)) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE_MULTIPE)
        this.setOrderCreate(true)
        this.$router.push(routePaths.ORDER_CREATE_MULTIPE)
      }
    },
    goOrderCreateMultiBox() {
      this.activePackage = false
      this.activePackages = false
      this.activeMultiBox = true
      this.isShowCreateOrderOptions = false
      const pathName = StringUtils.getPathNameByPath(this.$router.currentRoute.path)
      if ((pathName !== routePaths.ORDER_CREATE_MULTI_BOX)) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE_MULTI_BOX)
        this.setOrderCreate(true)
        this.$router.push(routePaths.ORDER_CREATE_MULTI_BOX)
      }
    },
    goToDashboard() {
      this.addBreadCrumbs(routePaths.DASHBOARD_MAIN)
      this.$router.push(routePaths.DASHBOARD_MAIN)
    },
    addBreadCrumbs(pathActive) {
      const breadCrumbs =
        DataBreadCrumbUtils.getBreadCrumbByPathActive(pathActive)
      this.setBreadcrumbs(breadCrumbs)
    },
    getColorMenu(menu) {
      return !menu.child &&
        typeof menu.active !== constants.undefined &&
        menu.active
        ? 'white'
        : 'white'
    },
    onCloseDialogCreateOrderOptions() {
      this.isShowCreateOrderOptions = false
    }
  }
}
</script>
<style>
.hover-pointer {
  cursor: pointer;
}
</style>
