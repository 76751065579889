<template>
  <v-main>
    <v-layout
      column
      rounded
      mt-2
      mt-sm-4
      mx-2
      mx-sm-4
      px-2
      px-sm-3
      py-2
      py-sm-4
      minh-content
    >
      <router-view />
    </v-layout>
    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>

<script>
export default {
  name: 'DashboardCoreView',

  components: {
    // DashboardCoreFooter: () => import('./Footer')
  }
}
</script>
