export default {
  // no auth
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_UPDATE: '/forgot-password-update',

  CHANGE_PASSWORD: '/change-password',
  UPDATE_PASSWORD: '/update-password',

  REGISTER: '/register',
  OTP: '/otp',

  // dashboard
  DASHBOARD: '/dashboard-sub',
  DASHBOARD_MAIN: '/dashboard',

  // order
  ORDER_LIST: '/order-list',
  LIST_ORDER_PROCESS: '/order-process',
  LIST_ORDER_ONE_PART: '/order_one_part',
  ORDER_CREATE: '/order-create',
  ORDER_CREATE_MULTIPE: '/order-create-multiple',
  ORDER_DETAIL: '/order-detail',
  ORDER_CREATE_MULTI_BOX: '/order-create-multi-box',

  CASH_FLOW_MANAGEMENT: '/cash-flow-management',

  SHOP_PROFILE: '/shop-profile',
  CHECKING_ACCOUNT: '/checking-account',
  ADDRESS_LIST: '/address-list'
}
