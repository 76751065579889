var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.widthDialog,"persistent":""},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"mb-3",attrs:{"justify-space-between":""}},[_c('h6',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t('edit_address') : _vm.$t('add_address'))+" ")]),_c('v-icon',{on:{"click":_vm.onClose}},[_vm._v("mdi-close")])],1),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-layout',{attrs:{"column":""}},[_c('label',[_vm._v(_vm._s(_vm.$t('fullname'))+" "),_c('span',{staticClass:"error--text"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"require","vid":"fullname","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","maxlength":_vm.maximumName,"placeholder":_vm.$t('place_holders.fullname'),"error-messages":errors},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})]}}],null,true)})],1),_c('v-layout',{attrs:{"column":""}},[_c('label',[_vm._v(_vm._s(_vm.$t('phone'))+" "),_c('span',{staticClass:"error--text"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"phone","rules":_vm.phoneRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","maxlength":_vm.maximumPhone,"placeholder":_vm.$t('place_holders.phone'),"error-messages":errors},on:{"keypress":function($event){return _vm.isNumber($event)},"paste":function($event){$event.preventDefault();return _vm.onPastePhone($event)}},model:{value:(_vm.item.phone),callback:function ($$v) {_vm.$set(_vm.item, "phone", $$v)},expression:"item.phone"}})]}}],null,true)})],1),_c('v-layout',{attrs:{"column":""}},[_c('label',[_vm._v(_vm._s(_vm.$t('email'))+" "),_c('span',{staticClass:"error--text"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"email","rules":_vm.emailRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","maxlength":_vm.maximumEmail,"placeholder":_vm.$t('place_holders.email'),"error-messages":errors},model:{value:(_vm.item.email),callback:function ($$v) {_vm.$set(_vm.item, "email", $$v)},expression:"item.email"}})]}}],null,true)})],1),_c('v-layout',{attrs:{"column":""}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('province'))+"/"+_vm._s(_vm.$t('district'))+"/"+_vm._s(_vm.$t('commune'))+" "),_c('span',{staticClass:"error--text"},[_vm._v("*")])]),_c('v-row',[_c('validation-provider',{staticClass:"container col-sm-4 col-12 pb-0 pb-sm-3",attrs:{"name":"require","vid":"province","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"inputProvince",staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","item-text":"name","item-value":"code","hide-no-data":"","items":_vm.lstProvince,"error-messages":errors,"placeholder":_vm.$t('place_holders.province')},on:{"change":_vm.onChangeProvince},model:{value:(_vm.item.provinceCode),callback:function ($$v) {_vm.$set(_vm.item, "provinceCode", $$v)},expression:"item.provinceCode"}})]}}],null,true)}),_c('validation-provider',{ref:"district",staticClass:"container col-sm-4 col-12 col-12 py-0 py-sm-3",attrs:{"name":"require","vid":"district","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"inputDistrict",staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","item-text":"name","item-value":"code","hide-no-data":"","items":_vm.lstDistrict,"placeholder":_vm.$t('place_holders.district'),"error-messages":errors,"disabled":_vm.districtDisabled},on:{"change":_vm.onChangeDistrict},model:{value:(_vm.item.districtCode),callback:function ($$v) {_vm.$set(_vm.item, "districtCode", $$v)},expression:"item.districtCode"}})]}}],null,true)}),_c('validation-provider',{ref:"commune",staticClass:"container col-sm-4 pt-0 pt-sm-3",attrs:{"name":"require","vid":"commune","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"inputCommune",staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","item-text":"name","item-value":"code","hide-no-data":"","items":_vm.lstCommune,"placeholder":_vm.$t('place_holders.commune'),"error-messages":errors,"disabled":_vm.communeDisabled},model:{value:(_vm.item.communeCode),callback:function ($$v) {_vm.$set(_vm.item, "communeCode", $$v)},expression:"item.communeCode"}})]}}],null,true)})],1)],1),_c('v-layout',{attrs:{"column":""}},[_c('label',[_vm._v(_vm._s(_vm.$t('specific_address'))+" "),_c('span',{staticClass:"error--text"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"require","vid":"specific_address","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","maxlength":_vm.maximumSmallText,"placeholder":_vm.$t('place_holders.address_detail'),"error-messages":errors},model:{value:(_vm.item.addressDetail),callback:function ($$v) {_vm.$set(_vm.item, "addressDetail", $$v)},expression:"item.addressDetail"}})]}}],null,true)})],1),_c('v-layout',{attrs:{"column":""}},[_c('label',[_vm._v(_vm._s(_vm.$t('delivery_notes')))]),_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","maxlength":_vm.maximumSmallText,"placeholder":_vm.$t('place_holders.delivery_notes')},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", $$v)},expression:"item.note"}})],1),_c('v-layout',{attrs:{"column":""}},[_c('validation-provider',[_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"label":_vm.$t('set_default_address'),"disabled":_vm.defaultOrigin},model:{value:(_vm.item.defaultAddress),callback:function ($$v) {_vm.$set(_vm.item, "defaultAddress", $$v)},expression:"item.defaultAddress"}})],1)],1),_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"rounded-lg mr-2",attrs:{"outlined":""},on:{"click":_vm.onClickCancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t('save') : _vm.$t('add'))+" ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }