<template>
  <div id="dashboard">
    <div class="description-section py-4 px-6">
      <v-layout justify-space-between align-center>
        <v-layout column>
          <span class="text--body-5-20 color-white">
            {{ $t("greeting") }}, {{ currentUser.name }} !
          </span>
          <span class="color-white pt-4">
            <span class="fw-500">{{ $t("brand") }}</span> - {{ $t("slogan") }}
          </span>
        </v-layout>
      </v-layout>
    </div>
    <div class="wrap-chart pt-4">
      <div class="box-search mr-5">
        <div class="d-flex justify-end align-center mr-2">
          <span v-if="circleType === 'TODAY'" class="text-date text--body-6-14">
            {{ circleStartTime }}
          </span>
          <span v-if="circleType === 'WEEK'" class="text-date text--body-6-14">
            {{ convertDateMonthFormat(circleStartTime) }} -
            {{ circleEndTime }}
          </span>
          <span
            v-if="circleType === 'MONTH'"
            class="text-date text--body-6-14 text-capitalize"
          >
            {{ $t("month") }} {{ currentMonth }}
          </span>
          <v-menu
            v-if="circleType === 'custom'"
            ref="menuDateFromTo"
            v-model="menuDateFromTo"
            :close-on-content-click="false"
            :return-value.sync="datesPicker"
            min-width="auto"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                :error-messages="$t(errorDateFromTo)"
                append-icon="mdi-calendar-range"
                outlined
                dense
                hide-details="auto"
                readonly
                clearable
                placeholder="DD/MM - DD/MM"
                v-bind="attrs"
                v-on="on"
                @input="onInputDateRange"
              />
            </template>
            <v-date-picker
              v-model="datesPicker"
              :allowed-dates="allowedDates"
              :locale="localDate"
              fomat="DD/MM/YYYY"
              no-title
              range
            >
              <v-spacer />
              <v-btn text color="primary" @click="menuDateFromTo = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn text color="primary" @click="onChangeDates">
                {{ $t("select") }}
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-select
            v-model="circleType"
            outlined
            dense
            hide-selected
            hide-details
            floating
            solo
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            :items="lstCircleType"
            item-text="name"
            item-value="value"
            attach
            class="calendar-text--body-4 ml-2"
            style="max-width: 40%"
            @change="changeTypeCalendar()"
          />
        </div>
        <div>
          <v-select
            v-model="partnerSelected"
            outlined
            dense
            hide-details
            class="select-groups"
            :placeholder="$t('place_holders.choice_group_shipping')"
            solo
            :items="lstPartner"
            item-text="name"
            item-value="code"
            @change="onChangePartner"
          />
        </div>
      </div>
      <div class="wrap-cart-item-first">
        <div class="wrap-cart-item">
          <div>
            {{ $t("total_value") }}
          </div>
          <div class="count-item ml-2">
            {{ totalValue ? convertPrice(totalValue) : 0 }} VND
          </div>
        </div>
        <div class="wrap-cart-item">
          <div>
            {{ $t("total_value_ship_success") }}
          </div>
          <div class="count-item ml-2">
            {{ totalSuccessDelivery ? convertPrice(totalSuccessDelivery) : 0 }} VND
          </div>
        </div>
        <div class="wrap-cart-item">
          <div>
            {{ $t("total_value_return") }}
          </div>
          <div class="count-item ml-2">
            {{ totalReturnedSender ? convertPrice(totalReturnedSender) : 0 }} VND
          </div>
        </div>
      </div>
      <div class="ml-5 my-3 name-item">
        {{ $t("operate") }}
      </div>
      <div class="wrap-cart-item-first">
        <div class="wrap-cart-item" style="background: #E7F4FD">
          <div>
            {{ $t("get_success_order") }}
          </div>
          <div class="count-item ml-2" style="color: #0466A9">
            {{ inProcess ? convertPrice(inProcess) : 0 }}
          </div>
        </div>
        <div class="wrap-cart-item" style="background: #E6FDEB">
          <div>
            {{ $t("success_delivery_order") }}
          </div>
          <div class="count-item ml-2" style="color: #0AA72E">
            {{ successDelivery ? convertPrice(successDelivery) : 0 }}
          </div>
        </div>
        <div class="wrap-cart-item" style="background: #F6F4FF">
          <div>
            {{ $t("returned_sender") }}
          </div>
          <div class="count-item ml-2" style="color: #5743BE">
            {{ returnedSender ? convertPrice(returnedSender) : 0 }}
          </div>
        </div>
        <div class="wrap-cart-item" style="background: #FBE0CC">
          <div>
            {{ $t("delivery_fail_order") }}
          </div>
          <div class="count-item ml-2" style="color: #E56200">
            {{ deliveryFail ? convertPrice(deliveryFail) : 0 }}
          </div>
        </div>
      </div>
      <div class="ml-5 my-3 name-item">
        Thống kê tiền hàng
      </div>
      <div class="wrap-cart-item-first">
        <div v-if="totalPieChartData === 0" class="wrap-cart-item-piachar">
          <empty-box :loading="loading" :subtitle="'empty_order_msg'" />
        </div>
        <v-layout v-else column align-center justify-center class="wrap-cart-item-piachar">
          <v-row>
            <v-col style="align-self: center">
              <piechart
                :series="series"
                :chart-options="chartOptions"
                :total="totalPieChartData"
                @go-order-list="goOrderList"
              />
            </v-col>
            <v-col cols="sm-8">
              <v-data-table
                hide-default-footer
                :no-data-text="$t('not_found')"
                :headers="headers"
                :fixed-header="true"
                class="px-9 mx-4"
                :items-per-page="items.length"
                :header-props="{ sortIcon: 'mdi-menu-swap' }"
                :items="items"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <tr class="max-width-6 text-truncate cursor-pointer" @click="goOrderList(item.statusDescription)">
                    <v-avatar tile :color="getColorStatus(item.statusOfOrder)" size="15" />
                    {{ item.statusDescription }}
                  </tr>
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  <td class="max-width-7 text-truncate">
                    {{ formatDecimal(item.amount) }}
                  </td>
                </template>
                <template v-slot:[`item.codValue`]="{ item }">
                  <td class="max-width-7 text-truncate">
                    {{ item.codValue ? formatDecimal(item.codValue) : 0 }}
                  </td>
                </template>
                <template v-slot:[`item.shipValue`]="{ item }">
                  <td class="max-width-7 text-truncate">
                    {{ formatDecimal(item.shipValue) }}
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyBox from "@/components/EmptyBox.vue";
// import DialogCreateOrderOption from "@/components/dialog/DialogCreateOrderOption.vue";
import Piechart from "./components/Piechart.vue";
import { mapActions } from "vuex";
import constants from "@/constants";
import routePaths from "@/router/routePaths";
import moment from "moment";
import { OrderService } from "@/services/orderService";
import { DataBreadCrumbUtils } from "@/helpers/dataBreadCrumbUtils";
import { StringUtils } from "@/helpers/stringUtils";

export default {
  name: "Dashboard",
  components: {
    EmptyBox,
    // DialogCreateOrderOption,
    Piechart,
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem("CURRENT_USER")),
      isShowCreateOrderOptions: false,
      activePackage: false,
      activePackages: false,
      activeMultiBox: false,
      series: [],
      listCod: [0, 0, 0, 0, 0],
      totalPieChartData: 0,
      dataDashBoard: null,
      loading: false,

      circleType: constants.typeOfDay.week,
      lstCircleType: [
        {
          name: "Hôm nay",
          value: constants.typeOfDay.today,
        },
        {
          name: "Tuần này",
          value: constants.typeOfDay.week,
        },
        {
          name: "Tháng này",
          value: constants.typeOfDay.month,
        },
        {
          name: "Tùy chọn",
          value: "custom",
        },
        {
          name: "Tất cả",
          value: "all",
        },
      ],
      startTime: moment().clone().startOf("isoWeek"),
      endTime: moment().clone().endOf("isoWeek"),
      dayNow: null,
      circleStartTime: null,
      circleEndTime: null,
      currentMonth: null,
      today: null,
      currentYear: null,
      isDisabledButtonNextWeek: false,

      menuDateFromTo: false,
      datesPicker: [],
      datesPickerFm: [],
      errorDateFromTo: null,

      lineType: constants.typeOfDay.week,
      headers: [
        {
          text: this.$t("status"),
          value: "status",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("amount"),
          value: "amount",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("cod_value"),
          value: "codValue",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("postage"),
          value: "shipValue",
          class: "text-subtitle-2",
        },
      ],
      lstPartner: [
        {
          name: "Tất cả",
          code: null
        },
        {
          name: 'Ninja Van',
          code: 1
        },
        {
          name: 'Giao hàng tiết kiệm',
          code: 2
        },
      ],
      partnerSelected: null,
      totalValue: 0,
      totalReturnedSender: 0, // boi hoan thanh cong
      totalSuccessDelivery: 0, // giao thanh cong
      successDelivery: 0, // giao thanh cong
      inProcess: 0, // cho xu ly
      returnedSender: 0, // hoan thanh cong
      deliveryFail: 0, // giao ko thanh cong
      amount: 0, // tong don,
      items: [],
    };
  },
  computed: {
    localDate() {
      return this.$i18n.locale === "vi" ? "vi-VN" : "en-US";
    },
    dateRangeText() {
      return this.datesPickerFm.join(" - ");
    },
    chartOptions: function() {
      return {
        labels: [
          this.$t("order_status.waiting"),
          this.$t("order_status.cancelled"),
          this.$t("order_status.delivering"),
          this.$t("order_status.pickup_failed"),
          this.$t("order_status.successful_delivery"),
          this.$t("order_status.completed"),
          this.$t("order_status.refunding_to_sender"),
          this.$t("order_status.refunded_to_sender"),
          this.$t("order_status.waiting_delivery"),
          this.$t("order_status.order-re-weighed"),
          this.$t("order_status.compensation_order"),
          this.$t("order_status.fail_delivery"),
        ],
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        chart: {
          type: "donut",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              switch (config.dataPointIndex) {
                case 0:
                  this.goOrderList(this.$t("order_status.waiting"));
                  break;
                case 1:
                  this.goOrderList(this.$t("order_status.cancelled"));
                  break;
                case 2:
                  this.goOrderList(this.$t("order_status.delivering"));
                  break;
                case 3:
                  this.goOrderList(this.$t("order_status.pickup_failed"));
                  break;
                case 4:
                  this.goOrderList(this.$t("order_status.successful_delivery"));
                  break;
                case 5:
                  this.goOrderList(this.$t("order_status.completed"));
                  break;
                case 6:
                  this.goOrderList(this.$t("order_status.refunding_to_sender"));
                  break;
                case 7:
                  this.goOrderList(this.$t("order_status.refunded_to_sender"));
                  break;
                case 8:
                  this.goOrderList(this.$t("order_status.waiting_delivery"));
                  break;
                case 9:
                  this.goOrderList(this.$t("order_status.order-re-weighed"));
                  break;
                case 10:
                  this.goOrderList(this.$t("order_status.compensation_order"));
                  break;
                case 11:
                  this.goOrderList(this.$t("order_status.fail_delivery"));
                  break;
                default:
                  break;
              }
            },
          },
        },
        colors: ["#4A50E2", "#D4D4D4", "#E7F4FD", "#FF4A55", "#31BB50", "#83563D", "#A2C615", "#FBE0CC", "#FF8C43", "#DE1BE2", "#016CB9", "#F6F4FF"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 500,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        tooltip: {
          enabled: true,
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            let total = 0;
            series.forEach((item) => {
              total += item;
            });
            return (
              '<div class="d-flex flex-column py-2 px-8" style="background-color: #343743; color: #ffffff">' +
              "<span>" +
              w.globals.labels[seriesIndex] +
              ": " +
              "<span class='font-weight-bold'>" +
              series[seriesIndex] +
              "</span>" +
              " (" +
              ((series[seriesIndex] / total) * 100).toFixed(1) +
              "%)" +
              "</div>"
            );
          },
        },
      };
    },
  },
  watch: {
    datesPicker(val) {
      if (val.length === 1) {
        this.startDateSearch = val[0];
      } else {
        this.startDateSearch = null;
      }

      // validate dates
      this.errorDateFromTo = "";
      if (
        this.datesPicker[0] !== undefined &&
        this.datesPicker[1] !== undefined
      ) {
        const selectDate = moment(this.datesPicker[1], constants.formatDateYmd);
        const timeExprired = moment(
          this.datesPicker[0],
          constants.formatDateYmd
        );
        this.createDateFrom = this.datesPicker[0];
        this.createDateTo = this.datesPicker[1];
        const diff = selectDate.diff(timeExprired, "days");
        if (diff < 0) {
          this.errorDateFromTo = "Error 1";
        } else if (diff > 365) {
          this.errorDateFromTo = "Error 2";
        } else {
          this.errorDateFromTo = "";
        }
      }
      this.datesPickerFm = [];
      if (val.length > 0) {
        val.forEach((item) => {
          const newItem = this.formatDate(item, constants.formatDateMonth);
          this.datesPickerFm.push(newItem);
        });
      }
    },
    lineEndTime() {
      this.isDisabledButtonNextWeek = false;
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentDay = today.getDate();
      const lineEndMonth = moment(
        this.lineEndTime,
        constants.formatDatePlaceholder
      ).format("MM");
      const lineEndDay = moment(
        this.lineEndTime,
        constants.formatDatePlaceholder
      ).format("DD");
      if (
        (lineEndDay > currentDay && lineEndMonth > currentMonth) ||
        lineEndMonth > currentMonth
      ) {
        this.isDisabledButtonNextWeek = true;
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions("layout", ["setOrderCreate", "setBreadcrumbs"]),
    async initData() {
      this.initDate();
      this.getDashBoard()
    },
    initDate() {
      var today = new Date();
      this.currentYear = today.getFullYear();
      this.today =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      this.circleStartTime = this.startTime
        ? this.convertDateFormat(this.startTime)
        : null;
      this.circleEndTime = this.endTime
        ? this.convertDateFormat(this.endTime)
        : null;
      this.lineStartTime = this.startTime
        ? this.convertDateFormat(this.startTime)
        : null;
      this.lineEndTime = this.endTime
        ? this.convertDateFormat(this.endTime)
        : null;
    },
    onShowDialogCreateOrderOption() {
      this.isShowCreateOrderOptions = true;
    },
    onCloseDialog() {
      this.isShowCreateOrderOptions = false;
    },
    goOrderCreate() {
      this.activePackage = true;
      this.activePackages = false;
      this.activeMultiBox = false
      this.isShowCreateOrderOptions = false;
      const pathName = StringUtils.getPathNameByPath(
        this.$router.currentRoute.path
      );
      if (pathName !== routePaths.ORDER_CREATE) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE);
        this.setOrderCreate(true);
        this.$router.push(routePaths.ORDER_CREATE);
      }
    },
    goOrderCreateMultiple() {
      this.activePackage = false;
      this.activePackages = true;
      this.activeMultiBox = false
      this.isShowCreateOrderOptions = false;
      const pathName = StringUtils.getPathNameByPath(
        this.$router.currentRoute.path
      );
      if (pathName !== routePaths.ORDER_CREATE_MULTIPE) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE_MULTIPE);
        this.setOrderCreate(true);
        this.$router.push(routePaths.ORDER_CREATE_MULTIPE);
      }
    },
    goOrderCreateMultiBox() {
      this.activePackage = false
      this.activePackages = false
      this.activeMultiBox = true
      this.isShowCreateOrderOptions = false
      const pathName = StringUtils.getPathNameByPath(this.$router.currentRoute.path)
      if ((pathName !== routePaths.ORDER_CREATE_MULTI_BOX)) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE_MULTI_BOX)
        this.setOrderCreate(true)
        this.$router.push(routePaths.ORDER_CREATE_MULTI_BOX)
      }
    },
    allowedDates(val) {
      if (this.startDateSearch) {
        return val >= this.startDateSearch && val <= this.today;
      } else {
        return val <= this.today;
      }
    },
    onInputDateRange(val) {
      if (!val) {
        this.datesPicker = [];
      }
    },
    onChangeDates() {
      if (this.datesPicker.length === 2) {
        this.$refs.menuDateFromTo.save(this.datesPicker);
        this.circleStartTime = moment(this.datesPicker[0]).format(
          constants.formatDatePlaceholder
        );
        this.circleEndTime = moment(this.datesPicker[1]).format(
          constants.formatDatePlaceholder
        );
        this.getDashBoard();
      }
    },
    changeTypeCalendar() {
      this.clearDate();
      if (this.circleType === constants.typeOfDay.today) {
        this.dayNow = moment().format(constants.formatDatePlaceholder);
        this.circleStartTime = this.dayNow;
        this.circleEndTime = this.dayNow;
        this.getDashBoard();
      }
      if (this.circleType === constants.typeOfDay.week) {
        this.circleStartTime = this.convertDateFormat(this.startTime);
        this.circleEndTime = this.convertDateFormat(this.endTime);
        this.getDashBoard();
      }
      if (this.circleType === constants.typeOfDay.month) {
        this.circleStartTime = moment()
          .clone()
          .startOf("month")
          .format(constants.formatDatePlaceholder);
        this.currentMonth = moment(
          this.circleStartTime,
          constants.formatDatePlaceholder
        ).format("MM/YYYY");
        this.circleEndTime = moment()
          .clone()
          .endOf("month")
          .format(constants.formatDatePlaceholder);
        this.getDashBoard();
      }
      if (this.circleType === "all") {
        this.circleStartTime = null;
        this.circleEndTime = null;
        this.getDashBoard();
      }
    },
    onChangePartner() {
      this.getDashBoard()
    },
    clearCircleData() {
      this.listCod = [0, 0, 0, 0, 0];
      this.series = [];
      this.totalPieChartData = 0;
    },
    formatDecimal(val) {
      return val !== undefined ? StringUtils.formatDecimal(val) : ''
    },
    clearDate() {
      this.datesPicker = [];
    },
    getParamDashBoard(val) {
      this.totalValue = val.totalValue
      this.totalSuccessDelivery = val.totalSuccessDelivery
      this.totalReturnedSender = val.totalReturnedSender
      this.totalPieChartData = val.amount
      this.successDelivery = val.successDelivery
      this.inProcess = val.inProcess
      this.returnedSender = val.returnedSender
      this.deliveryFail = val.deliveryFail
    },
    async getDashBoard() {
      this.clearCircleData();
      const pars = {
        partnerId: this.partnerSelected,
        fromDate: this.circleStartTime
          ? moment(this.circleStartTime, constants.formatDatePlaceholder).format("YYYY-MM-DD")
          : null,
        toDate: this.circleEndTime
          ? moment(this.circleEndTime, constants.formatDatePlaceholder).format("YYYY-MM-DD")
          : null,
      };
      const { status, data } = await OrderService.getDashBoard(pars);
      if (status === constants.statusCode.ok) {
        this.dataDashBoard = data;
        if (data) {
          this.items = data.circleStatisticInfoDtos
          this.getParamDashBoard(data)
          this.chartOptions.labels.forEach((item, index) => {
            var findItem = data.circleStatisticInfoDtos.find((it) => it.statusDescription === item);
            if (findItem !== undefined) {
              this.series.push(findItem.amount);
            } else {
              this.series.push(0);
            }
          });
        } else {
          this.items = []
        }
      } else {
        this.series = [0, 0, 0, 0, 0];
      }
    },
    getColorStatus(item) {
      switch (item) {
        case constants.orderStatus.cancelled:
          return '#D4D4D4'
        case constants.orderStatus.waiting:
          return '#4A50E2'
        case constants.orderStatus.delivering:
          return '#E7F4FD'
        case constants.orderStatus.pickUpFailed:
          return '#FF4A55'
        case constants.orderStatus.successfulDelivery:
          return '#31BB50'
        case constants.orderStatus.completed:
          return '#83563D'
        case constants.orderStatus.refundingToSender:
          return '#A2C615'
        case constants.orderStatus.refundedToSender:
          return '#F6F4FF'
        case constants.orderStatus.parcelWeight:
          return '#FF8C43'
        case constants.orderStatus.orderReweighed:
          return '#016CB9'
        case constants.orderStatus.failDelivery:
          return '#FBE0CC'
        default:
          return ''
      }
    },
    clearLineData() {
      this.lineSeriesInit = [
        {
          name: "Đơn hàng",
          type: "column",
          data: [],
        },
        {
          name: "COD",
          type: "line",
          data: [],
        },
      ];
      this.lineSeries = [];
      this.lineChartOptions.labels = [];
    },
    convertDateFormat(val) {
      return moment(val, constants.formatDatePlaceholder).format(
        constants.formatDatePlaceholder
      );
    },
    convertDateMonthFormat(val) {
      return moment(val, constants.formatDatePlaceholder).format(
        constants.formatDateMonth
      );
    },
    convertPrice(val) {
      if (val !== 0 && val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    formatDate(strDate, format) {
      if (typeof strDate === "undefined") return "";
      return moment.utc(strDate).format(format);
    },
    calculatePercentage(val) {
      return ((val / this.totalPieChartData) * 100).toFixed(1);
    },
    goOrderList(type) {
      this.addBreadCrumbs(routePaths.ORDER_LIST);
      this.$router.push({
        path: type === this.$t("order_status.fail_delivery") ? routePaths.LIST_ORDER_PROCESS : routePaths.ORDER_LIST,
        query: {
          type: type,
          fromDate: this.circleStartTime,
          toDate: this.circleEndTime,
        },
      });
    },
    addBreadCrumbs(pathActive) {
      const breadCrumbs =
        DataBreadCrumbUtils.getBreadCrumbByPathActive(pathActive);
      this.setBreadcrumbs(breadCrumbs);
    },
  },
};
</script>
<!-- <style src="@/styles/dashboard.scss" lang="scss" /> -->

<style lang="scss" scoped>
#dashboard {
  margin: -16px -12px;
  background: #e5e5e5;
  flex: 1;
  display: flex;
  flex-direction: column;
  .description-section {
    background-color: #0f90e8;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
  .box-search {
    display: flex;
    justify-content: flex-end;
  }
  .wrap-chart {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    margin-top: 16px;
    background: #ffffff;
    border-radius: 8px;
    .wrap-cart-item-first {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 10px 0px 0px 0px;

      .wrap-cart-item {
        padding: 15px;
        min-width: 362px;
        min-height: 114px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        flex: 1;
      }
      .wrap-cart-item-piachar {
        padding: 15px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        width: 100%;
      }
      .count-item {
        font-weight: 800;
        font-size: 36px;
        line-height: 50px;
        color: #22242c;
        display: flex;
        align-items: center;
      }

    }
    .name-item {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        text-transform: capitalize;
        color: #22242c;
      }
  }
}

</style>
