<template>
  <div class="text-center empty-box">
    <div v-if="loading">
      <v-progress-circular
        indeterminate
        :size="35"
        :width="1"
        color="primary"
      />
    </div>
    <v-layout v-else column>
      <span class="text--body-5-18">{{ $t('empty_data') }}</span>
      <span v-if="showSubtitle" class="text--body-4-12 color-gray">
        {{ $t(subtitle) }}
      </span>
      <v-img contain src="@/assets/icons/empty.svg" max-width="100%" height="200px" />
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    showSubtitle: {
      type: Boolean,
      default: true
    },
    subtitle: {
      type: String,
      default: 'empty.note'
    }
  }
}
</script>

<style lang="scss" scoped></style>
