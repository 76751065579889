export default {
  login: 'cust/signin',
  revoke: 'cust/signout',
  register: 'cust/signup',
  user_info: 'cust/info',
  otp_verify: 'cust/otp/verify',
  otp_resend: 'cust/otp/resend',
  address_get_all: 'cust/address/get-all',
  address_get_default: 'cust/address/get-default',
  address_create: 'cust/address/create',
  address_update: 'cust/address/update',
  address_delete: 'cust/address/delete',
  forgot_password: 'cust/forgot-password/otp-send',
  forgot_password_verify_otp: 'cust/forgot-password/verity-otp',
  forgot_password_update: 'cust/forgot-password/update',
  update_shop: 'cust/update-shop',

  change_password_check: 'cust/change-password/check-password',
  change_password_update: 'cust/change-password/update-password',

  get_delivery_notes: 'common/role/get-delivery-notes',

  province_all: 'province/findAll',
  find_all_districts: 'district/findAllDistricts',
  find_all_communes: 'commune/findAllCommunes',
  get_logistic_partner: 'common/get-services-partners',

  create_orders: 'cust/orders/create',
  calculate_ship_fee: 'cust/orders/calculate-all-ship-fee-partners',
  multi_calculate_ship_fee: 'cust/orders/multiple-packages/calculate-all-ship-fee-partners',
  search_orders: 'cust/orders/search-orders',
  orders_status: 'cust/orders/get-all-status',
  orders_detail: 'cust/orders/orders-detail',
  orders_cancel: 'cust/orders/cancel-orders',
  get_last_transport_type: 'cust/orders/getLastTransportType',
  get_receiver_info_by_phone: 'cust/orders/getReceiverInfoByPhone',
  get_circle_statistic: 'cust/orders/shop/circleStatistic',
  get_line_statistic: 'cust/orders/shop/lineStatistic',

  create_multiple_orders: 'cust/orders/create-multi-orders',
  orders_view_from_excel: 'cust/orders/view-from-excel',
  orders_download_template: 'cust/orders/download-order-template',

  way_bill: 'report/way-bill',
  report_files: 'report/files',

  bank_get_all: 'bank/get-all',
  bank_get_account: 'bank/get-account',
  bank_update_account: 'bank/update-account',
  comparison_get_all: 'comparison/get-all',

  get_all_district: 'district/findAll',
  get_all_commune: 'commune/findAll',

  get_find_for_user_Id: 'common/services/findForUserId',
  get_establish_service_by_partner: 'common/feetag/commodity/findByPartnerId',
  get_all_pick_work_ship: 'common/get-all-pick-work-ship',
  check_refer_code: 'cust/check-refer-code',
  create_multi_packages: 'cust/orders/create-multi-packages',
  calculate_service_fee: 'common/cal-service-fee',

  dashboard_shop: 'cust/orders/shop/dashboard'
}
