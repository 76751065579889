<template>
  <v-dialog v-model="internalValue" :max-width="widthDialog" persistent>
    <v-card class="pa-4">
      <v-layout column>
        <v-layout justify-space-between class="mb-3">
          <h6 class="text-h6">
            {{ $t('address_send_order') }}
          </h6>
          <v-icon @click="onClose">mdi-close</v-icon>
        </v-layout>
        <v-layout>
          <v-btn
            id="add-address-from-list"
            outlined
            color="primary"
            class="rounded-lg mb-2"
            @click="onAddAddress"
          >
            <v-icon class="mr-1"> mdi-plus </v-icon>
            {{ $t('new_address') }}
          </v-btn>
        </v-layout>
        <item-address-view
          v-for="item in items"
          :key="item.id"
          :item="item"
          :on-detail="onDetailAddress"
          :on-delete="onDeleteAddress"
          :on-checked="onCheckedAddress"
        />

        <custom-pagination
          :page="page"
          :start-record="computedStartRecord"
          :end-record="computedEndRecord"
          :total-record="totalRecord"
          :total-page="totalPage"
          :on-change-page="onChangePage"
        />

        <v-layout justify-end>
          <v-btn outlined class="rounded-lg mr-2" @click="onClose">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            class="rounded-lg"
            @click="onAcceptSelectedAddress"
          >
            {{ $t('select') }}
          </v-btn>
        </v-layout>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import constants from '@/constants'
import ItemAddressView from './ItemAddressView.vue'
import CustomPagination from '@/components/CustomPagination.vue'

export default {
  name: 'DialogAddressList',
  components: {
    ItemAddressView,
    CustomPagination
  },
  props: {
    widthDialog: {
      type: Number,
      default: 540
    },
    value: Boolean,
    items: {
      type: Array,
      default: null
    },
    onAddAddress: {
      type: Function,
      default: null
    },
    onDetailAddress: {
      type: Function,
      default: null
    },
    onDeleteAddress: {
      type: Function,
      default: null
    },
    onAcceptSelectedAddress: {
      type: Function,
      default: null
    },
    onSelectedAddress: {
      type: Function,
      default: null
    },
    totalPage: {
      type: Number,
      default: 0
    },
    totalRecord: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    onChangePage: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      internalValue: this.value
    }
  },
  computed: {
    computedStartRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.itemsPerPageHalf + 1
      } else {
        return 0
      }
    },
    computedEndRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.itemsPerPageHalf + this.items.length
      } else {
        return (this.page - 1) * constants.itemsPerPageHalf
      }
    }
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value(val, oldVal) {
      if (val === oldVal) return

      this.internalValue = val
    }
  },
  methods: {
    onClose() {
      this.$emit('input', false)
      this.onSelectedAddress(null)
    },
    onCheckedAddress(selected) {
      this.items.forEach((i) => {
        if (i.id !== selected.id) {
          i.checked = false
        }
      })

      this.onSelectedAddress(selected)
    },
    onchange(val) {
      this.onChangePage(val)
    }
  }
}
</script>
