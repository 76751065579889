<template>
  <div>
    <v-layout column>
      <v-row>
        <v-col cols="sm-6" :class="showAllTab ? 'col-12 col-md-4 pb-0 pb-md-4' : 'col-12 col-md-5 pb-0 pb-md-4'">
          <span>
            {{ $t('code-name-phone') }}
          </span>
          <v-text-field
            v-model="objSearch"
            outlined
            dense
            hide-details
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="`${$t('bill_of_lading_code')}/${$t('name')}/${$t(
              'receiver_phone_title'
            )}`"
            @keydown.enter="onClickSearch"
          />
        </v-col>
        <v-col cols="sm-6" :class="showAllTab ? 'col-12 col-md-4 pb-0' : 'col-12 col-md-5 pb-0'">
          <span>
            {{ $t('group-shipping') }}
          </span>
          <v-select
            v-model="partnerSelected"
            outlined
            dense
            hide-details
            class="select-groups"
            :placeholder="$t('place_holders.choice_group_shipping')"
            solo
            :items="lstPartner"
            item-text="partnerName"
            item-value="partnerId"
          />
        </v-col>
        <v-col v-if="showAllTab" cols="sm-6" class="col-12 col-md-4 pb-0">
          <span v-if="showAllTab">
            {{ $t('status-order') }}
          </span>
          <v-select
            v-if="showAllTab"
            v-model="statusSelected"
            outlined
            dense
            hide-details
            :placeholder="$t('place_holders.status_order')"
            :items="statusItems"
            item-text="name"
            item-value="code"
            multiple
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="toggleStatusSearch"
              >
                <v-list-item-action>
                  <v-icon :color="statusSelected.length > 0 ? 'primary' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("all") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ index }">
              <label v-if="statusSelected && index === 0">
                {{ $t("selectd_status") }}
                <span class="grey--text text-caption">
                  ({{ statusSelected.length }})
                </span>
              </label>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="sm-6" class="col-12 col-md-5 pb-0 pb-md-4">
          <span>
            {{ $t('create-date') }}
          </span>
          <DateRangerPicker
            ref="datePickerCreate"
            @setDateFrom="setFromDateCreate"
            @setDateTo="setToDateCreate"
          />
        </v-col>
        <v-col cols="sm-6" class="col-12 col-md-5 pb-0">
          <span>
            {{ $t('last-action') }}
          </span>
          <DateRangerPicker
            ref="datePickerEnd"
            @setDateFrom="setFromDateEnd"
            @setDateTo="setToDateEnd"
          />
        </v-col>
        <v-col align-self="end" class="mb-1 col-2">
          <div style="text-align-last: end">
            <v-btn style="min-width: 150px" color="primary" @click="onClickSearch">
              {{ $t("search") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-layout v-if="countSelected > 0" align-center class="my-2 xs-column">
        <label class="mr-4">
          {{ $t("selected") }}:
          {{ countSelected }}
        </label>
        <v-radio-group
          v-model="typeExportSelected"
          row
          class="type-export-select my-0 py-0"
        >
          <v-radio label="Excel" :value="typeExportConst.excel" class="my-0" />
          <v-radio label="CSV" :value="typeExportConst.csv" class="my-0" />
        </v-radio-group>
        <v-btn
          color="primary"
          outlined
          class="mr-4 mb-2 mb-sm-0"
          @click="handleDownload"
        >
          <v-icon size="20" class="mr-2">mdi-tray-arrow-down</v-icon>
          {{ $t("export_excel") }}
        </v-btn>
        <v-btn
          v-if="isShowBtnCancel"
          color="primary"
          outlined
          class="mr-4 mb-2 mb-sm-0"
          @click="onShowConfirmCancelItems"
        >
          <v-icon size="20" class="mr-2">mdi-close-box-outline</v-icon>
          {{ $t("cancel_order") }}
        </v-btn>
        <v-btn
          v-if="isShowBtnPrint"
          color="primary"
          @click="onViewWayBillNewTab"
        >
          <v-icon size="20" class="mr-2">mdi-printer-outline</v-icon>
          {{ $t("print_bill_of_lading") }}
        </v-btn>
      </v-layout>
      <v-divider style="border-color: red" class="mt-2" />
      <v-layout class="pb-1">
        <v-tabs v-model="indexTab" color="black" class="change-color-tab" fixed-tabs show-arrows>
          <v-tab v-for="(item, index) in statusList" :key="index" class="tab-status" @change="searchList(item.code, index)">
            <span class="text-transform-none" style="color:black">{{ item.name }}</span>
            <span v-if="item.totalCount" style="color: red">({{ item.totalCount }})</span>
          </v-tab>
        </v-tabs>
      </v-layout>

      <div
        class="box-list-content-order pt-4"
      >
        <v-data-table
          id="virtual-scroll-table"
          v-model="selected"
          v-scroll:#virtual-scroll-table="onScroll"
          show-select
          class="border-primary"
          hide-default-footer
          :fixed-header="true"
          height="59vh"
          :no-data-text="$t('not_found')"
          :headers="headers"
          :header-props="{ sortIcon: 'mdi-menu-swap' }"
          :items="items"
          :items-per-page="items.length"
        >
          <template v-slot:[`item.deliveryCode`]="{ item }">
            <td class="max-width-6 text-truncate">
              <a
                :href="getHrefLinkById(item.id)"
                target="_blank"
                class="text-decoration-none"
              >
                {{ item.deliveryCode }}
              </a>
            </td>
          </template>
          <template v-slot:[`item.shopName`]="{ item }">
            <td class="max-width-3 text-truncate">
              {{ item.shopName }}
            </td>
          </template>
          <template v-slot:[`item.receiverName`]="{ item }">
            <td class="max-width-4 text-truncate">
              {{ item.receiverName }}
            </td>
            <tr class="text--body-4-12">
              <v-icon size="12">mdi-phone-in-talk-outline</v-icon>
              {{ item.receiverPhone }}
            </tr>
          </template>
          <template v-slot:[`item.partnerName`]="{ item }">
            <tr class="max-width-3 text-truncate">
              {{ item.partnerName }}
            </tr>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDateView(item.createdAt) }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatDateView(item.updatedAt) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <td class="max-width-3 text-truncate">
              {{ item.statusDescription }}
            </td>
          </template>
          <template v-slot:[`item.codValue`]="{ item }">
            <td class="max-width-5 text-truncate">
              {{ formatDecimalView(item.codValue) }}
            </td>
          </template>
          <template v-slot:[`item.paymentBy`]="{ item }">
            <td class="max-width-5 text-truncate">
              {{ getPaymentByText(item.paymentBy) }}
            </td>
          </template>
          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              :indeterminate="props.indeterminate"
              color="primary"
              v-on="on"
            />
          </template>
          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
              v-ripple
              color="primary"
              :value="isSelected"
              @input="select($event)"
            />
          </template>
        </v-data-table>
      </div>
      <v-row justify-space-between align-center class="mx-0 mt-0">
        <v-layout align-center class="col-12 col-sm-3 col-md-6 pb-0">
          <label class="label-center">
            {{ $t("display") }} {{ computedStartRecord }} -
            {{ computedEndRecord }} /
            {{ totalRecord }}
          </label>
        </v-layout>
      </v-row>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <!-- dialog confirm cancel order -->
    <dialog-confirm
      v-model="showConfirmCancel"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAccept"
      :on-cancel="onCancelConfirmCancel"
      :on-accept="onAcceptConfirmCancel"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </div>
</template>

<script>
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import DateRangerPicker from "@/views/order/components/DateRangerPicker"
import { StringUtils } from "@/helpers/stringUtils";
import { DateTimeUtils } from "@/helpers/dateTimeUtils";
import { DataUtils } from "@/helpers/dataUtils";
import constants from "@/constants";
import { OrderService } from "@/services/orderService";
import routePaths from "@/router/routePaths";
import moment from "moment";
import mixinStatusOrder from "./mixins/mixinStatusOrder"
import { StorageService } from "@/services/storageService";
import { ReportService } from "@/services/reportService";
import { BaseService } from "@/services/baseService";

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirm,
    DialogConfirmTokenExpried,
    DateRangerPicker
  },
  mixins: [mixinStatusOrder],
  data() {
    return {
      serviceFeePayersConst: constants.serviceFeePayers,
      objSearch: "",
      menu: false,
      dates: [],
      datesFm: [],
      statusSelected: [],
      statusItems: [],

      selected: [],
      headers: [
        {
          text: this.$t("bill_of_lading_code"),
          value: "deliveryCode",
          width: "18%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("receiver_name"),
          value: "receiverName",
          width: "12%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("group-shipping"),
          sortable: false,
          value: "partnerName",
          width: "10%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("createdTime"),
          value: "createdAt",
          width: "14%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("last_action"),
          value: "updatedAt",
          width: "14%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("status"),
          value: "status",
          width: "14%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("money_cod"),
          sortable: false,
          value: "codValue",
          width: "8%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("human_payment"),
          sortable: false,
          value: "paymentBy",
          width: "8%",
          class: "text-subtitle-2",
        },
      ],
      items: [],
      totalPage: 0,
      page: 1,
      totalRecord: 0,
      itemsPerPage: constants.itemsPerPage,

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",

      showLoading: false,

      showConfirmCancel: false,
      textCancel: this.$t("cancel"),
      textAccept: this.$t("cancel_order"),

      showConfirmTokenExpried: false,

      timeout: null,
      typeExportConst: constants.typeExport,
      typeExportSelected: constants.typeExport.excel,
      headingExport: [
        [
          "Mã vận đơn",
          "Thời gian tạo",
          "Thao tác cuối",
          "Trạng thái đơn hàng",
          "Địa chỉ gửi hàng",
          "Tỉnh gửi hàng",
          "Địa chỉ nhận hàng",
          "Tỉnh nhận hàng",
          "Tên người nhận",
          "SĐT người nhận",
          "Khối lượng khi tạo đơn",
          "Khối lượng tính phí",
          "Tiền COD",
          "Phí vận chuyển",
          "Phí dịch vụ",
          "Tổng phí dịch vụ",
          "Bảo hiểm",
          "Phí bảo hiểm",
          "Đối tượng trả phí dịch vụ",
          "Tổng thu người nhận",
        ],
      ],
      statusType: this.$route.query.type,
      fromDate: this.$route.query.fromDate,
      toDate: this.$route.query.toDate,
      partnerSelected: null,
      lstPartner: [],
      fromDateCreate: null,
      toDateCreate: null,
      fromDateEnd: null,
      toDateEnd: null,
      statusList: [
        {
          code: null,
          name: this.$t("all"),
          totalCount: null
        },
        {
          code: 0,
          name: this.$t("order_status.waiting"),
          totalCount: null
        },
        {
          code: 3,
          name: this.$t("order_status.completed"),
          totalCount: null
        },
        {
          code: 1,
          name: this.$t("order_status.delivering"),
          totalCount: null
        },
        {
          code: 4,
          name: this.$t("order_status.waiting_delivery"),
          totalCount: null
        },
        {
          code: 2,
          name: this.$t("order_status.successful_delivery"),
          totalCount: null
        },
        {
          code: 6,
          name: this.$t("order_status.refunding_to_sender"),
          totalCount: null
        },
        {
          code: 7,
          name: this.$t("order_status.refunded_to_sender"),
          totalCount: null
        },
        {
          code: 5,
          name: this.$t("order_status.pickup_failed"),
          totalCount: null
        },
        {
          code: 8,
          name: this.$t("order_status.compensation_order"),
          totalCount: null
        },
        {
          code: -1,
          name: this.$t("order_status.cancelled"),
          totalCount: null
        },
      ],
      returnPartPackage: null,
      currentTab: null,
      showAllTab: true,
      statusSelectItem: "",
      showTabStatus: false,
      indexTab: 0,
      showData: false
    };
  },
  computed: {
    minAllowedDate() {
      if (this.dates && this.dates.length === 1) {
        return this.dates[0];
      }
      return null;
    },
    maxAllowedDate() {
      return moment().format(constants.formatDateYmd);
    },
    dateRangeText() {
      return this.datesFm.join(" - ");
    },
    computedStartRecord() {
      if (this.items !== null && this.items.length > 0) {
        return 1;
      } else {
        return 0;
      }
    },
    computedEndRecord() {
      return this.items.length;
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
    countSelected() {
      return this.selected.length;
    },
    isShowBtnDownload() {
      return this.selected.length > 0;
    },
    isShowBtnCancel() {
      if (this.countSelected === 0) return false;
      const countStatus = this.selected.filter(
        (x) => x.status === constants.orderStatus.waiting
      ).length;
      var statusCheck = countStatus === this.countSelected;
      if (!statusCheck) {
        return statusCheck;
      }
      const ghtkOrder = this.selected.find(
        (x) => x.partnerId === constants.partnerGroup.ghtk
      )
      if (ghtkOrder !== undefined && ghtkOrder !== null) {
        return false;
      }
      return true;
    },
    isShowBtnPrint() {
      if (this.countSelected === 0) return false;

      const countStatus = this.selected.filter(
        (x) => x.status === constants.orderStatus.waiting
      ).length;
      return countStatus === this.countSelected;
    },
    getFileName() {
      return `export_orders_${DateTimeUtils.formatDateTime(
        new Date(),
        constants.formatDateTimeInt
      )}.${this.typeExportSelected}`;
    },
    likesAllStatus() {
      return this.statusSelected.length === this.statusItems.length;
    },
    likesSomeStatus() {
      return this.statusSelected.length > 0 && !this.likesAllStatus;
    },
    icon() {
      if (this.likesAllStatus) return "mdi-close-box";
      if (this.likesSomeStatus) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    computedHeightTable() {
      const box = document.getElementById("virtual-scroll-table");
      return box.offsetHeight;
    },
    isLoadMore() {
      return this.items.length < this.totalRecord;
    },
  },
  watch: {
    dates(val) {
      const newDates = [];
      val.forEach((i) => {
        const item = this.formatDate(i);
        newDates.push(item);
      });
      this.datesFm = newDates;
    },
  },
  created() {
    if (localStorage.getItem('reloaded')) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem('reloaded');
      this.showData = true
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem('reloaded', '1');
      location.reload();
    }
    this.initData();
  },
  methods: {
    async initData() {
      await Promise.all([
        this.getDateCreated(),
        this.getListStatus(),
        this.getPartners(),
      ]);
      this.searchOrders();
    },
    async getPartners() {
      try {
        const { status, data } = await BaseService.getLogisticPartner();
        if (
          status === constants.statusCode.ok &&
          data !== null &&
          data.length > 0
        ) {
          this.lstPartner = data.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.partnerId === item.partnerId)
          );
          this.lstPartner.unshift({
            partnerId: null,
            partnerName: "Tất cả",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    onClickSearch() {
      document.getElementById("virtual-scroll-table").scrollTo(0, 0);
      this.page = 1;
      this.selected = [];
      this.currentTab
      this.searchOrders();
    },
    toggleStatusSearch() {
      this.$nextTick(() => {
        if (this.likesAllStatus) {
          this.statusSelected = [];
        } else {
          this.statusSelected = this.bindStatusCodes(this.statusItems);
        }
      });
    },
    bindStatusCodes(items) {
      const results = [];
      items.forEach((item) => {
        results.push(item.code);
      });
      return results;
    },
    async searchOrders() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      const { status, data } = await OrderService.searchOrders(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        this.totalRecord = data.totalRecord;
        setTimeout(() => {
          if (this.statusList[this.currentTab] === undefined) {
            this.statusList[0].totalCount = this.totalRecord
          } else {
            this.statusList[this.currentTab].totalCount = this.totalRecord
          }
        }, 200)
        this.totalPage = DataUtils.calculatePageByItemsPerPage(
          data.totalRecord,
          this.itemsPerPage
        );
        if (this.page > 1) {
          this.items = this.items.concat(data.data);
        } else {
          this.items = data.data ? data.data : [];
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
        setTimeout(() => {
          this.toggleDialogNoti();
        }, constants.timeOut);
      }
    },
    bindRequestPars() {
      const pars = {
        currentPage: this.page,
        limit: constants.pageSizeMax,
        objSearch: "",
        custId: StorageService.getUserId(),
      };

      if (this.objSearch) {
        pars.objSearch = this.objSearch.trim();
      }

      if (this.dates[0]) {
        pars["fromDate"] = this.dates[0];
      }

      if (this.dates[1]) {
        pars["toDate"] = this.dates[1];
      }

      if (this.fromDateCreate) {
        pars["fromDate"] = this.fromDateCreate;
      }
      if (this.toDateCreate) {
        pars["toDate"] = this.toDateCreate;
      }

      if (this.fromDateEnd) {
        pars["fromUpdateDate"] = this.fromDateEnd;
      }
      if (this.toDateEnd) {
        pars["toUpdateDate"] = this.toDateEnd;
      }
      if (this.partnerSelected) {
        pars["partnerId"] = this.partnerSelected
      }

      if (this.statusSelected.length > 0) {
        pars["statusList"] = this.statusSelected;
      }
      if (this.statusSelectItem && this.statusSelectItem.length < 2) {
        pars["statusList"] = this.statusSelectItem;
      }
      if (this.returnPartPackage) {
        pars["returnPartPackage"] = 1
      }

      return pars;
    },
    async searchList(item, index) {
      if (!item) {
        this.statusSelected = []
      }
      this.page = 1;
      this.currentTab = index;
      if (item === 99) {
        this.showAllTab = false
        this.returnPartPackage = 1
        this.statusSelectItem = ''
      } else if (item !== null && item !== "undefined" && item !== 99) {
        this.showAllTab = false
        this.statusSelectItem = [item]
        this.returnPartPackage = ''
      } else if (item === null) {
        this.showAllTab = true
        this.statusSelectItem = ''
        this.returnPartPackage = ''
      }
      await this.searchOrders()
      this.statusList.map(it => { it.totalCount = null })
      this.statusList[this.currentTab].totalCount = this.totalRecord
    },

    getDateCreated() {
      if (this.fromDate && this.toDate) {
        this.datesFm[0] = this.fromDate;
        this.datesFm[1] = this.toDate;
        this.dates[0] = moment(this.fromDate, constants.formatDatePlaceholder).format(constants.formatDateYmd);
        this.dates[1] = moment(this.toDate, constants.formatDatePlaceholder).format(constants.formatDateYmd);
        setTimeout(() => {
          if (this.$refs.datePickerCreate) {
            this.$refs.datePickerCreate.setDateCreate(this.fromDate, this.toDate)
          }
        }, 200);
      }
    },
    async getListStatus() {
      this.statusSelected = [];
      var { status, data } = await OrderService.getListStatus();
      if (status === constants.statusCode.ok && data.length > 0) {
        this.statusItems = data;
        if (this.statusType) {
          if (this.statusType === this.$t("order_status.waiting")) {
            data = data.filter((item) => item.code === 0);
            this.indexTab = 1
          }
          if (this.statusType === this.$t("order_status.cancelled")) {
            data = data.filter((item) => item.code === -1);
            this.indexTab = 9
          }
          if (this.statusType === this.$t("order_status.delivering")) {
            data = data.filter((item) => item.code === 1);
            this.indexTab = 3
          }
          if (this.statusType === this.$t("order_status.pickup_failed")) {
            data = data.filter((item) => item.code === 5);
            this.indexTab = 7
          }
          if (this.statusType === this.$t("order_status.successful_delivery")) {
            data = data.filter((item) => item.code === 2);
            this.indexTab = 5
          }
          if (this.statusType === this.$t("order_status.completed")) {
            data = data.filter((item) => item.code === 3);
            this.indexTab = 2
          }
          if (this.statusType === this.$t("order_status.refunding_to_sender")) {
            data = data.filter((item) => item.code === 6);
            this.indexTab = 5
          }
          if (this.statusType === this.$t("order_status.refunded_to_sender")) {
            data = data.filter((item) => item.code === 7);
            this.indexTab = 6
          }
          if (this.statusType === this.$t("order_status.waiting_delivery")) {
            data = data.filter((item) => item.code === 4);
            this.indexTab = 4
          }
          if (this.statusType === this.$t("order_status.order-re-weighed")) {
            data = data.filter((item) => item.code === 9);
          }
          if (this.statusType === this.$t("order_status.compensation_order")) {
            data = data.filter((item) => item.code === 8);
            this.indexTab = 8
          }
          if (this.statusType === this.$t("order_status.fail_delivery")) {
            data = data.filter((item) => item.code === 10);
          }
          data.forEach((item) => {
            this.statusSelected.push(item.code)
          })
        }
        this.statusSelected.forEach((it) => {
          this.searchList(it, this.indexTab)
        })
      }
    },
    onSelectedDates() {
      this.$refs.menu.save(this.dates);
    },
    formatDate(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate);
    },
    parseDate(date) {
      return DateTimeUtils.parseDate(date);
    },
    onShowConfirmCancelItems() {
      if (!this.checkStatusOrdersBeforeCancel()) {
        this.message = this.$t("are_you_cancel_orders");
        this.typeNoti = constants.typeAlert.warning;
        this.showConfirmCancel = true;
      } else {
        this.toggleDialogNoti({
          state: true,
          msg: this.$t("selected_order_invalid_status"),
        });
      }
    },
    checkStatusOrdersBeforeCancel() {
      if (this.countSelected === 0) return false;

      const arrSelected = this.selected;
      let isDiffWait = false;
      arrSelected.forEach((item) => {
        if (item.status !== constants.orderStatus.waiting) {
          isDiffWait = true;
        }
      });

      return isDiffWait;
    },
    onCancelConfirmCancel() {
      this.showConfirmCancel = false;
      this.message = "";
    },
    async onAcceptConfirmCancel() {
      this.showConfirmCancel = false;
      const items = this.selected;
      if (items !== null && items.length > 0) {
        this.showLoading = true;
        const pars = this.bindRequestOrdersCancel(items);
        const { status, data } = await OrderService.cancel(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok) {
          this.selected = [];
          await this.searchOrders();
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true;
        } else {
          this.toggleDialogNoti({
            state: true,
            msg: data.message,
          });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, constants.timeOut);
        }
      }
    },
    bindRequestOrdersCancel(items) {
      const ids = [];
      items.forEach((item) => {
        ids.push(item.id);
      });
      const pars = {
        ordersIds: ids,
      };

      return pars;
    },
    async onViewWayBillNewTab() {
      this.showLoading = true;
      const items = this.selected;
      const pars = this.bindRequestOrdersCancel(items);
      const { status, data } = await ReportService.wayBill(pars);
      if (status === constants.statusCode.ok) {
        await this.downloadFile(data.url);
        this.showLoading = false;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.showLoading = false;
        this.toggleDialogNoti({
          state: true,
          msg: data.message,
        });
      }
    },
    async downloadFile(url) {
      const { status, data } = await ReportService.reportFiles(url);
      if (status === constants.statusCode.ok) {
        var fileUrl = URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        window.open(fileUrl, "_blank");
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    onInputDateRange(val) {
      if (!val) {
        this.dates = [];
      }
    },
    setFromDateCreate(val) {
      if (val) {
        this.fromDateCreate = val.split("/").reverse().join("-");
      } else {
        this.fromDateCreate = val;
      }
    },
    setToDateCreate(val) {
      if (val) {
        this.toDateCreate = val.split("/").reverse().join("-");
      } else {
        this.toDateCreate = val;
      }
    },
    setFromDateEnd(val) {
      if (val) {
        this.fromDateEnd = val.split("/").reverse().join("-");
      } else {
        this.fromDateEnd = val;
      }
    },
    setToDateEnd(val) {
      if (val) {
        this.toDateEnd = val.split("/").reverse().join("-");
      } else {
        this.toDateEnd = val;
      }
    },
    formatDateView(val) {
      return DateTimeUtils.formatDateTime(val, constants.formatDate);
    },
    formatDecimalView(val) {
      return val !== undefined ? StringUtils.formatDecimal(val) : '';
    },
    getHrefLinkById(id) {
      const routeData = this.$router.resolve({
        path: routePaths.ORDER_DETAIL,
        query: { id: id },
      });
      return routeData.href;
    },
    getPaymentByText(val) {
      return val === this.serviceFeePayersConst.sender
        ? this.$t("sender")
        : this.$t("receiver");
    },
    onScroll(e) {
      this.timeout && clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const heightContent = document.querySelector(
          "#virtual-scroll-table > .v-data-table__wrapper tbody"
        ).offsetHeight;
        const scrollTop = heightContent - this.computedHeightTable;
        if (e.target.scrollTop >= scrollTop && this.isLoadMore) {
          this.page = this.page + 1;
          this.searchOrders();
        }
      }, 20);
    },
    // export excell
    async handleDownload() {
      this.showLoading = true;
      let dataExport = [];
      if (this.selected && this.selected.length > 0) {
        dataExport = this.selected;
      } else {
        dataExport = await this.getAllRecordByCondition();
      }
      const results = this.prepareDataExport(dataExport);
      const XLSX = require("xlsx");
      const workSheet = XLSX.utils.json_to_sheet(results);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(workSheet, this.headingExport);

      // Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(workSheet, results, {
        origin: "A2",
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");
      XLSX.write(workBook, {
        bookType: this.typeExportSelected,
        type: "buffer",
      });
      XLSX.write(workBook, {
        bookType: this.typeExportSelected,
        type: "binary",
      });
      XLSX.writeFile(workBook, this.getFileName);
      this.showLoading = false;
    },
    async getAllRecordByCondition() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      pars.currentPage = 1;
      pars.limit = constants.maximumRecordExport;
      const { status, data } = await OrderService.searchOrders(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        return data.data;
      } else {
        return [];
      }
    },
    prepareDataExport(arrData) {
      const results = [];
      arrData.forEach((item) => {
        var checkAddress = item.receiverAddress.includes(",")
        const newItem = {
          deliveryCode: item.deliveryCode,
          createdAt: DateTimeUtils.formatDateTime(
            item.createdAt,
            constants.formatDate
          ),
          updatedAt: DateTimeUtils.formatDateTime(
            item.updatedAt,
            constants.formatDate
          ),
          statusDescription: item.statusDescription,
          senderAddress: item.senderAddress,
          senderProvinceName: item.senderProvinceName,
          receiverAddress: checkAddress ? item.receiverAddress : `${item.receiverAddress},${item.receiverCommuneName},${item.receiverDistrictName},${item.receiverProvinceName}`,
          receiverProvinceName: item.receiverProvinceName,
          receiverName: item.receiverName,
          receiverPhone:
            this.typeExportSelected === constants.typeExport.csv
              ? `'${item.receiverPhone}`
              : item.receiverPhone,
          weight: item.weight ? StringUtils.numStrToFloat(
            item.weight.toFixed(constants.maximumAfterDecimal)
          ) : null,
          calculateWeight: item.calculateWeight ? StringUtils.numStrToFloat(
            item.calculateWeight.toFixed(constants.maximumAfterDecimal)
          ) : null,
          codValue: item.codValue ? item.codValue : 0,
          shipValue: item.shipValue ? item.shipValue : 0,
          serviceFeesValue: item.serviceFeesValue ? item.serviceFeesValue : 0,
          totalServiceValue: item.serviceFeesValue ? item.serviceFeesValue + item.shipValue : item.shipValue
        };
        if (item.insuranceValue && item.insuranceValue !== 0) {
          newItem["assurance"] = "Có"
        } else {
          newItem["assurance"] = "Không"
        }
        if (!item.insuranceValue || item.insuranceValue !== null) {
          newItem["insuranceValue"] = item.insuranceValue !== 0 ? item.insuranceValue : ''
        }
        if (item.paymentBy === constants.serviceFeePayers.sender) {
          newItem["paymentBy"] = "Người gửi";
          newItem["totalFee"] = item.codValue ? item.codValue : 0;
        } else {
          newItem["paymentBy"] = "Người Nhận";
          newItem["totalFee"] = item.serviceFeesValue && item.codValue
            ? item.codValue + (item.serviceFeesValue + item.shipValue)
            : item.codValue ? item.codValue + item.shipValue
              : item.serviceFeesValue
                ? item.serviceFeesValue + item.shipValue : item.shipValue;
        }
        results.push(newItem);
      });
      return results;
    },
  },
};
</script>
<style lang="scss">
.select-groups {
  .v-input__control {
    .v-input__slot {
      box-shadow: none !important;
    }
  }
}
.change-color-tab {
  .v-tabs-slider {
    background-color: red !important
  }
  .v-tabs-bar{
    color: black !important;
  }
}
.minh-content {
  background: white !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
</style>
