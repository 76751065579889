<template>
  <div>
    <v-layout column>
      <v-layout justify-end class="mb-2">
        <v-btn color="primary" @click="onShowAddressCreate">
          <v-icon class="mr-2">mdi-plus</v-icon>
          {{ $t('new_address') }}
        </v-btn>
      </v-layout>
      <v-data-table
        class="border-primary"
        hide-default-footer
        :no-data-text="$t('not_found')"
        :headers="headers"
        :header-props="{ sortIcon: 'mdi-menu-swap' }"
        :items="items"
      >
        <template v-slot:[`item.name`]="{ item }">
          <td class="max-width-3 text-truncate">
            {{ item.name }}
            <span v-if="item.defaultAddress" class="error--text text-min">
              [{{ $t('default') }}]
            </span>
          </td>
        </template>
        <template v-slot:[`item.addressDetail`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.addressDetail }}
          </td>
        </template>
        <template v-slot:[`item.provinceName`]="{ item }">
          <td>
            {{
              `${item.communeName}, ${item.districtName}, ${item.provinceName}`
            }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td class="max-width-2">
            <v-icon class="mr-4" @click="onDetailAddress(item)">
              mdi-pencil-outline
            </v-icon>
            <v-icon
              v-if="!item.defaultAddress"
              @click="onShowConfirmDeleteAddress(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <custom-pagination
        :page="page"
        :start-record="computedStartRecord"
        :end-record="computedEndRecord"
        :total-record="totalRecord"
        :total-page="totalPage"
        :on-change-page="onChangePage"
      />
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />

    <!-- Dialog create and edit address -->
    <dialog-create-and-edit-address
      v-model="showCreateAndEditAddress"
      :item="itemAddress"
      :default-origin="defaultAddressOrigin"
      :lst-province="lstProvince"
      :on-address-action="onAddressCreateOrUpdate"
      :on-cancel-action="onClickCancelDialogCreateAddress"
    />

    <!-- dialog confirm del address -->
    <dialog-confirm
      v-model="showAddressConfirmDel"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAcceptAddressConfirmDel"
      :on-cancel="onCancelAddressConfirmDel"
      :on-accept="onAcceptAddressConfirmDel"
      :data="itemAddressDelete"
    />
  </div>
</template>

<script>
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import DialogConfirmTokenExpried from '@/components/dialog/DialogConfirmTokenExpried.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import DialogCreateAndEditAddress from '@/views/order/components/DialogCreateAndEditAddress.vue'
import DialogConfirm from '@/components/dialog/DialogConfirm.vue'
import constants from '@/constants'
import { StringUtils } from '@/helpers/stringUtils'
import { DateTimeUtils } from '@/helpers/dateTimeUtils'
import { UserService } from '@/services/userService'
import { DataUtils } from '@/helpers/dataUtils'
import { StorageService } from '@/services/storageService'

const initItemAddress = {
  name: '',
  phone: '',
  email: '',
  provinceCode: null,
  districtCode: null,
  communeCode: null,
  addressDetail: '',
  note: '',
  defaultAddress: false
}

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried,
    CustomPagination,
    DialogCreateAndEditAddress,
    DialogConfirm
  },
  data() {
    return {
      objSearch: '',
      regencySelected: null,
      regencyItems: [],
      headers: [
        {
          text: this.$t('fullname'),
          value: 'name',
          sortable: false,
          width: '20%',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('phone'),
          value: 'phone',
          sortable: false,
          width: '10%',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('specific_address'),
          value: 'addressDetail',
          sortable: false,
          width: '25%',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('province_district_commune'),
          value: 'provinceName',
          sortable: false,
          width: '30%',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false,
          class: 'text-subtitle-2'
        }
      ],
      items: [],
      totalPage: 0,
      page: 1,
      totalRecord: 0,

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: '',
      showLoading: false,
      showConfirmTokenExpried: false,

      showCreateAndEditAddress: false,
      itemAddress: {},
      defaultAddressOrigin: false,
      lstProvince: [],
      showAddressConfirmDel: false,
      textCancel: this.$t('cancel'),
      textAcceptAddressConfirmDel: this.$t('delete'),
      itemAddressDelete: null
    }
  },
  computed: {
    computedStartRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.itemsPerPage + 1
      } else {
        return 0
      }
    },
    computedEndRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.itemsPerPage + this.items.length
      } else {
        return (this.page - 1) * constants.itemsPerPage
      }
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    }
  },
  watch: {},
  beforeMount() {
    this.initData()
  },
  methods: {
    async initData() {
      await this.getProvinces()
      this.search()
    },
    async getProvinces() {
      this.lstProvince = await StorageService.getProvinces()
    },
    async search() {
      this.showLoading = true
      const pars = this.bindRequestPars()
      const { status, data } = await UserService.addressGetAll(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.totalRecord = data.totalRecord
        this.totalPage = DataUtils.calculatePage(data.totalRecord)
        this.items = data.content
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message })
      }
    },
    bindRequestPars() {
      const pars = { page: this.page }
      return pars
    },
    onClickSearch() {
      this.page = 1
      this.search()
    },
    onChangePage(page) {
      this.page = page
      this.search()
    },
    formatDate(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate)
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: '' }
    ) {
      this.showNoti = pars.state
      this.typeNoti = pars.type
      this.message = pars.msg
    },
    onShowAddressCreate() {
      this.itemAddress = { ...initItemAddress }
      this.defaultAddressOrigin = this.itemAddress.defaultAddress
      this.showCreateAndEditAddress = true
    },
    async onClickCancelDialogCreateAddress() {
      this.showCreateAndEditAddress = false
    },
    onAddressCreateOrUpdate(item) {
      if (typeof item.id !== constants.undefined && item.id > 0) {
        this.addressUpdate(item)
      } else {
        this.addressCreate(item)
      }
    },
    async addressUpdate(item) {
      this.showLoading = true
      const pars = { ...item }
      const { status, data } = await UserService.addressUpdate(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok) {
        await this.search()
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t('success_saved_info')
        })
        setTimeout(() => {
          this.toggleDialogNoti()
          this.onClickCancelDialogCreateAddress()
        }, constants.timeOut)
      } else {
        this.toggleDialogNoti({
          state: true,
          msg: data.message
        })
      }
    },
    async addressCreate(item) {
      this.showLoading = true
      const pars = { ...item }
      pars['custId'] = StorageService.getUserId()
      const { status, data } = await UserService.addressCreate(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t('create_address_success')
        })
        this.search()
        setTimeout(() => {
          this.toggleDialogNoti()
          this.onClickCancelDialogCreateAddress()
        }, constants.timeOut)
      } else {
        this.toggleDialogNoti({
          state: true,
          msg: data.message
        })
      }
    },
    onDetailAddress(item) {
      if (item !== null && item.id > 0) {
        this.itemAddress = { ...item }
        this.defaultAddressOrigin = item.defaultAddress
        this.showCreateAndEditAddress = true
      }
    },
    async onShowConfirmDeleteAddress(item) {
      this.message = this.$t('are_you_delete_address')
      this.typeNoti = constants.typeAlert.warning
      this.showAddressConfirmDel = true
      this.itemAddressDelete = item
    },
    onCancelAddressConfirmDel() {
      this.showAddressConfirmDel = false
      this.itemAddressDelete = null
      this.message = ''
    },
    async onAcceptAddressConfirmDel(item) {
      this.showAddressConfirmDel = false
      this.itemAddressDelete = null
      if (item !== null && !item.defaultAddress) {
        this.showLoading = true
        const pars = { id: item.id }
        const { status, data } = await UserService.addressDelete(pars)
        this.showLoading = false
        if (status === constants.statusCode.ok) {
          this.search()
        } else {
          this.toggleDialogNoti({
            state: true,
            msg: data.message
          })
        }
      }
    }
  }
}
</script>
<style lang="scss">
.minh-content {
  background: white !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
</style>
