<template>
  <v-dialog v-model="internalValue" :max-width="widthDialog" persistent>
    <v-card class="pa-4">
      <v-layout column>
        <v-layout justify-space-between class="mb-3">
          <h6 class="text-h6">
            {{ isEdit ? $t('edit_address') : $t('add_address') }}
          </h6>
          <v-icon @click="onClose">mdi-close</v-icon>
        </v-layout>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="submit">
            <v-layout column>
              <label>{{ $t('fullname') }}
                <span class="error--text">*</span>
              </label>
              <validation-provider
                v-slot="{ errors }"
                name="require"
                vid="fullname"
                :rules="requireRules"
              >
                <v-text-field
                  v-model="item.name"
                  outlined
                  dense
                  class="rounded-lg"
                  :maxlength="maximumName"
                  :placeholder="$t('place_holders.fullname')"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-layout>
            <v-layout column>
              <label>{{ $t('phone') }}
                <span class="error--text">*</span>
              </label>
              <validation-provider
                v-slot="{ errors }"
                name="phone"
                :rules="phoneRules"
              >
                <v-text-field
                  v-model="item.phone"
                  outlined
                  dense
                  class="rounded-lg"
                  :maxlength="maximumPhone"
                  :placeholder="$t('place_holders.phone')"
                  :error-messages="errors"
                  @keypress="isNumber($event)"
                  @paste.prevent="onPastePhone($event)"
                />
              </validation-provider>
            </v-layout>
            <v-layout column>
              <label>{{ $t('email') }}
                <span class="error--text">*</span>
              </label>
              <validation-provider
                v-slot="{ errors }"
                name="email"
                :rules="emailRules"
              >
                <v-text-field
                  v-model="item.email"
                  outlined
                  dense
                  class="rounded-lg"
                  :maxlength="maximumEmail"
                  :placeholder="$t('place_holders.email')"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-layout>
            <v-layout column>
              <label>
                {{ $t('province') }}/{{ $t('district') }}/{{ $t('commune') }}
                <span class="error--text">*</span>
              </label>
              <v-row>
                <validation-provider
                  v-slot="{ errors }"
                  name="require"
                  vid="province"
                  class="container col-sm-4 col-12 pb-0 pb-sm-3"
                  :rules="requireRules"
                >
                  <v-autocomplete
                    ref="inputProvince"
                    v-model="item.provinceCode"
                    outlined
                    dense
                    class="rounded-lg"
                    item-text="name"
                    item-value="code"
                    hide-no-data
                    :items="lstProvince"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.province')"
                    @change="onChangeProvince"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  ref="district"
                  name="require"
                  vid="district"
                  class="container col-sm-4 col-12 col-12 py-0 py-sm-3"
                  :rules="requireRules"
                >
                  <v-autocomplete
                    ref="inputDistrict"
                    v-model="item.districtCode"
                    outlined
                    dense
                    class="rounded-lg"
                    item-text="name"
                    item-value="code"
                    hide-no-data
                    :items="lstDistrict"
                    :placeholder="$t('place_holders.district')"
                    :error-messages="errors"
                    :disabled="districtDisabled"
                    @change="onChangeDistrict"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  ref="commune"
                  name="require"
                  vid="commune"
                  class="container col-sm-4 pt-0 pt-sm-3"
                  :rules="requireRules"
                >
                  <v-autocomplete
                    ref="inputCommune"
                    v-model="item.communeCode"
                    outlined
                    dense
                    class="rounded-lg"
                    item-text="name"
                    item-value="code"
                    hide-no-data
                    :items="lstCommune"
                    :placeholder="$t('place_holders.commune')"
                    :error-messages="errors"
                    :disabled="communeDisabled"
                  />
                </validation-provider>
              </v-row>
            </v-layout>
            <v-layout column>
              <label>{{ $t('specific_address') }}
                <span class="error--text">*</span>
              </label>
              <validation-provider
                v-slot="{ errors }"
                name="require"
                vid="specific_address"
                :rules="requireRules"
              >
                <v-text-field
                  v-model="item.addressDetail"
                  outlined
                  dense
                  class="rounded-lg"
                  :maxlength="maximumSmallText"
                  :placeholder="$t('place_holders.address_detail')"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-layout>
            <v-layout column>
              <label>{{ $t('delivery_notes') }}</label>
              <v-text-field
                v-model="item.note"
                outlined
                dense
                class="rounded-lg"
                :maxlength="maximumSmallText"
                :placeholder="$t('place_holders.delivery_notes')"
              />
            </v-layout>
            <v-layout column>
              <validation-provider>
                <v-switch
                  v-model="item.defaultAddress"
                  :label="$t('set_default_address')"
                  class="mt-0 pt-0"
                  :disabled="defaultOrigin"
                />
              </validation-provider>
            </v-layout>
            <v-layout justify-end>
              <v-btn outlined class="rounded-lg mr-2" @click="onClickCancel">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                color="primary"
                class="rounded-lg"
                type="submit"
                :disabled="invalid"
              >
                {{ isEdit ? $t('save') : $t('add') }}
              </v-btn>
            </v-layout>
          </v-form>
        </validation-observer>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import constants from '@/constants'
import { FormUtils } from '@/helpers/formUtils'
import { UserService } from '@/services/userService'
import { StorageService } from '@/services/storageService'

export default {
  name: 'DialogCreateAndEditAddress',
  props: {
    widthDialog: {
      type: Number,
      default: 600
    },
    value: Boolean,
    item: {
      type: Object,
      default: null
    },
    defaultOrigin: {
      type: Boolean,
      default: null
    },
    lstProvince: {
      type: Array,
      default: null
    },
    onAddressAction: {
      type: Function,
      default: null
    },
    onCancelAction: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      internalValue: this.value,
      maximumName: constants.maximumSmallText,
      maximumPhone: constants.maximumPhone,
      phoneRules: {
        required: true,
        min: constants.minimumPhone,
        max: constants.maximumPhone,
        regex: constants.regExp.phone
      },
      maximumEmail: constants.maximumEmail,
      emailRules: {
        required: true,
        email: true,
        min: constants.minimumEmail,
        max: constants.maximumEmail,
        regex: constants.regExp.email
      },

      requireRules: {
        required: true
      },

      lstDistrict: [],
      districtDisabled: true,
      lstCommune: [],
      communeDisabled: true,
      maximumSmallText: constants.maximumSmallText
    }
  },
  computed: {
    isEdit() {
      return typeof this.item.id !== constants.undefined && this.item.id > 0
    }
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value(val, oldVal) {
      if (val === oldVal) return

      this.internalValue = val
      if (
        val &&
        typeof this.item.id !== constants.undefined &&
        this.item.id > 0
      ) {
        this.initDataEdit()
      }
    }
  },
  methods: {
    async initDataEdit() {
      await this.getDistrictByProvinceCode(this.item.provinceCode)
      await this.getCommuneByDistrictCode(this.item.districtCode)
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt)
    },
    onPastePhone(evt) {
      const val = FormUtils.isOnPasteOnlyNumber(evt)
      if (val !== null) {
        this.item.phone = val
      }
    },
    async onChangeProvince(val) {
      if (parseInt(this.item.provinceCode) !== val) {
        this.item.provinceCode = val
        this.clearDistrict()
        this.clearCommune()
        await this.getDistrictByProvinceCode(val)
        this.$refs.inputProvince.blur()
        this.$refs.inputDistrict.focus()
      }
    },
    clearDistrict() {
      this.item.districtCode = ''
      this.$refs.observer.refs.district.reset()
      this.lstDistrict = []
      this.districtDisabled = true
    },
    clearCommune() {
      this.item.communeCode = ''
      this.$refs.observer.refs.commune.reset()
      this.lstCommune = []
      this.communeDisabled = true
    },
    async getDistrictByProvinceCode(code) {
      this.showLoading = false
      const pars = {
        provinceCode: code
      }
      const { data } = await UserService.findDistrictByProvinceCode(pars)
      this.showLoading = false
      if (data != null && data.length > 0) {
        this.lstDistrict = data
        this.districtDisabled = false
      }
    },
    async onChangeDistrict(val) {
      if (parseInt(this.item.districtCode) !== val) {
        this.item.districtCode = val
        this.clearCommune()
        await this.getCommuneByDistrictCode(val)
        this.$refs.inputDistrict.blur()
        this.$refs.inputCommune.focus()
      }
    },
    async getCommuneByDistrictCode(code) {
      this.showLoading = false
      const pars = {
        districtCode: code
      }
      const { data } = await UserService.findCommuneByDistrictCode(pars)
      this.showLoading = false
      if (data != null && data.length > 0) {
        this.lstCommune = data
        this.communeDisabled = false
      }
    },
    bindRequestParams(item) {
      const rqPars = { ...item }
      rqPars['custId'] = StorageService.getUserId()
      return rqPars
    },
    async submit() {
      var valid = this.$refs.observer.validate()
      if (valid) {
        this.resetForm()
        this.onAddressAction(this.item)
      }
    },
    onClose() {
      this.resetForm()
      this.$emit('input', false)
    },
    resetForm() {
      this.$refs.observer.reset()
    },
    onClickCancel() {
      this.resetForm()
      this.onCancelAction()
    }
  }
}
</script>
