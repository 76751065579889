var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"mb-2",attrs:{"justify-end":""}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onShowAddressCreate}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('new_address'))+" ")],1)],1),_c('v-data-table',{staticClass:"border-primary",attrs:{"hide-default-footer":"","no-data-text":_vm.$t('not_found'),"headers":_vm.headers,"header-props":{ sortIcon: 'mdi-menu-swap' },"items":_vm.items},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-3 text-truncate"},[_vm._v(" "+_vm._s(item.name)+" "),(item.defaultAddress)?_c('span',{staticClass:"error--text text-min"},[_vm._v(" ["+_vm._s(_vm.$t('default'))+"] ")]):_vm._e()])]}},{key:"item.addressDetail",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-4 text-truncate"},[_vm._v(" "+_vm._s(item.addressDetail)+" ")])]}},{key:"item.provinceName",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(((item.communeName) + ", " + (item.districtName) + ", " + (item.provinceName)))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-2"},[_c('v-icon',{staticClass:"mr-4",on:{"click":function($event){return _vm.onDetailAddress(item)}}},[_vm._v(" mdi-pencil-outline ")]),(!item.defaultAddress)?_c('v-icon',{on:{"click":function($event){return _vm.onShowConfirmDeleteAddress(item)}}},[_vm._v(" mdi-trash-can-outline ")]):_vm._e()],1)]}}],null,true)}),_c('custom-pagination',{attrs:{"page":_vm.page,"start-record":_vm.computedStartRecord,"end-record":_vm.computedEndRecord,"total-record":_vm.totalRecord,"total-page":_vm.totalPage,"on-change-page":_vm.onChangePage}})],1),_c('dialog-loading',{attrs:{"message":_vm.$t('processing')},model:{value:(_vm.showLoading),callback:function ($$v) {_vm.showLoading=$$v},expression:"showLoading"}}),_c('dialog-notification',{attrs:{"icon-src":_vm.getIcon,"message":_vm.message},model:{value:(_vm.showNoti),callback:function ($$v) {_vm.showNoti=$$v},expression:"showNoti"}}),_c('dialog-confirm-token-expried',{model:{value:(_vm.showConfirmTokenExpried),callback:function ($$v) {_vm.showConfirmTokenExpried=$$v},expression:"showConfirmTokenExpried"}}),_c('dialog-create-and-edit-address',{attrs:{"item":_vm.itemAddress,"default-origin":_vm.defaultAddressOrigin,"lst-province":_vm.lstProvince,"on-address-action":_vm.onAddressCreateOrUpdate,"on-cancel-action":_vm.onClickCancelDialogCreateAddress},model:{value:(_vm.showCreateAndEditAddress),callback:function ($$v) {_vm.showCreateAndEditAddress=$$v},expression:"showCreateAndEditAddress"}}),_c('dialog-confirm',{attrs:{"icon-src":_vm.getIcon,"message":_vm.message,"text-cancel":_vm.textCancel,"text-accept":_vm.textAcceptAddressConfirmDel,"on-cancel":_vm.onCancelAddressConfirmDel,"on-accept":_vm.onAcceptAddressConfirmDel,"data":_vm.itemAddressDelete},model:{value:(_vm.showAddressConfirmDel),callback:function ($$v) {_vm.showAddressConfirmDel=$$v},expression:"showAddressConfirmDel"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }