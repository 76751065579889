import Vue from 'vue'
import Router from 'vue-router'

import routeNames from './routeNames'
import routePaths from './routePaths'

Vue.use(Router)

export default new Router({
  linkActiveClass: 'is-active',
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/views/layout/default'),
      children: [
        {
          name: routeNames.DASHBOARD,
          path: routePaths.DASHBOARD,
          component: () => import('@/views/dashboard/Dashboard')
        },
        {
          name: routeNames.DASHBOARD_MAIN,
          path: routePaths.DASHBOARD_MAIN,
          component: () => import('@/views/dashboard/DashboardMain')
        },
        {
          name: routeNames.ORDER_CREATE,
          path: routePaths.ORDER_CREATE,
          component: () => import('@/views/order/Create')
        },
        {
          name: routeNames.ORDER_CREATE_MULTIPE,
          path: routePaths.ORDER_CREATE_MULTIPE,
          component: () => import('@/views/order/CreateMultiple')
        },
        {
          name: routeNames.ORDER_LIST,
          path: routePaths.ORDER_LIST,
          component: () => import('@/views/order_management/List')
        },
        {
          name: routeNames.LIST_ORDER_PROCESS,
          path: routePaths.LIST_ORDER_PROCESS,
          component: () => import('@/views/order_management/ListOrderProcess')
        },
        {
          name: routeNames.LIST_ORDER_ONE_PART,
          path: routePaths.LIST_ORDER_ONE_PART,
          component: () => import('@/views/order_management/ListOrderPartial')
        },
        {
          name: routeNames.ORDER_DETAIL,
          path: routePaths.ORDER_DETAIL,
          component: () => import('@/views/order_management/Detail')
        },
        {
          name: routeNames.ORDER_CREATE_MULTI_BOX,
          path: routePaths.ORDER_CREATE_MULTI_BOX,
          component: () => import('@/views/order/CreateMultiBox')
        },
        {
          name: routeNames.CASH_FLOW_MANAGEMENT,
          path: routePaths.CASH_FLOW_MANAGEMENT,
          component: () => import('@/views/cash_flow_management/List')
        },
        {
          name: routeNames.SHOP_PROFILE,
          path: routePaths.SHOP_PROFILE,
          component: () => import('@/views/account_management/ShopProfile')
        },
        {
          name: routeNames.CHECKING_ACCOUNT,
          path: routePaths.CHECKING_ACCOUNT,
          component: () => import('@/views/account_management/CheckingAccount')
        },
        {
          name: routeNames.ADDRESS_LIST,
          path: routePaths.ADDRESS_LIST,
          component: () => import('@/views/account_management/AddressList')
        }
      ]
    },
    {
      name: routeNames.CHANGE_PASSWORD,
      path: routePaths.CHANGE_PASSWORD,
      component: () => import('@/views/user/ChangePassword')
    },
    {
      name: routeNames.UPDATE_PASSWORD,
      path: routePaths.UPDATE_PASSWORD,
      component: () => import('@/views/user/UpdatePassword')
    },
    // Authenticated
    {
      name: routeNames.LOGIN,
      path: routePaths.LOGIN,
      component: () => import('@/views/auth/Login')
    },
    {
      name: routeNames.FORGOT_PASSWORD,
      path: routePaths.FORGOT_PASSWORD,
      component: () => import('@/views/auth/ForgotPassword')
    },
    {
      name: routeNames.FORGOT_PASSWORD_UPDATE,
      path: routePaths.FORGOT_PASSWORD_UPDATE,
      component: () => import('@/views/auth/ForgotPasswordUpdate')
    },
    {
      name: routeNames.REGISTER,
      path: routePaths.REGISTER,
      component: () => import('@/views/auth/Register')
    },
    {
      name: routeNames.OTP,
      path: routePaths.OTP,
      component: () => import('@/views/otp/Otp')
    }
  ]
})
