<template>
  <v-dialog
    v-model="internalValue"
    :max-width="widthDialog"
    :persistent="persistent"
  >
    <v-card class="pb-3">
      <v-card-title class="text-h6 justify-center text-uppercase">
        <v-icon color="primary">{{ iconSrc }}</v-icon>
      </v-card-title>
      <v-card-text class="text-center my-3">
        <v-row
          v-if="title"
          justify="center"
          class="black--text text-uppercase text-subtitle-1 font-weight-medium"
        >
          {{ title }}
        </v-row>
        <v-row justify="center" class="black--text">
          {{ message }}
          <span v-if="isShowBrand" class="primary--text">
            {{ $t('brand') }}
          </span>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import constants from '@/constants'
export default {
  name: 'DialogNotification',
  props: {
    widthDialog: {
      type: Number,
      default: constants.widthDialog
    },
    value: Boolean,
    persistent: {
      type: Boolean,
      default: false
    },
    iconSrc: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    isShowBrand: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalValue: this.value
    }
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value(val, oldVal) {
      if (val === oldVal) return

      this.internalValue = val
    }
  }
}
</script>
