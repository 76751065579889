export default {
  TOKEN: 'TOKEN',
  TOKEN_EXP: 'TOKEN_EXP',
  CURRENT_USER: 'CURRENT_USER',
  LANGUAGE: 'LANGUAGE',
  USER_NAME: 'USER_NAME',
  TIME_GET_OTP: 'TIME_GET_OTP',
  PROVINCES: 'PROVINCES',
  DISTRICTS: 'DISTRICTS',
  COMMUNES: 'COMMUNES',
  typeUrl: {
    passport: 0
  },
  typeAlert: {
    success: 'success',
    error: 'error',
    warning: 'warning'
  },
  statusCode: {
    ok: 200,
    badRequest: 400
  },
  widthDialog: 360,
  regExp: {
    email:
      '^[a-zA-Z]+([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+([.]?w+)*(.[a-zA-Z]{2,3})+$',
    phone: '^(0)(3|5|7|8|9)[0-9]{8}$',
    password:
      '(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`~!@#$%^*()_|+=?;:".<>\'{}[\\/])',
    waybill: '^[a-zA-Z0-9]+$',
    number: '^[0-9]+$',
    numberFm: '^[0-9,]+$',
    decimal: '^[0-9.,]+$',
    not_zero: '^(?!0$).*',
    tracking_id: '^[0-9A-Za-z]{6,18}$'
  },
  basicAuth: {
    user: 'web',
    pass: 'web',
    grantType: 'password'
  },
  roleAccept: ['ROLE_CUSTOMER'],
  separation: '.',
  signFormatMoney: ',',
  maximumAfterDecimal: 2,
  undefined: 'undefined',

  minimumEmail: 6,
  maximumEmail: 70,
  minimumPhone: 10,
  maximumPhone: 10,
  minimumPassword: 8,
  maximumPassword: 32,
  maximumOtp: 6,
  maximumOrderQuantity: 10,
  maximumOrderQuantityFm: 11,
  maximumReferralCode: 20,
  maximumOrderWeight: 6,
  maximumWeight: 10,
  maximumOrderWeightFm: 7,
  maximumOrderWeightDecimalFm: 10,
  maximumPrice: 15,
  maximumPriceFm: 19,
  maximumSmallText: 100,
  maximumMediumText: 500,
  maximumLargeText: 1000,
  minimumWaybill: 6,
  maximumWaybill: 18,
  maximumRecordExport: 1000,
  maximumMoneyCod: 10,
  maximumPriceValueFm: 10,
  maximumOrderValue: 20000000,
  maximumCodValue: 20000000,
  maximumLimitPerPage: 9999,
  maximumInsurance: 1000000,
  maximumSizeLwH: 4,
  maximumSizeLwHFm: 5,
  maximumPriceValue: 8,
  formatDate: 'DD/MM/YYYY',
  formatDateDmy: 'yyyy/MM/DD',
  formatDateYmd: 'YYYY-MM-DD',
  formatDateTime: 'dd/MM/yyyy HH:mm:ss',
  formatDateTimeInt: 'ddMMyyyyHHmmss',
  formatDateTimeHm: 'dd/MM/yyyy HH:mm',
  formatTimeHm: 'HH:mm',
  formatDatePlaceholder: 'DD/MM/YYYY',
  formatDateTimeUtc: 'yyyy-MM-DDTHH:mm:ss',
  formatDateTimeCompare: 'yyyyMMddHHmmss',
  formatDateMonth: 'DD/MM',

  timeOut: 1000,
  initTimeCountDown: 60,
  startsWithOtpOverLimit: '3,2',
  startsWithShopInactive: 'nhân viên tư vấn của',

  timeOutRole: 500,
  limitHourOrderCreate: 16,
  numberDateAllowedOrderCreate: 2,

  createPriceListType: {
    partner: 'PARTNER',
    shop: 'SHOP',
    guest: 'GUEST'
  },
  customerType: {
    shop: 'SHOP',
    personal: 'PERSONAL'
  },
  transportType: {
    ship: 'SHIP',
    individual: 'INDIVIDUAL'
  },
  serviceType: {
    normal: 1,
    express: 2
  },
  pricesType: {
    standard: 'STANDARD',
    weight: 'WEIGHT'
  },
  status: {
    del: 4
  },
  priceTypeTabs: {
    standard: 0,
    weight: 1
  },
  itemsPerPage: 20,
  pageSizeMax: 100,
  pageSize: 10,
  numberEmployeesPerPage: 10,
  customerStatus: {
    activated: 1,
    waitingActivation: 0
  },
  fixDefaulValue: -99,
  serviceFeePayers: {
    sender: 'SENDER',
    receiver: 'RECEIVER'
  },
  orderInsurance: {
    yes: 1,
    no: 0
  },
  orderStatus: {
    cancelled: -1,
    waiting: 0,
    delivering: 1,
    successfulDelivery: 2,
    completed: 3,
    waitingDelivery: 4,
    pickUpFailed: 5,
    refundingToSender: 6,
    refundedToSender: 7,
    parcelWeight: 8,
    orderReweighed: 9, // đơn hàng được cân lại
    failDelivery: 10 // cho xu ly
  },
  statusPartnerAccount: {
    active: 1,
    inactive: 0
  },
  waybillPrefix: 'SVC',
  errorValidation: 'common.message.error.validation',
  typeExport: {
    excel: 'xlsx',
    csv: 'csv'
  },
  servicesShipping: {
    normal: 1,
    express: 2,
    standard: 3,
    over: 4
  },
  typeOfDay: {
    today: "TODAY",
    week: "WEEK",
    month: "MONTH",
    year: "YEAR",
  },
  itemsPerPageHalf: 5,
  totalPageVisible: 7,
  typeOtp: {
    register: 1,
    forgotPassword: 2
  },
  valueFilterForGuest: {
    type: 'WEEK',
    runDays: '5,5,7'
  },
  partnerGroup: {
    ninjaVan: 1,
    ghtk: 2
  },
  errorMessage: {
    linkExist: 'Tài khoản liên kết đã được chọn !'
  },
  statusCustomer: {
    inactive: 0, // khong hoat dong
    active: 1, // dang hoat dong
    off: 5, // Ngung hoat dong
    delete: 4, // ngung hoat dong
  },
  feeTag: {
    insurance: 5, // bao hiem
    reinforced: 6, // gia co hang hoa
    fragile: 4 // hang de vo
  },
  feeTagUnitType: {
    percent: 0, // tinhs theo %
    volume: 1 // tinh theo cong don
  },
  weight: 20, // khoi luon ghtk
  statusCreateOrder: {
    success: 1,
    fail: -1,
    edited: 2 // da chinh don khong hop le khi tao
  },
  pickWorkShip: {
    morning: 1, // sang
    afternoon: 2, // chieu
    night: 3 // toi
  }
}
