export default {
  // no auth
  LOGIN: 'LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_UPDATE: 'FORGOT_PASSWORD_UPDATE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  REGISTER: 'REGISTER',
  OTP: 'OTP',

  // dashboard
  DASHBOARD: 'DASHBOARD',
  DASHBOARD_MAIN: 'DASHBOARD_MAIN',

  // order
  ORDER_LIST: 'ORDER_LIST',
  ORDER_CREATE: 'ORDER_CREATE',
  ORDER_CREATE_MULTIPE: 'ORDER_CREATE_MULTIPE',
  ORDER_DETAIL: 'ORDER_DETAIL',
  ORDER_CREATE_MULTI_BOX: 'ORDER_CREATE_MULTI_BOX',
  LIST_ORDER_PROCESS: 'LIST_ORDER_PROCESS',
  LIST_ORDER_ONE_PART: 'LIST_ORDER_ONE_PART',

  CASH_FLOW_MANAGEMENT: 'CASH_FLOW_MANAGEMENT',

  SHOP_PROFILE: 'SHOP_PROFILE',
  CHECKING_ACCOUNT: 'CHECKING_ACCOUNT',
  ADDRESS_LIST: 'ADDRESS_LIST'
}
