<template>
  <v-app>
    <v-layout white>
      <v-layout box-noauth bg-main />
      <v-layout column justify-center>
        <v-container fluid zindex-1>
          <v-row>
            <v-col cols="md-6" class="col-12">
              <PostOffice />
            </v-col>
            <v-layout col-12 col-md-6 justify-center align-center pa-2 pa-sm-6>
              <div class="col-12 col-md-10 col-lg-8 pa-0">
                <v-layout column white rounded-xl box-shadow pa-6 pa-xl-16 py-4>
                  <label
                    class="text-uppercase font-weight-medium text-size-title"
                  >
                    {{ $t("register") }}
                  </label>
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form @submit.prevent="submit">
                      <!-- shop name -->
                      <v-layout column>
                        <label class="text-body-2">
                          {{ `${$t("name")} ${$t("shop")}` }}
                          <span class="red--text">{{ $t("required") }}</span>
                        </label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="shopName"
                          :rules="shopNameRules"
                        >
                          <v-text-field
                            v-model="shopName"
                            outlined
                            dense
                            class="rounded-lg"
                            :maxlength="maximumShopName"
                            :error-messages="errors"
                            :placeholder="$t('place_holders.shop_name')"
                          />
                        </validation-provider>
                      </v-layout>
                      <!-- phone -->
                      <v-layout column>
                        <label class="text-body-2">
                          {{ $t("phone") }}
                          <span class="red--text">{{ $t("required") }}</span>
                        </label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="phone"
                          :rules="phoneRules"
                        >
                          <v-text-field
                            v-model="phone"
                            type="text"
                            pattern="\d*"
                            outlined
                            dense
                            class="rounded-lg"
                            :maxlength="maximumPhone"
                            :error-messages="errors"
                            :placeholder="$t('place_holders.phone')"
                            @keypress="isNumber($event)"
                            @paste.prevent="onPastePhone($event)"
                          />
                        </validation-provider>
                      </v-layout>
                      <!-- email -->
                      <v-layout column>
                        <label class="text-body-2">
                          {{ $t("email") }}
                          <span class="red--text">{{ $t("required") }}</span>
                        </label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="email"
                          :rules="emailRules"
                        >
                          <v-text-field
                            v-model="email"
                            outlined
                            dense
                            class="rounded-lg"
                            :maxlength="maximumEmail"
                            :error-messages="errors"
                            :placeholder="$t('place_holders.email')"
                          />
                        </validation-provider>
                      </v-layout>
                      <!-- password and confirm password -->
                      <v-row class="pb-2">
                        <v-col cols="sm-6" class="col-12 pb-0 pb-sm-3">
                          <label class="text-body-2">
                            {{ $t("password") }}
                            <span class="red--text">{{ $t("required") }}</span>
                          </label>
                          <validation-provider
                            v-slot="{ errors }"
                            name="password"
                            :rules="passwordRules"
                          >
                            <v-text-field
                              v-model="password"
                              outlined
                              dense
                              class="rounded-lg"
                              :append-icon="
                                isPassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :type="isPassword ? 'password' : 'text'"
                              :maxlength="maximumPassword"
                              :error-messages="errors"
                              :placeholder="$t('place_holders.password')"
                              @click:append="isPassword = !isPassword"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="sm-6" class="col-12 pt-0 pt-sm-3">
                          <label class="text-body-2">
                            {{ $t("confirm_password") }}
                            <span class="red--text">{{ $t("required") }}</span>
                          </label>
                          <validation-provider
                            v-slot="{ errors }"
                            name="confirmPassword"
                            :rules="confirmPasswordRules"
                          >
                            <v-text-field
                              v-model="confirmPassword"
                              outlined
                              dense
                              class="rounded-lg"
                              :append-icon="
                                isPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :type="isPasswordConfirm ? 'password' : 'text'"
                              :maxlength="maximumPassword"
                              :error-messages="errors"
                              :placeholder="
                                $t('place_holders.confirn_password')
                              "
                              @click:append="
                                isPasswordConfirm = !isPasswordConfirm
                              "
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <!-- shop address -->
                      <v-layout column>
                        <label class="text-body-2">
                          {{ `${$t("address")} ${$t("shop")}` }}
                          <span class="red--text">{{ $t("required") }}</span>
                        </label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="province"
                          :rules="provinceRules"
                        >
                          <v-autocomplete
                            v-model="provinceSelected"
                            clearable
                            outlined
                            dense
                            class="rounded-lg"
                            item-text="name"
                            item-value="code"
                            hide-no-data
                            :placeholder="$t('place_holders.shop_address')"
                            :items="lstProvince"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-layout>
                      <!-- number order and referral code -->
                      <v-row>
                        <v-col cols="sm-6" class="col-12 pb-0">
                          <label class="text-body-2">
                            {{ `${$t("order_quantity")}/${$t("month")}` }}
                            <span class="red--text">
                              {{ $t("required") }}
                            </span>
                          </label>
                          <validation-provider
                            v-slot="{ errors }"
                            name="orderQuantity"
                            :rules="orderQuantityRules"
                          >
                            <v-text-field
                              v-model="orderQuantity"
                              outlined
                              dense
                              class="rounded-lg"
                              :maxlength="maximumOrderQuantity"
                              :error-messages="errors"
                              :placeholder="$t('place_holders.order_quantity')"
                              @keypress="isNumber($event)"
                              @input="onInputOrderQuantity"
                              @paste.prevent="onPasteOrderQuantity($event)"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="sm-6" class="col-12 pt-0 pt-sm-3">
                          <label class="text-body-2">
                            {{ $t("referral_code") }}
                            <v-tooltip
                              top
                              max-width="250"
                              color="white"
                              class="box-shadow"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  dark
                                  small
                                  v-bind="attrs"
                                  style="top: -2px"
                                  v-on="on"
                                >
                                  mdi-help-circle-outline
                                </v-icon>
                              </template>
                              <span style="color: black">
                                {{ $t("code_provided_by_bfast") }}
                              </span>
                            </v-tooltip>
                          </label>
                          <v-text-field
                            v-model="referralCode"
                            outlined
                            dense
                            :style="checkReferCode ? 'border: 1px solid green' : ''"
                            class="rounded-lg"
                            :error-messages="$t(msgReferralCode)"
                            :maxlength="maximumReferralCode"
                            :hide-details="checkReferCode"
                            :placeholder="$t('place_holders.referral_code')"
                            @change="referralCode = trimSpace(referralCode)"
                          />
                          <label v-if="msgReferralCodePass" style="font-size: 12px; color: green" class="ml-2">
                            {{ $t(msgReferralCodePass) }}
                          </label>
                          <div style="text-align: end;">
                            <v-btn color="primary" style="height: 25px" @click="onCheckReferCode">
                              <span style="text-transform: capitalize"> {{ $t("check_referral_code") }} </span>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      <v-layout class="mb-1">
                        <v-checkbox
                          v-model="checkbox"
                          hide-details
                          class="mt-0 pt-0"
                          color="primary"
                        >
                          <template v-slot:label>
                            <div class="text-body-2">
                              {{ $t("i_agree_with_the") }}
                              <span class="primary--text">
                                {{ $t("terms_and_conditions") }}
                              </span>
                            </div>
                          </template>
                        </v-checkbox>
                      </v-layout>
                      <div>
                        <v-btn
                          block
                          large
                          color="primary"
                          type="submit"
                          class="rounded-lg"
                          :disabled="invalid || !checkbox || msgReferralCode"
                        >
                          <span class="font-weight-medium white--text">
                            {{ $t("register") }}
                          </span>
                        </v-btn>
                      </div>
                    </v-form>
                  </validation-observer>
                  <v-layout justify-center class="mt-2">
                    <div class="blur--text text-h6-1">
                      {{ $t("had_an_account") }}?
                      <router-link
                        class="primary--text text-decoration-none"
                        :to="loginNowPath"
                      >
                        {{ $t("login_now") }}
                      </router-link>
                    </div>
                  </v-layout>
                </v-layout>
              </div>
            </v-layout>
          </v-row>
        </v-container>
      </v-layout>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />
  </v-app>
</template>

<script>
import PostOffice from "./components/PostOffice.vue";
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import constants from "@/constants";
import { mapActions } from "vuex";
import routePaths from "@/router/routePaths";
import { FormUtils } from "@/helpers/formUtils";
import { StringUtils } from "@/helpers/stringUtils";
import { StorageService } from "@/services/storageService";
import { UserService } from '@/services/userService'

export default {
  components: { PostOffice, DialogLoading, DialogNotification },
  data: () => ({
    shopName: "",
    maximumShopName: constants.maximumSmallText,
    shopNameRules: {
      required: true,
      max: constants.maximumSmallText,
    },
    phone: "",
    maximumPhone: constants.maximumPhone,
    phoneRules: {
      required: true,
      min: constants.minimumPhone,
      max: constants.maximumPhone,
      regex: constants.regExp.phone,
    },
    email: "",
    maximumEmail: constants.maximumEmail,
    emailRules: {
      required: true,
      email: true,
      min: constants.minimumEmail,
      max: constants.maximumEmail,
      regex: constants.regExp.email,
    },
    password: "",
    isPassword: true,
    maximumPassword: constants.maximumPassword,
    passwordRules: {
      required: true,
      min: constants.minimumPassword,
      max: constants.maximumPassword,
      regex: constants.regExp.password,
    },
    confirmPassword: "",
    isPasswordConfirm: true,
    confirmPasswordRules: {
      required: true,
      confirmed: "password",
    },
    provinceSelected: null,
    lstProvince: null,
    provinceRules: {
      required: true,
    },
    orderQuantity: "",
    maximumOrderQuantity: constants.maximumOrderQuantityFm,
    orderQuantityRules: {
      required: true,
    },
    referralCode: "",
    maximumReferralCode: constants.maximumReferralCode,
    serviceProviderSelected: null,
    serviceProviderRules: {
      required: true,
    },
    lstServiceProvider: [],
    checkbox: true,

    loginNowPath: routePaths.LOGIN,
    // loading
    showLoading: false,

    // notifications
    showNoti: false,
    typeNoti: constants.typeAlert.warning,
    message: "",
    msgReferralCode: null,
    msgReferralCodePass: null,
    checkReferCode: false
  }),
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
  },
  watch: {
    referralCode() {
      if (this.referralCode === '') {
        this.msgReferralCode = null
        this.msgReferralCodePass = null
        this.checkReferCode = false
      }
    }
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    ...mapActions("user", ["registerUser"]),
    async initData() {
      await this.getProvinceAll();
    },
    async submit() {
      var valid = this.$refs.observer.validate();
      if (valid && this.checkbox) {
        this.showLoading = true;
        const pars = this.bindRequestParams();
        const { status, data } = await this.registerUser(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok) {
          StorageService.setUserName(this.phone.trim());
          StorageService.setTimeGetOtp(data.metadata.timestamp);
          this.$router.push({
            path: routePaths.OTP,
            query: {
              type: constants.typeOtp.register,
            },
          });
        } else {
          if (this.isOtpOverLimit(data.message)) {
            StorageService.setUserName(this.phone.trim());
            this.$router.push({
              path: routePaths.OTP,
              query: {
                type: constants.typeOtp.register,
                message: data.message,
              },
            });
          } else {
            let msgNoti = "";
            if (data.message === constants.errorValidation) {
              var jsonObj = data.details;
              var keys = Object.keys(jsonObj);
              for (var i = 0; i < keys.length; i++) {
                if (i < keys.length - 1) {
                  msgNoti += jsonObj[keys[i]] + ",";
                } else {
                  msgNoti += jsonObj[keys[i]];
                }
              }
            } else {
              msgNoti = data.message;
            }
            this.toggleDialogNoti({ state: true, msg: msgNoti });
            setTimeout(() => {
              this.toggleDialogNoti();
            }, constants.timeOut);
          }
        }
      }
    },
    bindRequestParams() {
      const rqPars = {
        name: this.shopName.trim(),
        phone: this.phone.trim(),
        email: this.email.trim(),
        password: this.password.trim(),
        confirmPassword: this.confirmPassword.trim(),
        provinceCode: this.getProvinceSelected(),
        quantityPerDay: this.getOrderQuantity(),
        // postOfficeId: this.getServiceProviderSelected(),
        referCode: this.referralCode ? this.referralCode.trim() : null,
        acceptedPolicy: true,
      };
      return rqPars;
    },
    trimSpace(val) {
      return val ? val.trim() : "";
    },
    isOtpOverLimit(msg) {
      return msg.toLowerCase().startsWith(constants.startsWithOtpOverLimit);
    },
    async onCheckReferCode() {
      if (this.referralCode && this.referralCode.length) {
        const res = await UserService.checkReferCode(this.referralCode)
        if (res.status === constants.statusCode.ok) {
          if (res.data === false) {
            this.checkReferCode = false
            this.msgReferralCode = this.$t('msg_error_referal_code')
            this.msgReferralCodePass = null
          } else {
            this.checkReferCode = true
            this.msgReferralCodePass = this.$t('msg__referal_code')
            this.msgReferralCode = null
          }
        }
      }
    },
    async getProvinceAll() {
      this.lstProvince = await StorageService.getProvinces();
    },
    getDefaultPostoffice() {
      return this.lstServiceProvider.find((x) => x.code === "VC");
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt);
    },
    onInputOrderQuantity(value) {
      this.orderQuantity = StringUtils.formatNumber(value);
    },
    getOrderQuantity() {
      return StringUtils.numStrToInt(this.orderQuantity);
    },
    getProvinceSelected() {
      if (this.provinceSelected) {
        return this.provinceSelected;
      }

      return 0;
    },
    getServiceProviderSelected() {
      if (this.serviceProviderSelected) {
        return this.serviceProviderSelected.id;
      }

      return 0;
    },
    onPastePhone(evt) {
      const val = FormUtils.isOnPasteOnlyNumber(evt);
      if (val !== null) {
        this.phone = val;
      }
    },
    onPasteOrderQuantity(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumOrderQuantity
      );
      if (val !== null) {
        this.orderQuantity = StringUtils.formatNumber(val);
      }
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
  },
};
</script>
<style src="@/styles/form.scss" lang="scss">
.color-text-border {
  border: 2px solid green !important
}
</style>
